import React, { useRef, useState } from 'react';
import { useToast } from 'hooks';
import ChangeProfileIcon from 'assets/image-user.png';
import ChangeCoverIcon from 'assets/icons/ChangeProfile/ChangeProfile.png';
import Loading from 'components/common/Loading';
import useChangeProfileImage from 'hooks/users/useChangeProfileImage';
import ImageCropperModal from 'components/common/ImageCropper/ImageCropperModal';
import { imageRatios, imageSizes } from 'constants/constants';
import { checkImageDimensions } from 'utils/helpers';
import { convertHeicToJpeg } from 'utils/heicConverter';

const ChangeProfile = () => {
  const fileInputRef = useRef(null);
  const fileBannerInputRef = useRef(null);
  const { showToast, showErrorToast } = useToast();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppingUrl, setCroppingUrl] = useState();
  const [croppingUrlMimeType, setCroppingUrlMimeType] = useState();
  const [aspectRatio, setAspectRatio] = useState(null);
  const { coverLoading, loading, onChangeProfileImage } = useChangeProfileImage();
  const uploadImgType = useRef();

  const handleFileChange = async event => {
    const file = event.target.files[0];

    try {
      // Pre-process HEIC files before dimension check
      let processedFile = file;
      const isHeicType =
        /^image\/(heic|heif|heic-sequence|heif-sequence)$/i.test(file.type) ||
        file.name.toLowerCase().endsWith('.heic') ||
        file.name.toLowerCase().endsWith('.heif');

      if (isHeicType) {
        processedFile = await convertHeicToJpeg(file);
      }

      const result = await checkImageDimensions(
        processedFile,
        imageSizes.profile.width,
        imageSizes.profile.height
      );

      if (result.isValid) {
        uploadImgType.current = 'profile_image';
        setAspectRatio(imageRatios.profile);
        setCropModalOpen(true);
        setCroppingUrlMimeType(result.processedFile?.type || processedFile.type);
        setCroppingUrl(result.src);
      } else {
        showErrorToast(result.error);
      }
    } catch (error) {
      console.error('Error processing image:', error);
      showErrorToast('Failed to process image');
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleCoverFileChange = async event => {
    const file = event.target.files[0];

    try {
      const result = await checkImageDimensions(
        file,
        imageSizes.coverWeb.width,
        imageSizes.coverWeb.height
      );

      if (result.isValid) {
        uploadImgType.current = 'banner';
        setAspectRatio(imageRatios.covertArtWeb);
        setCropModalOpen(true);
        setCroppingUrlMimeType(result.processedFile.type);
        setCroppingUrl(result.src);
      } else {
        showErrorToast(result.error);
      }
    } catch (error) {
      console.error('Error processing image:', error);
      showErrorToast('Failed to process image');
    }

    if (fileBannerInputRef.current) {
      fileBannerInputRef.current.value = '';
    }
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleBannerClick = () => {
    fileBannerInputRef.current.click();
  };
  const handleCancel = () => {
    setCropModalOpen(false);
    setCroppingUrl();
    setCroppingUrlMimeType();
    setAspectRatio(null);
  };

  const handleCrop = async croppedFile => {
    await onChangeProfileImage(croppedFile, uploadImgType.current);
    showToast(
      `${
        uploadImgType.current !== 'banner' ? 'Profile Picture' : 'Cover Picture'
      } Changed Successfully!`
    );
  };
  return (
    <div className="change-profile">
      <div className="img-container">
        <div
          className="profile-row"
          style={{ cursor: `${loading ? 'not-allowed' : 'pointer'}` }}
          onClick={loading ? undefined : handleClick}
        >
          <img src={ChangeProfileIcon} className="profile-icon" alt="profile" />
          Change Profile Image {loading && <Loading type="ball-clip-rotate" />}
        </div>
        <div
          className="profile-row"
          style={{ cursor: `${coverLoading ? 'not-allowed' : 'pointer'}` }}
          onClick={coverLoading ? undefined : handleBannerClick}
        >
          <img src={ChangeCoverIcon} className="cover-icon" alt="profile" />
          Change Cover Image {coverLoading && <Loading type="ball-clip-rotate" />}
        </div>
        <input
          id="profile-upload"
          type="file"
          accept="image/*,.heic,.heif"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <input
          id="banner-upload"
          type="file"
          accept="image/*,.heic,.heif"
          ref={fileBannerInputRef}
          style={{ display: 'none' }}
          onChange={handleCoverFileChange}
        />
        {cropModalOpen && (
          <ImageCropperModal
            visible={cropModalOpen}
            imageUrl={croppingUrl}
            onCancel={handleCancel}
            onCrop={handleCrop}
            aspectRatio={aspectRatio}
            mimeType={croppingUrlMimeType === 'image/png' ? croppingUrlMimeType : 'image/jpeg'}
          />
        )}
      </div>
    </div>
  );
};

export default ChangeProfile;
