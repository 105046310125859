import React from 'react';
import cn from 'classnames';

import { BackArrowIcon } from 'components/icons';
import AddEventForm from 'components/Event/AddEventForm';
import { useParams, useLocation } from 'react-router-dom';

const AddEvent = () => {
  const { eventId, celebrityId } = useParams();
  const location = useLocation();

  const isGolive = location.pathname.includes('go-live-now');
  return (
    <div className={cn('add-event')}>
      <div className="add-event-gradient" />
      <div className="layer content-container">
        <div className="add-event-content">
          <div className="container" style={{ overflow: 'hidden' }}>
            <div className="back-button" onClick={() => window.history.back()}>
              <BackArrowIcon />
            </div>
            <h1 className="title">
              {isGolive ? 'Go Live Now' : eventId ? 'Edit / Reschedule New Event' : 'Add / Schedule New Event'}
            </h1>
           <AddEventForm eventId={eventId} celebrityId={celebrityId} isGolive={isGolive} />
          </div>
        </div>
      </div>
    </div>
  );
};

AddEvent.loadData = async () => {};

export default AddEvent;
