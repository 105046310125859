let heic2any;

if (typeof window !== 'undefined') {
  import('heic2any').then(module => {
    heic2any = module.default;
  });
}
export const convertHeicToJpeg = async file => {
  try {
    // Check for all possible HEIC/HEIF variations
    const isHeicType =
      /^image\/(heic|heif|heic-sequence|heif-sequence)$/i.test(file.type) ||
      file.name.toLowerCase().endsWith('.heic') ||
      file.name.toLowerCase().endsWith('.heif');

    if (isHeicType) {
      // Convert HEIC to JPEG blob
      const jpegBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.9
      });

      // Create a new File from the blob
      return new File([jpegBlob], file.name.replace(/\.(heic|heif)$/i, '.jpg'), {
        type: 'image/jpeg'
      });
    }
    return file;
  } catch (error) {
    console.error('HEIC conversion error:', error);
    throw new Error('Failed to convert HEIC image');
  }
};
