/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { shape, bool, func } from 'prop-types';
import cn from 'classnames';
import { routesPaths } from 'constants/routesPaths';
import { celebrityShape, serieShape } from 'constants/propTypesShapes';
import { routeWithProps } from 'utils/helpers';
import { PlayIcon, ArrowIcon, InfoIcon, TrashIcon, OptionsIcon, EditIcon } from 'components/icons';
import ReducedLogo from 'components/common/Brand/ReducedLogo';
import Button from 'components/common/Button/Button';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import { useClickOutside, useModal, useNavigation } from 'hooks';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';
import DeletePublication from 'components/DeletePublication/DeletePublication';
import Modal from 'components/common/Modal/Modal';
import ReportModal from 'components/ReportModal/ReportModal';

const CLOSED = 0;
const OPENED = 1;
const SerieBannerDesktop = ({
  serie,
  showEpisodes,
  toggleShowEpisodes,
  onClickPlay,
  toggleLanding,
  toggleTrailer,
  withBorder,
  refresh,
  celebrityId,
  isLoggedUser
}) => {
  const { toggle, isShowing } = useModal();
  const { goTo } = useNavigation();
  const { toggle: reportToggle, isShowing: reportShowing } = useModal();
  const [optionsStatus, setOptionsStatus] = useState(CLOSED);
  const { bannerUrl, logoImageUrl, title } = serie;
  const ref = useClickOutside(() => setOptionsStatus(CLOSED), optionsStatus);
  const handleEditSeries = () => {
    goTo(
      routeWithProps(routesPaths.editOriginals, {
        seriesId: serie.id,
        celebrityId
      })
    );
  };

  return (
    <div className={cn('serie-banner', { separator: !showEpisodes })}>
      <div className={`serie-banner-card ${withBorder ? 'series-banner-yellow' : ''}`}>
        <BackgroundOpacity background={bannerUrl} opacity={0.4} isVideo />
        <div onClick={toggleTrailer} className="layer serie-banner-card-info">
          <img src={logoImageUrl} alt="series logo" className="serie-banner-card-logo" />
          <ReducedLogo />
        </div>
        <div className="sharing-options" ref={ref}>
          {optionsStatus === OPENED && (
            <>
              {!isLoggedUser && (
                <button className="report-button" onClick={reportToggle}>
                  Report
                </button>
              )}
              {isLoggedUser && <IconWithBackground icon={<TrashIcon />} onClick={toggle} isSmall />}
              {isLoggedUser && (
                <IconWithBackground
                  icon={<EditIcon />}
                  onClick={() => handleEditSeries()}
                  isSmall
                />
              )}
              {/* <IconWithBackground
                  icon={<ShareIcon />}
                  onClick={() => setOptionsStatus(ICONS_DISPLAYED)}
                  isSmall
                /> */}
            </>
          )}
          {(optionsStatus === CLOSED || optionsStatus === OPENED) && (
            <IconWithBackground
              icon={<OptionsIcon />}
              onClick={() => setOptionsStatus(optionsStatus === OPENED ? CLOSED : OPENED)}
              isSmall
            />
          )}
          {isShowing && (
            <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
              <DeletePublication
                type="series"
                refresh={refresh}
                celebrityId={celebrityId}
                id={serie.id}
              />
            </Modal>
          )}
          {reportShowing && (
            <Modal
              hide={reportToggle}
              isShowing={reportShowing}
              topModal
              zIndex={zIndexModalThird}
              className="original-modal"
            >
              <ReportModal title="Report Content" type="isSeries" id={serie.id} />
            </Modal>
          )}
        </div>
      </div>
      <div className="serie-banner-description p1">{title}</div>
      <div className="serie-banner-info-buttons">
        <Button onClick={onClickPlay} type="primary" size="small" icon={<PlayIcon />} />
        <Button
          labelId="episodes.all"
          onClick={toggleShowEpisodes}
          type="secondary"
          size="small"
          icon={<ArrowIcon type={showEpisodes ? 'up' : 'down'} />}
        />
        <Button
          onClick={toggleLanding}
          type="secondary"
          size="small"
          icon={<InfoIcon />}
          className="info-icon"
        />
      </div>
    </div>
  );
};

SerieBannerDesktop.propTypes = {
  serie: shape(serieShape).isRequired,
  showEpisodes: bool,
  toggleShowEpisodes: func.isRequired,
  toggleLanding: func.isRequired,
  toggleTrailer: func.isRequired,
  onClickPlay: func.isRequired,
  withBorder: bool,
  refresh: func,
  celebrityId: shape(celebrityShape),
  isLoggedUser: bool
};

export default SerieBannerDesktop;
