/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/button-has-type */
import { useSession, useModal, useNavigation } from 'hooks';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Loading from 'components/common/Loading';
import DummyImg from 'assets/IDBackForAds.png';
import TrashIcon from 'assets/trash.png';
import DetailPageArrowIcon from 'assets/detailPageArrow.png';
import EditSquareIcon from 'assets/editSquare.png';
import AdService from 'services/adService';
import parseError from 'utils/parseError';
import Modal from 'components/common/Modal/Modal';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import { adTypes } from 'constants/constants';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { customStyles } from './tableStyle';
import DeleteAd from './DeleteAd/DeleteAd';

const VideoAdsList = () => {
  const { toggle, isShowing } = useModal();
  const [currentId, setCurrentId] = useState();
  const [videoAds, setVideoAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const { goToWithSearch, goTo } = useNavigation();
  const {
    user: { id: celebrityId }
  } = useSession();
  const gotoedit = id => {
    const route = routeWithProps(routesPaths.editvideoAds, {
      videoId: id,
      celebrityId
    });
    goToWithSearch(route);
  };
  const columns = [
    {
      name: 'Thumbnail',
      selector: row => row.title,
      cell: row => (
        <div className={cn('thumbnail-container')}>
          <img
            src={row?.thumbnailsUrls?.[0]?.src || DummyImg}
            alt={row.title}
            className={cn('thumbnail-image')}
          />
        </div>
      )
    },
    {
      name: 'Title & Duration',
      selector: row => row.title,
      cell: row => (
        <div className={cn('duration-container')}>
          <div className={cn('title-container')}>
            <div style={{ fontSize: '18px', fontWeight: '600', marginBottom: '0.5rem' }}>
              {row.title}
            </div>
            <div className={cn('ad-duration')}>
              {row.videoLength ? `00:00:${row.videoLength}` : '-'}
            </div>
          </div>
        </div>
      )
    },
    {
      name: 'Active in',
      cell: row => (
        <div className={cn('title-container')}>
          <div className={cn('ad-active-in')}>
            {row.narrativeCounts > 0 &&
              `${row.narrativeCounts} Shorts${`${row.episodeCounts > 0 ? ', ' : ''}`}`}
            {row.episodeCounts > 0 && `${row.episodeCounts} Movies & Series`}
            {row.episodeCounts === 0 && row.narrativeCounts === 0 && '-'}
          </div>
        </div>
      )
    },
    {
      name: 'End date',
      selector: row => row.endDate,
      format: row =>
        row.endDate === null
          ? '-'
          : new Date(row.endDate).toLocaleDateString('en-US', { timeZone: 'UTC' })
    },
    {
      name: 'Impressions',
      selector: row => row.impressions,
      cell: row => <div>{row.impressions || '-'}</div>
    },
    {
      name: 'Clicks',
      selector: row => row.clicks,
      cell: row => <div>{row.clicks || '-'}</div>
    },
    {
      name: 'Actions',
      cell: row => (
        <div className={cn('table-action-flex')}>
          <button
            style={{ cursor: 'pointer' }}
            className="action-button"
            onClick={() => gotoedit(row.id)}
          >
            <img height="20px" width="20px" src={EditSquareIcon} alt="edit" />
          </button>
          <button
            style={{ cursor: 'pointer' }}
            className="action-button"
            onClick={() => {
              setCurrentId(row.id);
              toggle();
            }}
          >
            <img height="20px" width="20px" src={TrashIcon} alt="trash" />
          </button>
          <button
            style={{ cursor: 'pointer' }}
            className="action-button"
            onClick={() => {
              goTo(routeWithProps(routesPaths.adDetails, { type: adTypes.VIDEO, id: row.id }));
            }}
          >
            <img height="20px" width="20px" src={DetailPageArrowIcon} alt="detailPage" />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ];

  const getVideoAdList = async () => {
    try {
      setLoading(true);
      const { data } = await AdService.getVideoAdList(celebrityId);
      setVideoAds(data.videoAds);
    } catch ({ response }) {
      throw parseError(response);
    } finally {
      setLoading(false);
    }
  };
  const handleApiCall = () => {
    getVideoAdList();
  };
  useEffect(() => {
    getVideoAdList();
  }, []);

  return (
    <div className={cn('bannerAdsList')}>
      <div className={cn('table-container')}>
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={videoAds}
          pagination={false}
          progressPending={loading}
          progressComponent={
            <div className="content-loading">
              <Loading />
            </div>
          }
          responsive
        />
      </div>
      <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
        <DeleteAd
          handleApiCall={handleApiCall}
          celebrityId={celebrityId}
          currentId={currentId}
          type={adTypes.VIDEO}
        />
      </Modal>
    </div>
  );
};

export default VideoAdsList;
