import React, { useEffect, useState } from 'react';

import { getHome } from 'state/actions/homeActions';
import { useDispatch, useAnalytics, useHome, useSession } from 'hooks';
import { useSeries } from 'hooks/useSeries';
import useProfile from 'hooks/users/useProfile';
import useShowPushNotification from 'hooks/notifications/useShowPushNotification';
import { MIXPANEL_EVENTS } from 'constants/constants';

import FeaturedHero from 'components/featured/FeaturedHero/FeaturedHero';
import FeaturedCelebrities from 'components/celebrity/FeaturedCelebrities/FeaturedCelebrities';
import CategorySection from 'components/categoryList/CategorySection/CategorySection';
import KeepWatchingSection from 'components/series/KeepWatchingSection/KeepWatchingSection';
import Loading from 'components/common/Loading';

import { getFullName } from 'utils/helpers';
import parseError from 'utils/parseError';
import EventService from 'services/eventService';
import FeaturedUpcomingEvents from 'components/celebrity/FeaturedUpcomingEvents/FeaturedUpcomingEvents';

const HomePage = () => {
  const { trackEvent } = useAnalytics();
  const { initializePushNotifications } = useShowPushNotification();
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);

  useEffect(() => {
    initializePushNotifications();
  }, []);

  const getHomeRequest = useDispatch(getHome);
  const { keepWatchingList } = useSeries();
  const { authenticated } = useSession();

  const { celebrities, featuredCategories, content, loading } = useHome();
  const { requestProfile } = useProfile();

  useEffect(() => {
    if (!content?.length) {
      getHomeRequest();
    }
    if (authenticated) {
      requestProfile();
    }
    trackEvent(MIXPANEL_EVENTS.home);
  }, []);

  const getEvents = async () => {
    try {
      const { data: upcoming } = await EventService.getAllEvents('upcoming');
      const { data: live } = await EventService.getAllEvents('live');
      setUpcomingEvents(upcoming?.events);
      setLiveEvents(live?.events);
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const getLiveEvents = async () => {
    try {
      const { data: live } = await EventService.getAllEvents('live');
      setLiveEvents(live?.events);
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const getUpcomingEvents = async () => {
    try {
      const { data: upcoming } = await EventService.getAllEvents('upcoming');
      setUpcomingEvents(upcoming?.events);
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    getEvents(); 

    const intervalId = setInterval(() => {
      getLiveEvents();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const keepWatchingFiltered = keepWatchingList.filter(
    element => content.findIndex(({ id, liveEvent }) => id == element.id || !liveEvent) !== -1
  );

  const onCelebrityClick = celebrity => {
    const { firstName, lastName } = celebrity;
    trackEvent(MIXPANEL_EVENTS.featuredCreator, { creatorName: getFullName(firstName, lastName) });
  };

  if (loading) {
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <FeaturedHero content={content} />
      {keepWatchingFiltered && keepWatchingFiltered.length > 0 && (
        <KeepWatchingSection series={keepWatchingFiltered} />
      )}
      <FeaturedCelebrities
        celebrities={celebrities}
        titleId="featured.creators"
        onCelebrityClick={onCelebrityClick}
      />
      {liveEvents.length > 0 && (
        <FeaturedUpcomingEvents title="Live Now" events={liveEvents} />
      )}
      {upcomingEvents.length > 0 && (
        <FeaturedUpcomingEvents title="Upcoming Events" events={upcomingEvents} onSuccess={getUpcomingEvents} />
      )}

      {featuredCategories.map(category => (
        <CategorySection category={category} key={category.id} />
      ))}
    </>
  );
};

HomePage.loadData = async store => {
  await store.dispatch(getHome());
};

export default HomePage;
