// AddWidgetForm.js
import { LOADING } from '@rootstrap/redux-tools';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { useForm, useModal, useTextInputProps, useValidation, useToast, useIsMobile } from 'hooks';
import { widgetAdValidation } from 'utils/constraints';
import React, { memo, useRef, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Modal from 'components/common/Modal/Modal';
import ImageCropperModal from 'components/common/ImageCropper/ImageCropperModal';
import { urlToFile } from 'components/common/ImageCropper/UrlToImage';
// import { formatDate } from 'utils/date';
// import ArrowRightIcon from 'assets/arrow-right.png';
import useUploadWidgetAd from 'hooks/adManager/useUploadWidgetAd';
import useUpdateWidgetAd from 'hooks/adManager/useUpdateWidgetAd';
import { handleDateKeyDown, validateImage, isValidURL, checkImageDimensions } from 'utils/helpers';
import AdService from 'services/adService';
import parseError from 'utils/parseError';
import { getTodayDate } from 'utils/date';
import { imageSizes } from 'constants/constants';
import AdItem from './widgetAdItems';
import SelectContentModal from './SelectContentModal';
import UpgradeModal from 'components/upgradeModal/UpgradeModal';

const messages = defineMessages({
  title: { id: 'title' },
  bannerAdTitle: { id: 'bannerAd.title' },
  actionButtonLabel: { id: 'label.actionButton' },
  actionButtonPlaceHolder: { id: 'placeholder.actionButton' },
  buttonTextLabel: { id: 'label.ButtonText' },
  buttontextPlaceHolder: { id: 'placeholder.ButtonText' },
  adDateLabel: { id: 'label.adEndDate' },
  adDatePlaceHolder: { id: 'placeholder.adEndDate' },
  selectContentLabel: { id: 'label.selectContent' },
  selectContent: { id: 'btn.selectContent' },
  desktopWidgetLabel: { id: 'label.desktopWidget' },
  upload: { id: 'placeholder.upload' },
  desktopWidgetExtras: { id: 'extra.desktopWidget' },
  mobileWidgetLabel: { id: 'label.mobileWidget' },
  mobileBannerExtras: { id: 'extra.mobileWidget' },
  SingleShorts: { id: 'content.shorts' },
  SingleOrignals: { id: 'content.originals' }
});

const fields = {
  id: 'id',
  title: 'title',
  widget_ad_images: 'widget_ad_images',
  content_selection: 'content_selection',
  end_date: 'end_date',
  narrative_ids: 'narrative_ids',
  episode_ids: 'episode_ids'
};

const AddWidgetForm = ({ id, celId, contentType, contentid }) => {
  const intl = useIntl();
  const { toggle, isShowing } = useModal();
  const { toggle: upgradeToggle, isShowing: upgradeShowing } = useModal();
  const widgetRef = useRef(null);
  const { showErrorToast } = useToast();
  const [widget, setWidget] = useState(null);
  const [actionButton, setActionButton] = useState('');
  const [actionButtonUrl, setActionButtonUrl] = useState('');
  const [actionButtonError, setActionErrorButton] = useState(null);
  const [actionButtonUrlError, setActionButtonErrorUrl] = useState(null);
  const validator = useValidation(widgetAdValidation);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [currentImageFile, setCurrentImageFile] = useState(null);
   const [croppingUrlMimeType, setCroppingUrlMimeType] = useState();
  const [croppingUrl, setCroppingUrl] = useState();
  const [ratio, setRatio] = useState();
  const [widgetError, setWidgetError] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [widgetsAd, setWidgetAd] = useState();
  const [labelSelectedContent, setlabelSelectedContent] = useState('');
  const [contenteDisable, setcontenteDisable] = useState(false);
  const isMobile = useIsMobile();
  // const { onSubmit, status, error } = useUploadWidgetAd();
  const {
    onSubmit: uploadWidgetAd,
    status: uploadStatus,
    error: uploadError,
    isOverLimit
  } = useUploadWidgetAd();
  const {
    onSubmit: updateWidgetAd,
    status: updateStatus,
    error: updateError
  } = useUpdateWidgetAd();
  const handleFormSubmit = async () => {
    const payload = {
      id: values[fields.id],
      title: values[fields.title],
      widget_ad_images: values[fields.widget_ad_images],
      end_date: values[fields.end_date] ? values[fields.end_date] : '',
      narrative_ids: values[fields.narrative_ids],
      episode_ids: values[fields.episode_ids]
    };

    if (id) {
      updateWidgetAd(payload); // Update existing widget
    } else {
      uploadWidgetAd(payload); // Create new widget
    }
  };

  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      validator,
      onSubmit: handleFormSubmit,
      validateOnBlur: true,
      validateOnChange: true
    },
    [handleFormSubmit]
  );

  const [adItems, setAdItems] = useState([]);

  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);

  const handleWidgetUpload = () => {
    widgetRef.current.click();
  };

  const handleWidgetChange = async e => {
    const file = e.target.files[0];
    const validationResult = await checkImageDimensions(
      file,
      imageSizes.widgetAd.width,
      imageSizes.widgetAd.height
    );
    if (validationResult.isValid) {
      setCurrentFile(file);
      setCropModalOpen(true);
      setCroppingUrlMimeType(file?.type);
      setCroppingUrl(URL.createObjectURL(file));
      setRatio(3 / 2);
    } else {
      showErrorToast(validationResult.error);
      e.target.value = null;
    }
  };

  const handleEndDateChange = e => {
    // const endDate = formatDate(e.target.value);
    // const endDate = new Date(e.target.value).toISOString().split('T')[0];
    handleValueChange(fields.end_date, e.target.value);
  };

  const validateWidget = widget => {
    const widgetErrors = { isValid: true };
    if (widget.action_button_url && !widget.button_text && widget.button_text.trim() === '') {
      widgetErrors.isValid = false;
    }
    if (widget.action_button_url && widget.button_text && widget.button_text.length > 50) {
      widgetErrors.isValid = false;
    }
    if (widget.action_button_url && !isValidURL(widget.action_button_url)) {
      widgetErrors.isValid = false;
    }
    if (!widget.image && !validateImage(widget.image).valid) {
      if (validateImage(widget.image).error) {
        widgetErrors.image = validateImage(widget.image).error;
      } else {
        widgetErrors.image = 'Image is required';
      }
    }
    return widgetErrors;
  };

  const transformData = async data => {
    const transformedData = await Promise.all(
      data.map(async item => {
        // Convert URL to File object
        let file = null;
        if (item.widgetAdImageUrl) {
          try {
            file = await urlToFile(item.widgetAdImageUrl);
          } catch (error) {
            console.error('Error converting image URL to File:', error);
          }
        }

        return {
          action_button_url: item.actionButtonUrl,
          button_text: item.buttonText,
          devise_type: item.deviseType,
          image: file
        };
      })
    );

    return transformedData;
  };

  const handleAddWidget = () => {
    setWidgetError('');
    const widgetErrors = validateWidget({
      button_text: actionButton,
      action_button_url: actionButtonUrl,
      image: widget
    });
    if (!widgetErrors.isValid) return;
    delete widgetErrors.isValid;
    if (Object.keys(widgetErrors).length > 0) {
      console.error('Widget validation failed:', widgetErrors);
      const errorMessage = Object.values(widgetErrors).join(', ');
      // setWidgetError(errorMessage);
      showErrorToast(errorMessage);

      return;
    }
    // const existingWidget = adItems.find(
    //   item => item.button_text === actionButton && item.action_button_url === actionButtonUrl
    // );

    // if (!existingWidget) {
    const widgets = [...adItems];
    widgets.push({
      button_text: actionButton,
      action_button_url: actionButtonUrl,
      image: widget,
      devise_type: 'mobile'
    });
    setAdItems(widgets);
    handleValueChange(fields.widget_ad_images, widgets);
    // }
    setActionButton('');
    setActionButtonUrl('');
    setCurrentFile(null);
    setCurrentImageFile(null);
  };

  const handleSaveContent = content => {
    setSelectedContent(content);
    setValues(prevValues => {
      const newValues = { ...prevValues };

      if (content.narratives.length > 0) {
        const narrativeIds = content.narratives;
        newValues[fields.narrative_ids] = narrativeIds || [];
      }

      if (content.series.length > 0) {
        const episodeIds = content.series;
        newValues[fields.episode_ids] = episodeIds || [];
      }

      return newValues;
    });

    toggle();
    setlabelSelectedContent(
      `${content.series.length} MOVIES & SERIES, ${content.narratives.length} SHORTS SELECTED`
    );
  };

  const getWidgetAdsData = async () => {
    try {
      const { data } = await AdService.getWidgetAdByID(id, celId);
      setWidgetAd(data?.widgetAd);
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const handleCancel = () => {
    setCropModalOpen(false);
    setCroppingUrlMimeType();
    setCroppingUrl();
    setRatio();
    setCurrentFile(null);
  };

  const handleWidgetBlur = name => {
    if (name === 'action_button_url') {
      if (actionButtonUrl && !isValidURL(actionButtonUrl)) {
        setActionButtonErrorUrl(['Action button url is invalid']);
      } else {
        setActionButtonErrorUrl(null);
      }
    }

    if (name === 'button_text') {
      if (actionButtonUrl && !actionButton && actionButton.trim() === '') {
        setActionErrorButton(['Button text is required']);
      } else if (actionButtonUrl && actionButton && actionButton.length > 15) {
        setActionErrorButton(['Button text is too long (maximum is 15 characters)']);
      } else {
        setActionErrorButton(null);
      }
    }
  };

  // const renderButtonLabel = () => {
  //   if (text !== '') {
  //     return (
  //       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  //         {text}
  //         <img
  //           src={ArrowRightIcon}
  //           alt="arrow"
  //           style={{ marginLeft: '8px', width: '16px', height: '16px' }}
  //         />
  //       </div>
  //     );
  //   }
  //   return 'Select Content';
  // };

  // useEffect(() => {
  //   if (selectedContent?.narratives || selectedContent?.series) {
  //     const narrativesCount = selectedContent.narratives.length;
  //     const seriesCount = selectedContent.series.length;
  //     let buttonText = '';

  //     if (narrativesCount > 0 && seriesCount > 0) {
  //       buttonText = `${seriesCount} ORIGINALS, ${narrativesCount} NARRATIVE SELECTED`;
  //     } else if (narrativesCount > 0) {
  //       buttonText = `${narrativesCount} NARRATIVE${narrativesCount > 1 ? 'S' : ''} SELECTED`;
  //     } else if (seriesCount > 0) {
  //       buttonText = `${seriesCount} ORIGINAL${seriesCount > 1 ? 'S' : ''} SELECTED`;
  //     }
  //     setText(buttonText);
  //   }
  // }, [selectedContent]);

  useEffect(() => {
    const updateState = async () => {
      if (widgetsAd && id) {
        const transformedData = await transformData(widgetsAd?.widgetAdImages);
        if (transformedData && transformedData[0].image) setAdItems(transformedData);

        const episodeIds = widgetsAd?.episodes.map(item => item.episodeId);
        const narrativeIds = widgetsAd?.narratives.map(item => item.id);
        setSelectedContent({
          narratives: narrativeIds,
          series: episodeIds
        });

        setValues(prevValues => {
          const newValues = { ...prevValues };

          newValues[fields.id] = widgetsAd.id || id;
          if (transformedData && transformedData[0].image)
            newValues[fields.widget_ad_images] = transformedData || [];

          newValues[fields.title] = widgetsAd.title;
          const formattedDate = widgetsAd.endDate
            ? new Date(widgetsAd.endDate).toISOString().split('T')[0]
            : '';
          newValues[fields.end_date] = formattedDate;

          if (widgetsAd.narratives.length > 0) {
            // const narrativeIds = widgetsAd.narratives.map(item => item.id);
            newValues[fields.narrative_ids] = narrativeIds || [];
          }

          if (widgetsAd.episodes.length > 0) {
            // const episodeIds = widgetsAd.episodes.map(item => item.episodeId);
            newValues[fields.episode_ids] = episodeIds || [];
          }

          return newValues;
        });

        setlabelSelectedContent(
          `${widgetsAd.episodes.length} MOVIES & SERIES, ${widgetsAd.narratives.length} SHORTS SELECTED`
        );
      }
    };

    updateState();
  }, [widgetsAd]);

  useEffect(() => {
    if (id) {
      getWidgetAdsData();
    }
  }, [id]);

  useEffect(() => {
    if (isOverLimit) {
      upgradeToggle();
    }
  }, [isOverLimit]);

  const handleCrop = cropImg => {
    setWidget(cropImg);
    setCurrentImageFile(currentFile);
  };

  const handleDeleteAdItem = index => {
    const newAdItems = adItems.filter((_, i) => i !== index);
    setAdItems(newAdItems);
    handleValueChange(fields.widget_ad_images, newAdItems);
  };
  const handleCustom = () => {
    if (contentType === 'narrative') {
      setSelectedContent({
        narratives: [contentid],
        series: []
      });
      setlabelSelectedContent(messages.SingleShorts.id);
      setValues(prevValues => {
        const newValues = { ...prevValues };
        newValues[fields.narrative_ids] = [contentid];
        return newValues;
      });
      setcontenteDisable(true);
    } else if (contentType === 'seriesid') {
      setSelectedContent({
        narratives: [],
        series: [contentid]
      });
      setlabelSelectedContent(messages.SingleOrignals.id);
      setValues(prevValues => {
        const newValues = { ...prevValues };
        newValues[fields.episode_ids] = [contentid];
        return newValues;
      });
      setcontenteDisable(true);
    }
  };
  useEffect(() => {
    handleCustom();
  }, [contentType, contentid]);
  return (
    <div className="creator-form">
      <div className="creator-form-fields">
        <Input
          name="title"
          placeholder={intl.formatMessage(messages.bannerAdTitle)}
          label={intl.formatMessage(messages.title)}
          errors={errors[fields.title]}
          {...inputProps(fields.title)}
        />
        <div className="date-card">
          <Input
            name="end_date"
            type="date"
            placeholder={intl.formatMessage(messages.adDatePlaceHolder)}
            label={intl.formatMessage(messages.adDateLabel)}
            value={values[fields.end_date]}
            onKeyDown={handleDateKeyDown}
            onChange={handleEndDateChange}
            min={getTodayDate()}
          />
        </div>
        <input
          type="file"
          ref={widgetRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="web_widget"
          onChange={handleWidgetChange}
        />
        <div>
          <span
            style={{
              textTransform: 'uppercase',
              color: '#959595',
              marginLeft: !isMobile ? '15px' : ''
            }}
            className="tag-bold upload-widget-heading"
          >
            Upload Widget
          </span>
          {adItems.length > 0 &&
            adItems.map((item, index) => (
              <AdItem
                key={index}
                thumbnail={URL.createObjectURL(item.image)}
                title={item.button_text}
                link={item.action_button_url}
                onDelete={handleDeleteAdItem}
                index={index}
              />
            ))}

          <div style={{ marginBottom: '26px' }} className="input-container-widget">
            <div style={{ paddingTop: !isMobile ? '10px' : '' }} className="input-card">
              <div className="upload-div">
                {currentImageFile ? (
                  <div style={{ justifyContent: 'unset' }} className="white-label">
                    <span className="white-name">{currentImageFile?.name}</span>
                    <span
                      className="white-name"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setCurrentImageFile(null)}
                    >
                      x
                    </span>
                  </div>
                ) : (
                  <div className="button-margin">
                    {adItems.length > 0 && (
                      <div
                        style={{
                          borderTop: '1px solid #5C5B5B',
                          paddingTop: '10px',
                          margin: !isMobile ? '0 1.4rem 0' : ''
                        }}
                      />
                    )}
                    <Button
                      labelId={intl.formatMessage(messages.upload)}
                      type="secondary"
                      onClick={handleWidgetUpload}
                      className="profile-button"
                    />
                    <span
                      style={{ fontFamily: 'sans-serif', margin: isMobile ? 0 : '' }}
                      className="label-margin"
                    >
                      {intl.formatMessage(messages.desktopWidgetExtras)}
                    </span>
                  </div>
                )}
              </div>
              <Input
                name="action_button_url"
                placeholder={intl.formatMessage(messages.actionButtonPlaceHolder)}
                label={intl.formatMessage(messages.actionButtonLabel)}
                errors={actionButtonUrlError}
                onBlur={() => handleWidgetBlur('action_button_url')}
                // {...inputProps(fields.widget_ad_images[0].action_button_url)}
                value={actionButtonUrl}
                onChange={e => {
                  setActionButtonUrl(e.target.value);
                }}
              />
              {actionButtonUrl && (
                <Input
                  name="button_text"
                  placeholder={intl.formatMessage(messages.buttontextPlaceHolder)}
                  label={intl.formatMessage(messages.buttonTextLabel)}
                  errors={actionButtonError}
                  onBlur={() => handleWidgetBlur('button_text')}
                  // {...inputProps(fields.widget_ad_images[0].button_text)}
                  value={actionButton}
                  onChange={e => {
                    setActionButton(e.target.value);
                  }}
                />
              )}

              {widgetError && <div className="creator-form-error p1">{widgetError}</div>}
              <div
                className="add-widget-button"
                style={{ cursor: 'pointer', padding: !isMobile ? '0px 0px 10px 10px' : '' }}
                onClick={() => {
                  handleAddWidget();
                }}
              >
                Add New Widget
              </div>
            </div>
          </div>
        </div>
        <div className="button-margin">
          <Button
            labelId={labelSelectedContent || messages.selectContent.id}
            type="secondary"
            onClick={toggle}
            className="profile-button"
            disabled={contenteDisable}
          />
        </div>
      </div>
      {!isOverLimit && (
        <div className="creator-form-error p1">{id ? updateError : uploadError}</div>
      )}
      <Button
        labelId={id ? 'title.edit' : 'banner.btnName'}
        className="submit-button"
        onClick={handleSubmit}
        disabled={
          (id ? updateStatus : uploadStatus) === LOADING ||
          !values[fields.title] ||
          !adItems.length ||
          selectedContent === null
        }
      />
      {(id ? updateStatus : uploadStatus) === LOADING && <Loading type="ball-clip-rotate" />}
      <Modal hide={toggle} isShowing={isShowing} className="series-form-modal">
        <SelectContentModal
          content={selectedContent}
          handleClose={toggle}
          onSaveContent={handleSaveContent}
        />
      </Modal>
      <Modal hide={upgradeToggle} isShowing={upgradeShowing} className="series-form-modal">
        <UpgradeModal />
      </Modal>
      {cropModalOpen && (
        <ImageCropperModal
          visible={cropModalOpen}
          imageUrl={croppingUrl}
          onCancel={handleCancel}
          onCrop={handleCrop}
          aspectRatio={ratio}
          mimeType={croppingUrlMimeType === 'image/png' ? croppingUrlMimeType : 'image/jpeg' }
        />
      )}
    </div>
  );
};

export default memo(AddWidgetForm);
