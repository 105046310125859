import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';
import { useAnalytics, useNavigation, useToast } from 'hooks';
import { useLogger } from 'components/common/LogViewer';
import capitalize from 'lodash/capitalize';
import { editSeries, getTrailerUrlForJWPlayer } from 'state/actions/seriesActions';
import { parseHashtags, routeWithProps, isValidURL } from 'utils/helpers';
import { clearEpisodes } from 'state/actions/episodesActions';
import { routesPaths } from 'constants/routesPaths';
import parseError from 'utils/parseError';
import UserService from 'services/userService';
import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import axios from 'axios';

export default () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { goTo } = useNavigation();
  const username = useRef();
  const [loading, setLoading] = useState(false);
  const { showErrorToast, showToast } = useToast();
  const [series, setSeries] = useState({});
  const { status, error } = useStatus(editSeries);
  const { trailerData } = useSelector(({ seriesUpload: { trailerData } }) => ({ trailerData }));
  const { episodes } = useSelector(({ episodes: { episodes } }) => ({ episodes }));
  const [completedVideos, setCompletedVideos] = useState(0);
  const [uploadedVideos, setUploadedVideos] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const totalVideoRef = useRef();
  const videoDoneRef = useRef();
  const totalVideos = episodes.length + (!isValidURL(series.trailer) ? 1 : 0);
  const [uploadStage, setUploadStage] = useState('');
  const { status: getTrailerUrlStatus, error: getTrailerUrlError } = useStatus(
    getTrailerUrlForJWPlayer
  );

  // Initialize logger
  const {
    logs,
    showLogs,
    setShowLogs,
    logInfo,
    logWarning,
    logError,
    logApiError,
    clearLogs
  } = useLogger();

  const editNewSeries = async seriesData => {
    logInfo('Preparing to edit series', {
      seriesId: seriesData.id,
      totalEpisodes: episodes.length + (seriesData.episodes?.length || 0)
    });

    const { categoryIds = [], description, hashtags = [], title, trailer, id } = seriesData;
    const data = {
      id,
      description,
      title,
      banner_media_id: trailerData ? trailerData?.mediaId : '',
      mime_type: trailerData ? trailer?.type : seriesData.mimeType
    };

    logInfo('Building episodes list for edit');
    const newEpisodes = [...episodes, ...(seriesData.episodes || [])];
    const episodesList = newEpisodes.map(
      (
        {
          creditsStartTime,
          description,
          earlyReleaseDateTime,
          exclusive,
          mediaId,
          membersPrice,
          nonMembersPrice,
          releaseDateTime,
          title,
          seriesVideo,
          content_viewer_category,
          lead_cast,
          credits,
          episodeId
        },
        index
      ) => ({
        credits_start_time: creditsStartTime,
        early_release_date_time: earlyReleaseDateTime,
        media_id: mediaId,
        members_price: membersPrice === 0 ? null : Number(membersPrice).toFixed(2),
        non_members_price: nonMembersPrice === 0 ? null : Number(nonMembersPrice).toFixed(2),
        release_date_time: releaseDateTime,
        position: index,
        episode_id: episodeId || null,
        mime_type: seriesVideo?.type || 'video/mp4',
        credits,
        content_viewer_category,
        description,
        exclusive,
        title,
        lead_cast
      })
    );

    logInfo('Creating FormData for series edit', {
      categoryCount: categoryIds?.length || 0,
      hashtagCount: hashtags?.length || 0,
      episodesCount: episodesList?.length || 0
    });

    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(`series[${key}]`, data[key]);
    });

    formData.append('series[logo_image]', seriesData.logo_image);
    formData.append('series[desktop_image]', seriesData.desktop_image);
    formData.append('series[mobile_image]', seriesData.mobile_image);

    formData.append('series[category_ids]', JSON.stringify(categoryIds || []));
    formData.append('series[hashtags]', JSON.stringify(hashtags || []));
    formData.append('series[episodes]', JSON.stringify(episodesList || []));

    formData.append('series[lead_cast]', seriesData.lead_cast || '');
    formData.append('series[content_viewer_category]', seriesData.content_viewer_category || '');
    formData.append('series[notify_subscribers]', seriesData.notify_subscribers || false);
    formData.append('series[credits]', seriesData.credits || '');
    // formData.append('series[season]', series.season);

    logInfo('Dispatching editSeries action', { seriesId: seriesData.id });
    await dispatch(editSeries(formData, seriesData.id));
  };

  useEffect(() => {
    // Only proceed if we have a valid series ID
    if (!series.id) {
      return;
    }

    if (getTrailerUrlStatus === SUCCESS) {
      logInfo('Successfully obtained trailer URL for edit', { trailerData });
      editNewSeries(series);
      return;
    }

    if (getTrailerUrlStatus === ERROR) {
      logError('Error getting trailer URL for edit', { error: getTrailerUrlError });
      showErrorToast(getTrailerUrlError);
      dispatch(getTrailerUrlForJWPlayer.reset());
      setLoading(false);
    }
  }, [getTrailerUrlStatus]);

  // Add validation function to be called before proceeding with edit
  const validateBeforeEdit = data => {
    logInfo('Validating series data before edit', {
      hasTrailer: !!data.trailer || isValidURL(data.trailer),
      hasLogo: !!data.logo_image || typeof data.logo_image === 'string',
      hasDesktopImage: !!data.desktop_image || typeof data.desktop_image === 'string',
      hasMobileImage: !!data.mobile_image || typeof data.mobile_image === 'string',
      categoryCount: data.categoryIds?.length
    });

    // Improved category validation with more detailed logging
    if (!data.categoryIds || !Array.isArray(data.categoryIds) || data.categoryIds.length === 0) {
      logError('No categories selected', {
        categoryIds: data.categoryIds,
        type: typeof data.categoryIds,
        isArray: Array.isArray(data.categoryIds),
        length: Array.isArray(data.categoryIds) ? data.categoryIds.length : null
      });
      showErrorToast('Please select at least one category');
      return false;
    }

    logInfo('Validation passed');
    return true;
  };

  const onSubmit = useCallback(
    data => {
      logInfo('Starting series edit submission', {
        title: data.title,
        id: data.id
      });

      // Set loading state immediately
      setLoading(true);

      // Safely map the values and filter out any undefined items
      const categoryIds = (data.categoryIds || [])
        .filter(item => item && typeof item === 'object' && 'value' in item)
        .map(item => item.value);

      logInfo('Processed category IDs', {
        categoryCount: categoryIds.length
      });

      data.hashtags = parseHashtags(data.description);
      data.categoryIds = categoryIds;

      // Validate before proceeding
      if (!validateBeforeEdit(data)) {
        setLoading(false);
        return;
      }

      setSeries(data);

      // Set upload stage to start showing progress indicators
      setUploadStage('validating');

      if (isValidURL(data.trailer)) {
        logInfo('Using existing trailer URL', { trailerUrl: data.trailer });
        // If the trailer is a URL, we'll assume it is already uploaded and can be used directly
        editNewSeries(data);
      } else {
        logInfo('Getting new trailer URL for upload', { fileName: data.trailer?.name });
        // If the trailer is not a URL, we dispatch the action to upload it
        dispatch(getTrailerUrlForJWPlayer(data.trailer));
      }
    },
    [dispatch, logInfo, showErrorToast, validateBeforeEdit]
  );

  const resetDispatchData = () => {
    logInfo('Resetting dispatch data');
    dispatch(clearEpisodes());
    dispatch(editSeries.reset());
    dispatch(getTrailerUrlForJWPlayer.reset());
  };

  const onCompleteVideoUpload = () => {
    logInfo('Video upload process completed for edit');
    resetDispatchData();
    showToast('Publication successfully updated!');
    setLoading(false);

    logInfo('Navigating to celebrity profile');
    goTo(
      routeWithProps(routesPaths.celebrityId, {
        username: username.current.toLowerCase()
      })
    );
  };

  const getProfile = async () => {
    try {
      logInfo('Fetching user profile');
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
      logInfo('Profile fetched successfully', { username: data.user.username });
    } catch (error) {
      logApiError('Error fetching profile', error);
      throw parseError(error.response);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (completedVideos === totalVideos) {
      logInfo('All videos completed upload for edit', {
        completedVideos,
        totalVideos
      });
      onCompleteVideoUpload();
      videoDoneRef.current = 2;
    }
  }, [completedVideos]);

  const uploadFile = async (url, file) => {
    try {
      logInfo('Starting file upload for edit', {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        url
      });

      const totalSize = file.size;
      setUploadStage('uploading');

      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            const uploadedSize = progressEvent.loaded;
            const progress = Math.round((uploadedSize / totalSize) * 100);
            setUploadProgress(progress);

            // Log progress at meaningful intervals
            if (progress % 25 === 0) {
              logInfo(`Upload progress: ${progress}%`, {
                uploaded: uploadedSize,
                total: totalSize,
                fileName: file.name
              });
            }
          }
        }
      });

      logInfo('File upload completed successfully', { fileName: file.name });
      setUploadStage('processing');
    } catch (error) {
      logApiError('Error uploading file', error);
      setUploadStage('');
      throw error;
    }
  };

  const uploadVideosInJWPlayer = async videos => {
    try {
      logInfo('Starting batch upload to JWPlayer for edit', { videoCount: videos.length });
      videoDoneRef.current = 1;
      setUploadProgress(0);
      setUploadedVideos(0);
      totalVideoRef.current = videos.length;
      setUploadStage('validated');

      for (let index = 0; index < videos.length; index++) {
        const video = videos[index];
        logInfo(`Uploading video ${index + 1} of ${videos.length}`, {
          fileName: video.video.name,
          mediaId: video.mediaId
        });

        await uploadFile(video.uploadUrl, video.video);

        setUploadedVideos(prevCount => prevCount + 1);
        setCompletedVideos(prev => prev + 1);
        setUploadProgress(0);

        logInfo(`Completed video ${index + 1} of ${videos.length}`);
      }

      logInfo('All videos uploaded successfully');
      setUploadStage('completed');
    } catch (error) {
      logApiError('Error in batch upload process', error);
      showErrorToast('Something went wrong, try again please!');
      setUploadStage('');
    }
  };

  useEffect(() => {
    if (status === SUCCESS) {
      logInfo('Series updated successfully, preparing to upload videos');
      setLoading(true);
      trackEvent(MIXPANEL_EVENTS.uploadSeries, {
        [MIXPANEL_PARAMS.seriesName]: series.title
      });

      const videosToUpload = [];
      if (!isValidURL(series.trailer)) {
        logInfo('Adding trailer to upload queue', {
          trailerName: series.trailer?.name,
          mediaId: trailerData?.mediaId
        });

        videosToUpload.push({
          video: series.trailer,
          mediaId: trailerData?.mediaId,
          uploadUrl: trailerData?.uploadUrl
        });
      }

      episodes.forEach(({ mediaId, uploadUrl, seriesVideo }) => {
        logInfo('Adding episode to upload queue', {
          videoName: seriesVideo?.name,
          mediaId
        });

        videosToUpload.push({
          video: seriesVideo,
          mediaId,
          uploadUrl
        });
      });

      if (videosToUpload.length === 0) {
        logInfo('No videos to upload, completing process');
        onCompleteVideoUpload();
      } else {
        logInfo('Starting video upload process', { count: videosToUpload.length });
        uploadVideosInJWPlayer(videosToUpload);
      }
    } else if (status === ERROR) {
      logError('Error updating series', { error });
      showErrorToast(error);
    }
  }, [status, dispatch]);

  return {
    onSubmit,
    status,
    loading,
    uploadProgress,
    uploadedVideos,
    error: capitalize(error),
    totalVideos: totalVideoRef.current,
    uploadingStatus: videoDoneRef.current,
    uploadStage,
    logs,
    showLogs,
    setShowLogs
  };
};
