/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { string, number, element, arrayOf } from 'prop-types';
import { maxMobileWidth, maxTabletWidth } from 'styles/common/_constants.scss';

const MasonryGrid = ({
  defaultColumns,
  tabletColumns,
  mobileColumns,
  children,
  className,
  columnClassName
}) => {
  const [columnAmount, setColumnAmount] = useState(() => {
    const screenWidth = window?.innerWidth;
    if (screenWidth <= maxMobileWidth) return mobileColumns;
    if (screenWidth <= maxTabletWidth) return tabletColumns;
    return defaultColumns;
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= maxMobileWidth) {
        setColumnAmount(mobileColumns);
      } else if (screenWidth <= maxTabletWidth) {
        setColumnAmount(tabletColumns);
      } else {
        setColumnAmount(defaultColumns);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [defaultColumns, tabletColumns, mobileColumns]);

  const columns = Array.from({ length: columnAmount }, (_, i) => (
    <div key={`col${i}`} className={columnClassName}>
      {children.filter((_, index) => i === index % columnAmount)}
    </div>
  ));

  return <div className={className}>{columns}</div>;
};

MasonryGrid.propTypes = {
  defaultColumns: number,
  tabletColumns: number,
  mobileColumns: number,
  children: arrayOf(element),
  className: string,
  columnClassName: string
};

MasonryGrid.defaultProps = {
  defaultColumns: 3,
  tabletColumns: 2,
  mobileColumns: 1
};

export default MasonryGrid;
