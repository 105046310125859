import httpClient from 'httpClient';
import { CONTENT_TYPE, MULTIPART_FORM_DATA } from './userService';

class PodcastService {
  static createPodcast(celebrityId, podcast) {
    return httpClient.post(`/celebrities/${celebrityId}/podcasts`, podcast, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => podcast
    });
  }

  static updatePodcast(celebrityId, podcastId, podcast) {
    return httpClient.put(`/celebrities/${celebrityId}/podcasts/${podcastId}`, podcast, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => podcast
    });
  }

  static processPodcast(celebrityId, podcastId) {
    return httpClient.post(`/celebrities/${celebrityId}/podcasts/${podcastId}/generate`, {});
  }

  static deletePodcast(celebrityId, podcastId) {
    return httpClient.delete(`/celebrities/${celebrityId}/podcasts/${podcastId}`);
  }

  static reportPodcast({ podcastId, params }) {
    return httpClient.post(`/podcasts/${podcastId}/reported_contents`, params);
  }

  static getPodcastById(podcastId, celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/podcasts/${podcastId}`);
  }
}

export default PodcastService;
