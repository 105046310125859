import React from 'react';
import { ReducedLogoIcon } from 'components/icons';
import UserService from 'services/userService';

export const isSignedUrlExpired = presignedUrl => {
  try {
    // Parse the URL
    const url = new URL(presignedUrl);

    // Get AWS specific parameters
    const amzDate = url.searchParams.get('X-Amz-Date');
    const expiresIn = url.searchParams.get('X-Amz-Expires');

    if (!amzDate || !expiresIn) {
      throw new Error('Missing required AWS parameters');
    }

    // Parse AWS ISO8601 timestamp (YYYYMMDDTHHMMSSZ)
    const year = amzDate.slice(0, 4);
    const month = amzDate.slice(4, 6);
    const day = amzDate.slice(6, 8);
    const hour = amzDate.slice(9, 11);
    const minute = amzDate.slice(11, 13);
    const second = amzDate.slice(13, 15);

    // Create Date object from timestamp
    const signatureTime = new Date(
      Date.UTC(
        parseInt(year),
        parseInt(month) - 1, // Month is 0-based
        parseInt(day),
        parseInt(hour),
        parseInt(minute),
        parseInt(second)
      )
    );

    // Calculate expiration time
    const expirationTime = new Date(signatureTime.getTime() + parseInt(expiresIn) * 1000);

    // Compare with current time
    const currentTime = new Date();

    return currentTime > expirationTime;
  } catch (error) {
    console.error('Error checking presigned URL:', error);
    throw error;
  }
};

const getProfile = async () => {
  try {
    const { data } = await UserService.getProfile();
    if (!data?.user) {
      throw new Error('User data not found in response');
    }
    return data.user;
  } catch (error) {
    throw error;
  }
};

// Modify these functions to be consistent
export const getUserPicture = (user, size = 16) => {
  return user ? (
    <img
      src={user}
      alt="profile"
      style={{
        WebkitBackfaceVisibility: 'hidden',
        WebkitTransform: 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)'
      }}
    />
  ) : (
    <ReducedLogoIcon height={size} width={size} opacity={1} />
  );
};

export const updateUserProfile = async user => {
  if (isSignedUrlExpired(user)) {
    const updatedUser = await getProfile();
    return updatedUser.profileImageUrl;
  }
  return user;
};

export const getIdFromTab = (tabs, selectedTab) => {
  const tab = Object.values(tabs).find(obj => obj.tab === selectedTab);
  return tab?.id;
};

export const getTabNameFromId = (tabs, selectedId) => {
  const selectedTab = Object.values(tabs).find(obj => obj.id === selectedId);
  return selectedTab?.tab;
};

export const getLocaleFromId = (tabs, selectedId) => {
  const selectedTab = Object.values(tabs).find(obj => obj.id === selectedId);
  return selectedTab?.localeId;
};

export const getEpisodeIndex = (serie, episodeId) => {
  const { episodes, earlyReleaseEpisodes } = serie;
  const episodeIndex = episodes?.findIndex(({ id }) => id == episodeId);

  if (episodeIndex >= 0) {
    const { exclusive, link } = episodes[episodeIndex];
    const needsSubscription = exclusive && !link;
    return { episodeIndex, needsSubscription };
  }

  const earlyReleaseIndex = earlyReleaseEpisodes?.findIndex(({ id }) => id == episodeId);
  return { isEarlyRelease: earlyReleaseIndex >= 0, earlyReleaseIndex };
};

export const filterEpisodes = (episodes, filterEarlyRelease = false) =>
  episodes?.filter(({ link, exclusive }) => (filterEarlyRelease ? !link : link || exclusive));

export const filterAccessibleEpisodes = series => ({
  ...series,
  episodes: series.episodes
});

export const filterSeries = content =>
  content
    ?.filter(
      ({ episodes, firstEpisode, liveEvent }) =>
        filterEpisodes(episodes)?.length || firstEpisode?.link || liveEvent
    )
    .map(element =>
      element.liveEvent
        ? element
        : { ...element, episodes: element.episodes && filterEpisodes(element.episodes) }
    );

export const filterSubCategoriesSeries = subCategories =>
  subCategories
    .filter(({ content }) => content.length)
    .map(subCategory => ({
      ...subCategory,
      content: subCategory.content.filter(
        ({ series, narrative }) => series?.firstEpisode || narrative
      )
    }));
