import React from 'react';
import { shape, func, oneOf } from 'prop-types';
import { useIntl } from 'react-intl';

import { getMaskedCreditCard, getCardIcon, routeWithProps } from 'utils/helpers';
import {
  creditCardShape,
  celebrityShape,
  scheduledLiveEventShape
} from 'constants/propTypesShapes';
import { routesPaths } from 'constants/routesPaths';
import { PURCHASE_TYPES, legalTabs } from 'constants/constants';

import Button from 'components/common/Button/Button';

import Hyperlink from 'components/common/Hyperlink/Hyperlink';
import useSubscribeContext from 'hooks/account/useSubscribeContext';
import useConfirmPayment from './useConfirmPayment';

const ConfirmPayment = ({ creditCard, celebrity, liveEvent, hide, onSuccess, purchaseType }) => {
  const intl = useIntl();
  const { goToChangePaymentMethod } = useSubscribeContext();
  const { onConfirm, loading } = useConfirmPayment(
    celebrity,
    liveEvent,
    onSuccess,
    hide,
    purchaseType
  );

  const { last4, id, brand } = creditCard;

  const cardIcon = getCardIcon(brand);

  return (
    <div className="confirm-payment">
      <div className="checkout-header">
        <h4 className="h4-medium">{intl.formatMessage({ id: 'payments.form.creditCard' })}</h4>
        <div className="secondary-action" onClick={goToChangePaymentMethod}>
          <h6 className="bold">{intl.formatMessage({ id: 'payments.changeMethod' })}</h6>
        </div>
      </div>
      <div className="confirm-field">
        {!!cardIcon && <img src={cardIcon} alt="card" className="card-icon" />}
        {getMaskedCreditCard(last4)}
      </div>
      <div className="legal-messages p2">
        {intl.formatMessage({
          id: celebrity ? 'legalMessages.subscribing' : 'legalMessages.purchasing'
        })}
        <Hyperlink
          text={intl.formatMessage({ id: 'terms.title' })}
          link={routeWithProps(routesPaths.legal, { tab: legalTabs.TERMS.tab })}
        />
        <span>{intl.formatMessage({ id: 'legalMessages.message2' })}</span>
        <Hyperlink
          text={intl.formatMessage({ id: 'privacyPolicies.name' })}
          link={routeWithProps(routesPaths.legal, { tab: legalTabs.PRIVACY.tab })}
        />
        <span>{intl.formatMessage({ id: 'legalMessages.message3' })}</span>
      </div>
      <div className="submit-container">
        <Button
          onClick={() => onConfirm(id)}
          labelId={
            celebrity && !purchaseType
              ? 'membership.completeSubscription'
              : 'payPerView.completePurchase'
          }
          type="primary"
          size="small"
          disabled={loading}
        />
      </div>
    </div>
  );
};

ConfirmPayment.propTypes = {
  creditCard: shape(creditCardShape).isRequired,
  celebrity: shape(celebrityShape),
  liveEvent: shape(scheduledLiveEventShape),
  hide: func.isRequired,
  onSuccess: func,
  purchaseType: oneOf([
    PURCHASE_TYPES.MEMBERSHIP,
    PURCHASE_TYPES.EPISODE,
    PURCHASE_TYPES.PODCAST,
    PURCHASE_TYPES.EVENT,
    PURCHASE_TYPES.MEMBERSHIP_EPISODE
  ])
};

export default ConfirmPayment;
