/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import { shape, bool, string } from 'prop-types';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

import { eventShape, celebrityInfoShape } from 'constants/propTypesShapes';

import NarrativesControls from 'components/Players/Controls/NarrativesControls';
import { useDisappearingControls } from 'hooks';
import ActionableContents from 'components/actionableContents/ActionableContents';
import useVideoPlayer from './useVideoPlayer';
import './videojs.css';

const EventPlayer = React.memo(
  ({
    CurrentIndex,
    event,
    celebrity,
    indexId,
    width,
    height,
    play,
    authenticated
  }) => {
  
    const { handleMouseMove, controlsVisible, handleClick } = useDisappearingControls(true);
    const playerRef = useRef(null);
    const [isBlurred, setIsBlurred] = useState(false);

    const applyBlur = useCallback(() => {
      if (videoRef.current) {
        videoRef.current.style.filter = 'blur(20px)';
      }
      if (playerRef.current) {
        playerRef.current.pause();
      }
      setIsBlurred(true);
    }, []);

    const removeBlur = useCallback(() => {
      if (videoRef.current) {
        videoRef.current.style.filter = 'none';
      }
      if (playerRef.current) {
        playerRef.current.play();
      }
      setIsBlurred(false);
    }, []);

    const handleBlur = useCallback(() => {
      applyBlur();
    }, [applyBlur]);

    const handleUserInteraction = useCallback(() => {
      if (isBlurred) {
        removeBlur();
      }
    }, [isBlurred, removeBlur]);

    useEffect(() => {
      window.addEventListener('blur', handleBlur);

      return () => {
        window.removeEventListener('blur', handleBlur);
      };
    }, [handleBlur]);
   
    const videoJsOptions = useMemo(
      () => ({
        autoplay: false,
        loop: false,
        controls: true,
        responsive: true,
        fluid: false,
        width,
        height,
        aspectRatio: isMobile ? '9:16' : '12:8',
        sources: [
          {
            src: event?.videoFileUrl,
            type: 'video/mp4'
          }
        ],
        tracks: {
          id: event?.id,
          type: 'Event',
          celebrity,
          title: event?.title,
        }
      }),
      [event?.videoFileUrl, isMobile]
    );

    const onPlayerReady = useCallback(
      player => {
        console.log('Player is ready', player, event?.videoFileUrl);
        playerRef.current = player;
      },
      [event?.videoFileUrl]
    );
  
    const { videoRef } = useVideoPlayer({
      options: videoJsOptions,
      onReady: onPlayerReady,
   
      link: event?.videoFileUrl,
      mimeType: 'video/mp4'
    });
 

    return (
      <div
        className={cn(
          'video-container video-container-narratives video-ad-container-without-widget visible',
          {
            'no-pointer': isMobile
          }
        )}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        style={{
          position: 'relative',
          width: '100%'
        }}
      >
        <video
          ref={videoRef}
          width={width}
          height={height}
          // onClick={videoClicked}
          id={indexId}
          className="video-js vjs-default-skin"
        />
        {isBlurred && (
          <div className="blur-overlay" onClick={handleUserInteraction}>
            <p>Click to resume video playback</p>
          </div>
        )}
        
        {playerRef.current && play && (
          <>
            <NarrativesControls
              title={event?.title}
              description={event?.title}
              player={playerRef.current}
              celebrity={celebrity}
              narrativeId={event?.id}
              controlsVisible={controlsVisible}
              mute
              isEvent
            />
            <ActionableContents
              videoTitle={event?.title}
              disableSharing={false}
              celebrity={celebrity}
              id={event?.id}
              authenticated={authenticated}
              contentType="Event"
            />
          </>
        )}
      </div>
    );
  }
);

EventPlayer.propTypes = {
  event: shape(eventShape).isRequired,
  celebrity: shape(celebrityInfoShape),
  indexId: string.isRequired,
  play: bool,
};

export default EventPlayer;
