import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import Loading from 'components/common/Loading';
import Metadata from 'components/common/Metadata';
import EventVideoPlayer from 'components/Event/eventVideoPlayer';
import { EventContextProvider } from 'contexts/EventContext';
import UnlockEventLanding from 'components/unlock/UnlockEvent';
import NotFoundPage from 'pages/NotFoundPage';
import { useCelebrity, useToast, useAnalytics, useSession } from 'hooks';
import { checkSubscription, getFullName } from 'utils/helpers';
import {  MIXPANEL_EVENTS } from 'constants/constants';
import useProfile from 'hooks/users/useProfile';
import { getCelebrity } from 'state/actions/celebritiesActions';
import { routesPaths } from 'constants/routesPaths';

const EventPage = () => {
  const intl = useIntl();
  const { showErrorToast } = useToast();
  const { trackEvent } = useAnalytics();
  const { eventId, celebrityId } = useParams();
  const { authenticated } = useSession();
  const dispatch = useDispatch();

  const {
    requestProfile,
    loading: profileLoading,
    profile: { memberships },
  } = useProfile();

  const { celebrity, error, loading: celebrityLoading } = useCelebrity();
  const { events } = celebrity;

  const resetCelebrity = celebrity.id !== Number(celebrityId);
  const event = useMemo(() => events?.find(item => item.id == eventId), [events, eventId]);
  const eventUnavailable = !event && !resetCelebrity;

  const handleReset = () => {
    dispatch(getCelebrity(celebrityId))
  };

  useEffect(() => {
    authenticated && requestProfile();
    (!celebrity || resetCelebrity) && dispatch(getCelebrity(celebrityId));
  }, []);

  useEffect(() => {
    isEmpty(celebrity) && dispatch(getCelebrity(celebrityId));
  }, [celebrity?.firstName]);

  useEffect(() => {
    if (eventUnavailable) {
      showErrorToast(intl.formatMessage({ id: 'events.eventUnavailable' }));
    }
    if (event && !resetCelebrity) {
      const { firstName, lastName } = celebrity;
      trackEvent(MIXPANEL_EVENTS.eventPlayerView, {
        'Creator Name': getFullName(firstName, lastName),
        'Event Title': event.title,
        id: event.id,
        typeName: 'event'
      });
    }
  }, [event, resetCelebrity, eventUnavailable]);


  const eventPrice = useMemo(() => {
    if (!event) return null;
    return checkSubscription(memberships, celebrity?.id)
      ? event.membersPrice?.toFixed(2)
      : event.nonMembersPrice?.toFixed(2);
  }, [event, memberships]);


  const NEED_BUY_EPISODE = useMemo(
    () => eventPrice !== null && eventPrice !== undefined && !event?.videoFileUrl && !event.subscribedToEvent,
    [eventPrice, event]
  );

  const SHOW_PLAYER = useMemo(
    () => event?.videoFileUrl && event.subscribedToEvent,
    [event]
  );

  const SHOW_UNLOCK_CONTENT = useMemo(
    () => NEED_BUY_EPISODE,
    [NEED_BUY_EPISODE]
  );

  
  const UNLOCK_CONFIG = useMemo(() => {
    if (NEED_BUY_EPISODE) {
      return {
        title: intl.formatMessage({ id: 'unlock.title' }),
        buttonLabel: intl.formatMessage({ id: 'unlock.event' }),
        price: eventPrice,
        episodeName: event?.title,
        purchaseType: 'EVENT',
      };
    }
    return {};
  }, [NEED_BUY_EPISODE, intl, eventPrice, event]);


  if (error) return <NotFoundPage />;

  if (isEmpty(events) || resetCelebrity || profileLoading || celebrityLoading) {
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  }

  if (eventUnavailable) {
    return <Redirect to={routesPaths.home} />;
  }

  
  return (
    <EventContextProvider>
      <Metadata socialMediaMetadata={UNLOCK_CONFIG} />
      {SHOW_UNLOCK_CONTENT && (
        <UnlockEventLanding event={event} celebrity={celebrity} config={UNLOCK_CONFIG} onSuccess={handleReset} />
      )}
      {SHOW_PLAYER && (
        <EventVideoPlayer authenticated={authenticated} event={event} celebrity={celebrity} />
      )}
    </EventContextProvider>
  );
};

export default EventPage;
