import React from 'react';
import { object, func } from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import Crown from 'components/icons/membership/Crown';
import { convertUtcToLocal, getFullName } from 'utils/helpers';
import Settings from './Settings';
import { useClickOutside, useToggle } from 'hooks';
import Button from 'components/common/Button/Button';

const EventSubscriptionCard = ({ event, celebrity, cancelEventSubscription }) => {
  const { title, subscribedToEvent, eventStartTimeInUtc } = event;
  const { profileImageUrl, firstName, lastName, bannerUrl } = celebrity;
  const [showCancel, toggleCancel] = useToggle(false);
  const intl = useIntl();
  const ref = useClickOutside(toggleCancel, showCancel);

  const onCancel = async () => {
    cancelEventSubscription(event);
    toggleCancel();
  };

  return (
    <div className="membership-card">
      <div className="membership-card-banner">
        <BackgroundOpacity background={bannerUrl} />
        <div className="gradient" />
      </div>
      <div className="membership-card-content">
      <div style={{marginBottom: '20px'}}>
      {!showCancel && subscribedToEvent === true && <Settings onSelectCancel={toggleCancel} />}
      </div>
        <div className="flex content-row">
          <img className="membership-card-avatar" src={profileImageUrl} alt="avatar" />
          <div className="category-semibold">{getFullName(firstName, lastName)}</div>
        </div>
        {subscribedToEvent === true && (
          <div className={cn('cancel-membership', { visible: showCancel })} ref={ref}>
            <h5 className="bold">{intl.formatMessage({ id: 'subscriptions.confirmCancelEvent' })}</h5>
            <div className="flex-center">
              <Button
                labelId="general.cancel"
                onClick={toggleCancel}
                type="secondary"
                size="xsmall"
              />
              <Button labelId="general.yes" onClick={onCancel} type="red" size="xsmall" />
            </div>
          </div>
        )}
        <div className="flex-end content-row">
          <div className="flex-1">
            <h4 className="semi-bold membership-title">{title}</h4>
            <div className="p1">
              {convertUtcToLocal(eventStartTimeInUtc)}
            </div>
            {/* {status === ACTIVE && (
              <div className="p1">
                {`${intl.formatMessage(
                  { id: 'membershipCard.periodLabel' },
                  { period }
                )} ${getDayAndMonth(currentPeriodEndsAt)} `}
                {intl.formatMessage(
                  { id: 'membershipCard.timeToNextPayment' },
                  {
                    expiry: `${getDiffDay(currentPeriodEndsAt)} ${intl.formatMessage(
                      { id: 'date.days' },
                      { amount: getDiffDay(currentPeriodEndsAt) }
                    )}`
                  }
                )}
              </div>
            )} */}
            {/* {status === ACTIVE_CANCELED && (
              <div className="p1">
                <span className="red">
                  {`${intl.formatMessage({ id: 'membershipCard.canceled' })}. `}
                </span>
                {`${intl.formatMessage({ id: 'membershipCard.activeUntil' })} ${getDayAndMonth(
                  currentPeriodEndsAt
                )} `}
              </div>
            )} */}
          </div>
          <div className="subscription-label">
            <Crown />
          </div>
        </div>
      </div>
    </div>
  );
};

EventSubscriptionCard.propTypes = {
  event: object,
  cancelEventSubscription: func.isRequired
};

export default EventSubscriptionCard;
