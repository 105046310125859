import React from 'react';
import { Switch, Router } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { ModalContextProvider } from 'contexts/ModalContext';

import history from 'utils/history';
import RouteFromPath from 'components/routes/RouteFromPath';
import InternetStatusAlert from 'components/common/InternetStatusAlert';
import routes from '../routes';
import theme from '../constants/theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>Identifi</title>
          <meta name="description" content="Identifi web page" />
        </Helmet>
        <Router history={history}>
          <ModalContextProvider>
          <InternetStatusAlert />
            <Switch>
              {routes.map((route, index) => (
                <RouteFromPath key={`route${index}`} {...route} />
              ))}
            </Switch>
          </ModalContextProvider>
        </Router>
      </>
    </ThemeProvider>
  );
};

export default App;
