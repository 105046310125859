/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect } from 'react';
import { getUserPicture, isSignedUrlExpired, updateUserProfile } from 'utils/dataHelpers';
import { shape, string } from 'prop-types';

import AvatarBorderSmall from 'components/icons/brand/AvatarBorderSmall';
import Button from 'components/common/Button/Button';
import { useCelebrity, useDispatch, useIsMobile, useNavigation } from 'hooks';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import useProfile from 'hooks/users/useProfile';
import { USER_TYPES } from 'hooks/useSession';
import { getCelebrity } from 'state/actions/celebritiesActions';

const UserHeader = ({ user }) => {
  const { profile } = useProfile();
  const { goTo } = useNavigation();
  const { firstName, lastName, username, profileImageUrl } = user;

  const getCelebrityRequest = useDispatch(getCelebrity);
  const { celebrity } = useCelebrity();
  const isMobile = useIsMobile();

  const [profileImage, setProfileImageUrl] = useState();

  useEffect(() => {
    const updateProfile = async () => {
      try {
         if (user && user.profileImageUrl && isSignedUrlExpired(user.profileImageUrl)) {
          const updatedUrl = await updateUserProfile(user.profileImageUrl);
          setProfileImageUrl(updatedUrl);
        }
        else{
          setProfileImageUrl( user.profileImageUrl);
        }
      } catch (error) {
        console.error('Error updating profile image:', error);
      }
    };
    updateProfile();
  }, [profileImageUrl]); // Only depend on the initial URL

  const userProfileImage = useMemo(() => getUserPicture(profileImage, 40), [profileImage]);

  const handleMyID = () => {
    goTo(
      routeWithProps(routesPaths.celebrityId, {
        username: username ? username.toLowerCase() : celebrity.username
      })
    );
  };

  useEffect(() => {
    if (profile.username) {
      getCelebrityRequest(profile.username);
    }
  }, [profile]);
  return (
    <>
      {profile.type === USER_TYPES.user ? (
        <div className="user-header">
          <div className="user-info">
            <h1 className="small">{firstName}</h1>
            <h2>{lastName}</h2>
            <h5>User</h5>
          </div>
          <div className="avatar-container">
            <AvatarBorderSmall className="avatar-borders" />
            {userProfileImage}
          </div>
        </div>
      ) : (
        <div>
          <div className="profile-info">
            <div className="user-header">
              <div className="user-info">
                <h1 className="small">{firstName}</h1>
                <h2>{lastName}</h2>
                <h5>Creator</h5>
              </div>
              <div className="avatar-container">
                <AvatarBorderSmall className="avatar-borders" />
                {userProfileImage}
              </div>
            </div>
            <div className="follower-info">
              <div className="user-info">
                <h1 style={{ color: 'white' }}>{profile.followsCount}</h1>
                <h2 className="small">Followers</h2>
              </div>
            </div>
          </div>
          <div className="id-container">
            <Button
              labelId="MY ID"
              type="secondary"
              onClick={handleMyID}
              className="follow-button"
              smallBoldFont={isMobile}
            />
          </div>
        </div>
      )}
    </>
  );
};

UserHeader.propTypes = {
  user: shape({
    firstName: string,
    lastName: string
  })
};

export default UserHeader;
