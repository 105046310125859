import React, { memo } from 'react';
import { func, string, node, object, number } from 'prop-types';
import cn from 'classnames';

import { white, black60 } from 'styles/common/_constants.scss';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      color: white,
      fontFamily: 'Open Sans',
      fontSize: '16px',
      '::placeholder': {
        color: black60
      }
    }
  }
};

const StripeField = ({ onChange, StripeComponent, label, placeholder, error, width }) => (
  <div className="stripe" style={width ? { width: `${width}rem` } : { flex: 1 }}>
    <label htmlFor="stripe-label" className="stripe-label tag-bold">
      {label}
    </label>
    <div className={cn('stripe-field', { error })}>
      <StripeComponent options={{...CARD_OPTIONS, placeholder}} onChange={onChange} />
    </div>
    {error && <div className="error-message p2">{error.message}</div>}
  </div>
);

StripeField.propTypes = {
  onChange: func.isRequired,
  StripeComponent: node.isRequired,
  label: string.isRequired,
  placeholder: string,
  error: object,
  width: number
};

export default memo(StripeField);
