import React from 'react';
import { arrayOf, func, oneOfType, shape, string } from 'prop-types';

import { celebrityInfoShape } from 'constants/propTypesShapes';

import MiscPlayer from 'components/Players/VideoJs/MiscPlayer';

const TrailerModal = ({ hide, celebrities, videoUrl, title, description, id }) => (
  <div className="trailer-modal">
    <MiscPlayer
      title={title}
      description={description}
      celebrities={celebrities}
      videoUrl={videoUrl}
      type='video/mp4'
      goBack={hide}
      id={id}
    />
  </div>
);

TrailerModal.propTypes = {
  hide: func,
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))])
    .isRequired,
  videoUrl: string.isRequired,
  title: string,
  description: string
};

export default TrailerModal;
