import React from 'react';
import { object } from 'prop-types';
import { convertUtcToLocalDate, convertUtcToLocalTime } from 'utils/helpers';

const EventAbout = ({ event }) => {
  const {
    description,
    eventDate,
    eventStartTimeInUtc,
    duration,
    membersPrice,
    nonMembersPrice,
    status
  } = event;

  return (
    <div className="event-about scrollable-container">
      <div className="date-time-row">
        <div>
          <h5 className="title">Date</h5>
          <span className="date-time-span">{convertUtcToLocalDate(eventStartTimeInUtc)}</span>
        </div>
        <div>
          <h5 className="title">Time</h5>
          <span>{convertUtcToLocalTime(eventStartTimeInUtc)}</span>
        </div>
        <div>
          <h5 className="title">Duration</h5>
          <span className="date-time-span">{duration}</span>
        </div>
      </div>
     {(membersPrice !== 0 || nonMembersPrice !== 0 || status === 'upcoming') &&  <div className="member-row">
        <div>
          <h5 className="title">Members Price</h5>
          <span className="date-time-span">${membersPrice.toFixed(2)}</span>
        </div>
        <div>
          <h5 className="title">Non Members Price</h5>
          <span className="date-time-span">${nonMembersPrice.toFixed(2)}</span>
        </div>
      </div>}
      <div className="description-row">
        <h5 className="title">Description</h5>
        <span>{description}</span>
      </div>
    </div>
  );
};

EventAbout.propTypes = {
  event: object
};

export default EventAbout;
