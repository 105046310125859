import { decode } from 'tiff';

const extractFilename = url => {
  const decodedUrl = decodeURIComponent(url); // Decode URL in case it has encoded characters
  const matches = decodedUrl.match(/\/([^\/?#]+)(?=\?|$)/i);
  return matches && matches[1] ? matches[1] : null;
};

const extractMimeType = url => {
  const extension = url
    .split('.')
    .pop()
    .toLowerCase()
    .split(/\#|\?/)[0];
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    case 'tiff':
      return 'image/tiff';
    case 'heic':
      return 'image/heic';
    case 'heif':
      return 'image/heif';
    default:
      return null;
  }
};

const loadImage = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('Image load failed'));
  });
};

export const urlToFile = async url => {
  const filename = extractFilename(url) || 'downloaded-image.jpg';
  const mimeType = extractMimeType(url) || 'image/jpeg';

  // If the image is in HEIC format, convert it to JPEG
  if (mimeType.toLowerCase().includes('heic') || mimeType.toLowerCase().includes('heif')) {
    return convertHeicToJpeg(url);
  }

  const image = await loadImage(url);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set canvas dimensions to match the image
  canvas.width = image.width;
  canvas.height = image.height;

  // Draw the image onto the canvas
  ctx.drawImage(image, 0, 0);

  // Convert canvas to Blob
  const blob = await new Promise(resolve => canvas.toBlob(resolve, mimeType));

  // Convert Blob to File
  return new File([blob], filename, { type: mimeType });
};

export const urlToFileVideo = async url => {
  const filename = extractFilename(url) || 'downloaded-video.mp4';
  const mimeType = extractMimeTypeVideo(url) || 'video/mp4';

  // Fetch the video data as a Blob
  const response = await fetch(url);
  const blob = await response.blob();

  // Convert Blob to File
  return new File([blob], filename, { type: mimeType });
};

const extractMimeTypeVideo = url => {
  const extension = url
    .split('.')
    .pop()
    .split(/\#|\?/)[0];
  switch (extension) {
    case 'mp4':
      return 'video/mp4';
    case 'webm':
      return 'video/webm';
    case 'ogg':
      return 'video/ogg';
    default:
      return '';
  }
};

const convertToRGBA = (imageData, width, height) => {
  // Create a new array with 4 channels (RGBA)
  const rgba = new Uint8ClampedArray(width * height * 4);

  for (let i = 0; i < imageData.length; i++) {
    const value = imageData[i];
    const offset = i * 4;

    // Set RGB channels to the same value (grayscale)
    rgba[offset] = value; // R
    rgba[offset + 1] = value; // G
    rgba[offset + 2] = value; // B
    rgba[offset + 3] = 255; // A (fully opaque)
  }

  return rgba;
};

export const convertTiffToJpeg = async file => {
  try {
    // Read the file as an ArrayBuffer
    const arrayBuffer = await file.arrayBuffer();

    // Convert ArrayBuffer to Buffer
    const buffer = Buffer.from(arrayBuffer);

    // Decode TIFF (returns array of images)
    const images = decode(buffer);

    if (!images || images.length === 0) {
      throw new Error('No images found in TIFF file');
    }

    const image = images[0]; // Use first image from TIFF

    // Convert to RGBA format
    const rgbaData = convertToRGBA(image.data, image.width, image.height);

    // Create canvas
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');

    // Create ImageData with RGBA data
    const imageData = new ImageData(rgbaData, image.width, image.height);

    // Put image data on canvas
    ctx.putImageData(imageData, 0, 0);

    // Convert to JPEG blob instead of data URL
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (blob) {
            resolve(URL.createObjectURL(blob));
          } else {
            reject(new Error('Failed to convert to JPEG'));
          }
        },
        'image/jpeg',
        0.9
      );
    });
  } catch (err) {
    console.error('TIFF Conversion error:', err);
    throw err; // Re-throw the error to handle it in the calling code
  }
};
