import React from 'react';
import { string, shape, bool } from 'prop-types';
import cn from 'classnames';

import { eventShape } from 'constants/propTypesShapes';

import EventTag from 'components/common/Brand/EventTag';

const EventInfo = ({ event, className, hideDescription }) => {
  const { title, webCoverImageUrl } = event;

  return (
    <div className={cn('serie-info', className)}>
      <EventTag />
      <img src={webCoverImageUrl} alt="series" className="serie-logo" />
      {!hideDescription && <h4 className="semibold serie-description">{title}</h4>}
    </div>
  );
};

EventInfo.propTypes = {
  event: shape(eventShape).isRequired,
  className: string,
  hideDescription: bool
};

export default EventInfo;
