import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import PropTypes from 'prop-types';
import CustomModal from './CustomModal';

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop, mimeType = 'image/jpeg') => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );
  
  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob);
    }, mimeType, 0.9);
  });
};

const ImageCropperModal = ({ 
  visible, 
  imageUrl, 
  onCancel, 
  onCrop, 
  aspectRatio,
  mimeType = 'image/jpeg'
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const blobToFile = (blob, fileName) => {
    return new File([blob], fileName, { type: blob.type });
  };

  const handleCrop = useCallback(async () => {
    try {
      const croppedImageBlob = await getCroppedImg(imageUrl, croppedAreaPixels, mimeType);
      const fileExtension = mimeType.split('/')[1];
      const fileName = `cropped-image-${Date.now()}.${fileExtension}`;
      
      const croppedImageFile = blobToFile(croppedImageBlob, fileName);
      onCrop(croppedImageFile);
      onCancel();
    } catch (e) {
      console.error('Error cropping image:', e);
    }
  }, [imageUrl, croppedAreaPixels, onCrop, onCancel, mimeType]);

  return (
    <CustomModal visible={visible} onCancel={onCancel} onSave={handleCrop}>
      <div style={{ height: 300, position: 'relative' }}>
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <p>Zoom</p>
        <input
          type="range"
          min={1}
          max={3}
          step={0.1}
          value={zoom}
          onChange={e => setZoom(Number(e.target.value))}
        />
      </div>
    </CustomModal>
  );
};

ImageCropperModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
  mimeType: PropTypes.string
};

export default ImageCropperModal;