import React, { useEffect } from 'react';
import { func, shape } from 'prop-types';
// import { useParams } from 'react-router-dom';

import Button from 'components/common/Button/Button';
import Header from 'components/header/Header/Header';
import EventInfo from 'components/series/SerieInfo/EventInfo';
import UnlockEventButton from 'components/unlock/UnlockEventButton';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import { routesPaths } from 'constants/routesPaths';
import { PURCHASE_TYPES } from 'constants/constants';
import { eventShape, unlockContentConfigShape, celebrityInfoShape } from 'constants/propTypesShapes';

import useEvent from 'hooks/useEvent';
import { useIsMobile, useNavigation } from 'hooks';
import useMembership from 'hooks/celebrities/useMembership';

import { renderImage, routeWithProps } from 'utils/helpers';

const UnlockEventLanding = ({ event, config, celebrity, onSuccess }) => {
  const isMobile = useIsMobile();
  const { goToWithSearch, goTo } = useNavigation();
  const { setEvent, setFlowConfig } = useEvent();
  const { getMembership } = useMembership();
  const { webCoverImageUrl } = event;
  const {  username } = celebrity;

  useEffect(() => {
    const CURRENT_EPISODE = event;
    if (CURRENT_EPISODE) setEvent(CURRENT_EPISODE);
    if (config) setFlowConfig(config);
      getMembership(celebrity.id);
  }, []);

  return (
    <>
      <Header />
      <div className="unlock-episode">
        <BackgroundOpacity background={renderImage(webCoverImageUrl, webCoverImageUrl)} opacity={0.5} />
        <div className="unlock-episode-gradient" />
        <div className="unlock-episode-content">
          <div className="left-content content-item">
            {config.purchaseType === PURCHASE_TYPES.EVENT && (
              <>
                <h2 className="bold episode-title">
                  {config.title} for ${config.price}
                </h2>
                <h2 className="padding-top-episode-name">{config.episodeName}</h2>
              </>
            )}
            <div className="button-container">
              <UnlockEventButton
                celebrity={celebrity}
                title={config.buttonLabel}
                size={isMobile ? 'large' : 'small'}
                onSuccess={() =>  
                  onSuccess?.()
              }
              />
              <Button
                labelId="earlyRelease.watchAnotherEvent"
                type="secondary"
                size={isMobile ? 'large' : 'small'}
                onClick={() =>
                  goToWithSearch(
                    routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() }), `?tab=3`
                  )
                }
              />
            </div>
          </div>
          <EventInfo event={event} hideDescription className="content-item" />
        </div>
      </div>
    </>
  );
};

UnlockEventLanding.propTypes = {
  event: shape(eventShape).isRequired,
  config: shape(unlockContentConfigShape).isRequired,
  celebrity: shape(celebrityInfoShape),
  onSuccess: func
};

export default UnlockEventLanding;
