/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/media-has-caption */
import { LOADING, SUCCESS, ERROR, useStatus } from '@rootstrap/redux-tools';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { LogViewer, useLogger } from 'components/common/LogViewer';
import {
  useAnalytics,
  useForm,
  // useModal,
  useNavigation,
  useTextInputProps,
  useToast,
  useValidation
} from 'hooks';
import { createNarrativeValidation } from 'utils/constraints';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Check from 'assets/icons/Check/Check.png';
import useUploadingNarrative from 'hooks/narrative/useUploadingNarrative';
import { createNarrative, processNarrative } from 'state/actions/narrativeActions';
import { useDispatch } from 'react-redux';
import { some } from 'lodash';
import categoriesService from 'services/categoriesService';
import parseError from 'utils/parseError';
import UserService from 'services/userService';
import { routeWithProps, validateVideo } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
// import Modal from 'components/common/Modal/Modal';
import { Line } from 'rc-progress';
import NarrativeService from 'services/narrativeService';
import useUpdateNarrative from 'hooks/narrative/useUpdateNarrative';
import { MIXPANEL_EVENTS, contentOptions, videoUploadsType } from 'constants/constants';
import axios from 'axios';
import CustomCategory from 'components/CustomCategory/CustomCategory';
// import PublishModal from './PublishModal';
import Select from 'react-select';
import cn from 'classnames';
import { ValidationErrors } from 'utils/validationErrors';
import { customDropDownStyles } from 'styles/customDropDownStyles';

const messages = defineMessages({
  description: { id: 'form.description' },
  descriptionPlaceholder: { id: 'form.descriptionPlaceholder' },
  title: { id: 'form.title' },
  titlePlaceholder: { id: 'form.titlePlaceholder' },
  postProduce: { id: 'form.narrative' },
  uploadPostProduce: { id: 'form.uploadPostProduce' },
  categories: { id: 'form.categories' },
  addBtn: { id: 'form.add' },
  shareTo: { id: 'form.shareTo' },
  narratives: { id: 'form.narratives' }
});

const fields = {
  title: 'title',
  description: 'description',
  narrative: 'narrative',
  category_ids: 'category_ids',
  leadCast: 'leadCast',
  credits: 'credits',
  contentViewerCategory: 'contentViewerCategory'
};

const AddNarrativeForm = ({ id, celId }) => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  // const { toggle, isShowing } = useModal();
  const { goToWithSearch } = useNavigation();
  const dispatch = useDispatch();
  const narrativeRef = useRef(null);
  const [narrative, setNarrative] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState();
  const celebrityId = useRef();
  const username = useRef();
  const fullName = useRef();
  const [selectedContent, setSelectedContent] = useState([]);
  const validator = useValidation(createNarrativeValidation);
  const { loading, uploadingNarrative } = useUploadingNarrative();
  const { error, status } = useStatus(createNarrative);
  const [processLoading, setProcessLoading] = useState(false);
  const [postProduceValue, setPostProductValue] = useState(0);
  const [narrativeData, setNarrativeData] = useState();
  const { showErrorToast } = useToast();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { updateNarrative, isLoading } = useUpdateNarrative();
  const [subscribedFans, setSubScribedFans] = useState(true);
  const [uploadStage, setUploadStage] = useState(''); // 'validating', 'validated', 'uploading', 'processing', 'completed'

  // Initialize logger hook
  const { logs, showLogs, setShowLogs, logInfo, logWarning, logError, logApiError } = useLogger();

  const handlePublish = value => {
    value ? setPostProductValue(1) : setPostProductValue(2);
    logInfo('Tracking publish event', {
      value,
      name: fullName.current,
      event: value ? MIXPANEL_EVENTS.uploadNarrativePostProduce : MIXPANEL_EVENTS.uploadNarrative
    });
    trackEvent(
      value ? MIXPANEL_EVENTS.uploadNarrativePostProduce : MIXPANEL_EVENTS.uploadNarrative,
      {
        name: fullName.current
      }
    );
  };
  const onSubmit = useCallback(
    narrative => {
      logInfo('Starting narrative submission', { hasId: !!id });
      setNarrativeData(narrative);
      if (id) {
        logInfo('Updating existing narrative', { id });
        const categoryIds = selectedCategories.map(({ value }) => value);
        narrative.category_ids = categoryIds;
        narrative.contentViewerCategory = selectedContent.value;
        updateNarrative(narrative);
      } else {
        // toggle();
        handlePublish();
      }
    },
    [dispatch, selectedCategories, postProduceValue, id, logInfo]
  );

  useEffect(() => {
    if (postProduceValue !== 0) {
      logInfo('Processing narrative with post-produce value', {
        postProduceValue,
        categoryIds: selectedCategories.map(({ value }) => value)
      });

      const categoryIds = selectedCategories.map(({ value }) => value);
      narrativeData.postProduce = postProduceValue === 1;
      narrativeData.category_ids = categoryIds;
      narrativeData.mime_type = narrative.type;
      narrativeData.notify_subscribers = subscribedFans;

      logInfo('Dispatching createNarrative action', {
        celebrityId,
        narrativeType: narrative.type,
        subscribedFans
      });

      dispatch(createNarrative(celebrityId, narrativeData));
    }
  }, [postProduceValue]);
  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {}
    },
    [onSubmit]
  );
  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);
  const handleNarrativeUpload = () => {
    narrativeRef.current.click();
  };

  const resetNarrativeInput = () => {
    if (narrativeRef.current) {
      narrativeRef.current.value = '';
      logInfo('Reset narrative input field');
    }
  };
  const handleNarrativeChange = async e => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      logInfo('Starting narrative validation', {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type
      });

      setUploadStage('validating');
      try {
        const validationResult = await validateVideo(file, videoUploadsType.Shorts);
        logInfo('Validation result received', validationResult);

        const { isValid, isResolutionValid, isAspectRatioValid, error } = validationResult;

        if (error) {
          logError('Video validation error', { error });
          showErrorToast(ValidationErrors.loadingVideo);
          resetNarrativeInput();
          setUploadStage('');
        } else if (!isResolutionValid) {
          logError('Resolution validation failed', { file });
          showErrorToast(ValidationErrors.resolutionValidation);
          resetNarrativeInput();
          setUploadStage('');
        } else if (!isAspectRatioValid) {
          logError('Aspect ratio validation failed', { file });
          showErrorToast(ValidationErrors.aspectRatioValidationForShorts);
          resetNarrativeInput();
          setUploadStage('');
        } else if (isValid) {
          logInfo('Validation successful', { fileName: file.name });
          setNarrative(file);
          handleValueChange(fields.narrative, file);
          setUploadStage('validated');
        }
      } catch (error) {
        logError('Validation process error', { error: error.message });
        showErrorToast(`Validation error: ${error.message}`);
        resetNarrativeInput();
        setUploadStage('');
      }
    } else {
      logError('Invalid file type', {
        fileName: file?.name,
        fileType: file?.type
      });
      showErrorToast(ValidationErrors.validFile);
      resetNarrativeInput();
      setUploadStage('');
    }
  };

  const handleCategoryChange = selectedOptions => {
    setSelectedCategories(selectedOptions);
    handleValueChange(fields.category_ids, selectedOptions);
  };

  const handleContentChange = e => {
    setSelectedContent(e);
    handleValueChange(fields.contentViewerCategory, e.value);
  };

  const getCategories = async () => {
    try {
      logInfo('Fetching categories');
      const { data } = await categoriesService.getCategories();
      const transformedData = data.categories.map(item => ({
        value: item.id,
        label: item.name
      }));
      logInfo('Categories fetched successfully', { count: transformedData.length });
      setCategories(transformedData);
    } catch (error) {
      logApiError('Error fetching categories', error);
      throw parseError(error.response);
    }
  };

  const getProfile = async () => {
    try {
      logInfo('Fetching user profile');
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
      fullName.current = data.user.fullName;
      celebrityId.current = data.user.id;
      logInfo('Profile fetched successfully', {
        username: data.user.username,
        userId: data.user.id
      });
    } catch (error) {
      logApiError('Error fetching profile', error);
      throw parseError(error.response);
    }
  };

  useEffect(() => {
    getCategories();
    getProfile();
  }, []);

  const onCompleteVideoUpload = () => {
    logInfo('Narrative upload completed, starting processing', {
      celebrityId: celebrityId.current,
      narrativeId: uploadingNarrative.id
    });
    dispatch(processNarrative(celebrityId.current, uploadingNarrative.id, narrative));
  };

  const { status: processNarrativeStatus, error: processNarrativeError } = useStatus(
    processNarrative
  );

  useEffect(() => {
    const processNarrativeAsync = async () => {
      if (processNarrativeStatus === SUCCESS) {
        logInfo('Narrative processed successfully');
        await dispatch(processNarrative.reset());
        setProcessLoading(false);
        setUploadStage('completed');
        const route = routeWithProps(routesPaths.celebrityId, {
          username: username.current.toLowerCase()
        });
        goToWithSearch(route, `?tab=2`);
      } else if (processNarrativeStatus === ERROR) {
        logError('Error processing narrative', { error: processNarrativeError });
      }
    };
    processNarrativeAsync();
  }, [dispatch, celebrityId, processNarrativeStatus, processNarrativeError, goToWithSearch]);

  const uploadFile = async url => {
    try {
      logInfo('Starting narrative file upload', {
        url,
        fileSize: narrative.size,
        fileType: narrative.type,
        fileName: typeof narrative === 'string' ? 'existing-narrative' : narrative.name
      });

      const totalSize = narrative.size;
      setUploadStage('uploading');

      await axios.put(url, narrative, {
        headers: {
          'Content-Type': narrative.type
        },
        onUploadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            const uploadedSize = progressEvent.loaded;
            const progress = Math.round((uploadedSize / totalSize) * 100);
            setUploadProgress(progress);

            // Log progress at 25%, 50%, 75% and 100% to avoid too many logs
            if (progress === 25 || progress === 50 || progress === 75 || progress === 100) {
              logInfo(`Upload progress: ${progress}%`, {
                uploaded: uploadedSize,
                total: totalSize
              });
            }
          }
        }
      });

      logInfo('Upload completed successfully');
      setProcessLoading(false);
      setUploadProgress(100);
      setUploadStage('processing');
      onCompleteVideoUpload && onCompleteVideoUpload();
    } catch (error) {
      logApiError('Upload failed', error);
      showErrorToast(`Upload failed: ${error.message || 'Something went wrong'}`);
      setProcessLoading(false);
      setUploadProgress(0);
      setUploadStage('');
    }
  };
  useEffect(() => {
    if (status === SUCCESS && uploadingNarrative?.uploadUrl) {
      logInfo('Received upload URL from API', { uploadUrl: uploadingNarrative.uploadUrl });
      dispatch(createNarrative.reset());
      setProcessLoading(true);
      setUploadStage('validated');
      uploadFile(uploadingNarrative?.uploadUrl);
    } else if (status === ERROR) {
      logError('Error creating narrative', { error });
    }
  }, [dispatch, status, error, uploadingNarrative?.uploadUrl]);

  const getNarrativeData = async () => {
    try {
      logInfo('Fetching narrative data', { id, celId });
      const { data } = await NarrativeService.getNarrativeById(id, celId);
      logInfo('Narrative data received', { narrative: data.narrative });
      setNarrativeData(data.narrative);
      if (data.narrative.link) {
        setNarrative(data.narrative.link);
      }
    } catch (error) {
      logApiError('Error fetching narrative data', error);
      throw parseError(error.response);
    }
  };

  useEffect(() => {
    if (categories && narrativeData && id) {
      const selectedCats = categories?.filter(item =>
        narrativeData.categoryIds.includes(item.value)
      );
      const selectedContent = contentOptions.filter(
        item => item.value === narrativeData.contentViewerCategory
      );
      setSelectedCategories(selectedCats);
      handleValueChange(fields.category_ids, selectedCats);
      setSelectedContent(selectedContent[0]);
      setValues({
        ...narrativeData,
        [fields.category_ids]: selectedCats
      });
    }
  }, [categories, narrativeData]);

  useEffect(() => {
    if (id) {
      getNarrativeData();
    }
  }, [id]);

  const hasErrors = some(errors);
  const isFormIncomplete =
    !values[fields.title] ||
    !values[fields.description] ||
    !values[fields.category_ids] ||
    !values[fields.contentViewerCategory];
  const isLoadingState = loading || processLoading || isLoading;
  const isNarrativeMissing = !narrative;

  const isDisabled = hasErrors || isFormIncomplete || isNarrativeMissing || isLoadingState;

  return (
    <div className="creator-form">
      <div className="creator-form-fields">
        <Input
          name="title"
          placeholder={intl.formatMessage(messages.titlePlaceholder)}
          label={intl.formatMessage(messages.title)}
          errors={errors[fields.title]}
          {...inputProps(fields.title)}
        />
        <Input
          name="description"
          placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
          label={intl.formatMessage(messages.description)}
          errors={errors[fields.description]}
          {...inputProps(fields.description)}
        />
        <Input
          name="lead_cast"
          placeholder="Lead cast name"
          label="Lead Cast (optional)"
          errors={errors[fields.leadCast]}
          {...inputProps(fields.leadCast)}
        />
        <Input
          name="credits"
          placeholder="Credits for Shorts"
          label="Credit For Shorts (optional)"
          errors={errors[fields.credits]}
          {...inputProps(fields.credits)}
        />
        <div className={cn('custom-short-content')}>
          <span className="custom-short-content-label">Content Viewers</span>
          <div className="single-select-short">
            <Select
              value={selectedContent}
              styles={customDropDownStyles}
              options={contentOptions}
              isSearchable={false}
              onChange={handleContentChange}
            />
          </div>
        </div>
        <input
          type="file"
          ref={narrativeRef}
          style={{ display: 'none' }}
          accept=".mp4,.mov"
          name="narrative"
          onChange={handleNarrativeChange}
        />
        <div>
          <span className="custom-label">{intl.formatMessage(messages.postProduce)}</span>
          {narrative ? (
            <div>
              <video className="video-preview" preload="metadata" playsInline autoPlay>
                <source
                  src={typeof narrative === 'string' ? narrative : URL.createObjectURL(narrative)}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <div className="button-margin">
              <Button
                labelId={intl.formatMessage(messages.uploadPostProduce)}
                type="secondary"
                onClick={handleNarrativeUpload}
                className="profile-button"
              />
              <span className="label-margin">Recommended dimension: 720px X 1280px (9:16)</span>
            </div>
          )}
        </div>
        {/* <div className="categories">
          <span className="custom-label">{intl.formatMessage(messages.categories)}</span>
          <div className="custom-select">
            <Select
              closeMenuOnSelect={false}
              isMulti
              value={selectedCategories}
              options={categories}
              onChange={handleCategoryChange}
            />
          </div>
        </div> */}
        <CustomCategory value={selectedCategories} handleCategoryChange={handleCategoryChange} />
        {!id && (
          <div className={cn('notify-box')}>
            <input
              type="checkbox"
              checked={subscribedFans}
              onChange={() => setSubScribedFans(!subscribedFans)}
            />
            Notify all paid fans
          </div>
        )}
        <div className="share-container-short">
          <span className="custom-label">{intl.formatMessage(messages.shareTo)}</span>
          <div className="narrative-tag">
            <span>{intl.formatMessage(messages.narratives)}</span>
            <img src={Check} alt="check" height="10px" />
          </div>
        </div>
      </div>
      <div className="creator-form-error p1">{error}</div>
      {/* Error logs auto-displayed */}
      <LogViewer
        logs={logs}
        showLogs={showLogs}
        setShowLogs={setShowLogs}
        title="Narrative Upload Errors"
      />
      {uploadProgress > 0 && (
        <div
          style={{
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>
            {uploadStage === 'validating' && 'Checking file validations...'}
            {uploadStage === 'validated' && 'Validation passed, preparing upload...'}
            {uploadStage === 'uploading' && 'Uploading shorts...'}
            {uploadStage === 'processing' && 'Processing shorts...'}
            {uploadStage === 'completed' && 'Upload completed successfully!'}
          </div>
          <div style={{ width: '100%', padding: '0 1.4rem', marginTop: '10px' }}>
            <Line percent={uploadProgress} strokeWidth={2} strokeColor="#2400FC" />
          </div>
          <div
            style={{
              textAlign: 'center',
              marginTop: '5px',
              marginBottom: '5px',
              color: 'white'
            }}
          >
            {uploadProgress}% Complete
          </div>
        </div>
      )}
      {(status === LOADING || loading || processLoading || isLoading) && (
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
          <Loading type="ball-clip-rotate" />
        </div>
      )}
      <Button
        labelId={id ? 'Update' : 'form.narrative.submit'}
        className="submit-button"
        onClick={handleSubmit}
        disabled={isDisabled}
      ></Button>
      {/* <Modal hide={toggle} isShowing={isShowing} className="original-modal">
        <PublishModal handlePublish={handlePublish} />
      </Modal> */}
    </div>
  );
};

export default memo(AddNarrativeForm);
