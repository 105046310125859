import React from 'react';
import { bool, string } from 'prop-types';
import LiveIcon from 'assets/live-icon.png';
import { VIDEO_SHORT_DESCRIPTION_LENGTH } from 'constants/constants';
import MoreText from 'components/common/MoreText/MoreText';
import { convertUtcToLocal } from 'utils/helpers';

const EventCardInfo = ({
  title,
  isHome,
  creatorName,
  eventStartTimeInUtc,
  subscribedToEvent,
  duration,
  eventDate,
  membersPrice,
  nonMembersPrice,
  isAuthenticated,
  status,
  isMember
}) => (
  <div className="event-card-info">
    <div className="title-row">
      <div style={{ display: 'inline-flex', gap: '10px', alignItems: 'center' }}>
        <h5
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '200px'
          }}
          className="bold"
        >
          {title}
        </h5>
        {status === 'ongoing' && (
          <div className="live-stream-tag">
            <img height="20px" width="25px" src={LiveIcon} alt="live-tag" />
            <span className="live-span">Live</span>
          </div>
        )}
      </div>
      {(membersPrice !== 0 || nonMembersPrice !== 0) && !subscribedToEvent && (
        <h5 className="bold">${isMember ? membersPrice.toFixed(2) : nonMembersPrice}</h5>
      )}
    </div>
    {isHome && (
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '300px'
        }}
      >
        {creatorName}
      </span>
    )}
    <div className="date-row">
      <div className="p1">
        <MoreText
          text={convertUtcToLocal(eventStartTimeInUtc)}
          limitLength={VIDEO_SHORT_DESCRIPTION_LENGTH}
        />
      </div>
      <div>{duration}</div>
    </div>
  </div>
);

EventCardInfo.propTypes = {
  title: string.isRequired,
  creatorName: string.isRequired,
  eventStartTimeInUtc: string.isRequired,
  duration: string.isRequired,
  membersPrice: string.isRequired,
  nonMembersPrice: string.isRequired,
  subscribedToEvent: bool,
  isAuthenticated: bool,
  isHome: bool,
  status: string,
  isMember: bool
};

export default EventCardInfo;
