export const customDropDownStyles = {
  input: (provided, state) => ({
    ...provided,
    color: '#fff',
    margin: '0 !important',
    padding: '0',
    background: 'transparent',
    cursor: state.isDisabled ? 'not-allowed' : 'text', 
    caretColor: state.isDisabled ? 'transparent' : 'auto',
    '& input': {
      margin: '0 !important',
      boxSizing: 'border-box',
      boxShadow: 'none !important',
      textShadow: 'none !important',
      textTransform: 'none !important',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isDisabled ? '#202020' : 'black', 
    color: state.isDisabled ? '#4D4D4D' : 'white', 
    minHeight: '55px',
    borderColor: '#5C5B5B',
    fontFamily: 'Raleway',
    boxShadow: 'none !important',
    '&:hover': {
      borderColor: '#5C5B5B'
    },
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    '& .css-tlfecz-indicatorContainer': {
      color: state.isDisabled ? '#4D4D4D' : 'white',
    }
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isDisabled ? '#4D4D4D' : 'white', 
  }),
  menu: baseStyles => ({
    ...baseStyles,
    backgroundColor: 'white',
    fontFamily: 'Raleway'
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? '#D3DFF1' : 'white',
    color: 'black'
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    height: '56px'
  }),
};
