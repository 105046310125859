import React, { memo } from 'react';
import { string, func, node } from 'prop-types';

const VideoThumbnail = ({ thumbnail, className, onClick, children, type }) => (
  <div className={className} onClick={onClick}>
    <img src={thumbnail} alt={type || 'shorts'} />
    {children}
  </div>
);

VideoThumbnail.propTypes = {
  thumbnail: string.isRequired,
  className: string,
  onClick: func.isRequired,
  children: node,
  type: string
};

export default memo(VideoThumbnail);
