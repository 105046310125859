/* eslint-disable camelcase */
/* eslint-disable no-useless-catch */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import { useAnalytics, useNavigation, useToast } from 'hooks';
import { useLogger } from 'components/common/LogViewer';
import capitalize from 'lodash/capitalize';
import { addSeries, getTrailerUrlForJWPlayer } from 'state/actions/seriesActions';
import { parseHashtags, routeWithProps } from 'utils/helpers';
import { clearEpisodes } from 'state/actions/episodesActions';
import { routesPaths } from 'constants/routesPaths';
import parseError from 'utils/parseError';
import UserService from 'services/userService';
import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import axios from 'axios';

export default () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { goTo } = useNavigation();
  const username = useRef();
  const [loading, setLoading] = useState(false);
  const { showErrorToast, showToast } = useToast();
  const [series, setSeries] = useState({});
  const { status, error } = useStatus(addSeries);
  const { trailerData } = useSelector(({ seriesUpload: { trailerData } }) => ({ trailerData }));
  const { episodes } = useSelector(({ episodes: { episodes } }) => ({ episodes }));
  const [completedVideos, setCompletedVideos] = useState(0);
  const [uploadedVideos, setUploadedVideos] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStage, setUploadStage] = useState(''); // 'validating', 'validated', 'uploading', 'processing', 'completed'
  const totalVideoRef = useRef();
  const videoDoneRef = useRef();
  const totalVideos = episodes.length + 1;
  const { status: getTrailerUrlStatus, error: getTrailerUrlError } = useStatus(
    getTrailerUrlForJWPlayer
  );

  // Initialize logger
  const {
    logs,
    showLogs,
    setShowLogs,
    logInfo,
    logWarning,
    logError,
    logApiError,
    clearLogs
  } = useLogger();

  const addNewSeries = async () => {
    // Ensure we have a valid series object with necessary properties
    if (!series || !series.title) {
      logError('Invalid series data', { series });
      setLoading(false);
      return;
    }

    logInfo('Preparing to add new series', { totalEpisodes: episodes.length });
    const { categoryIds = [], description, hashtags = [], title, trailer } = series;
    const data = {
      description,
      title,
      banner_media_id: trailerData?.mediaId,
      mime_type: trailer?.type
    };

    // Ensure episodes exist and is an array
    if (!episodes || !Array.isArray(episodes)) {
      logError('No episodes found or episodes is not an array', {
        hasEpisodes: !!episodes,
        isArray: Array.isArray(episodes)
      });
      showErrorToast('Error: No episodes available. Please add at least one episode.');
      setLoading(false);
      return;
    }

    logInfo('Building episodes list');
    const episodesList = episodes.map(
      (
        {
          creditStartTime,
          description,
          earlyReleaseDateTime,
          exclusive,
          mediaId,
          membersPrice,
          nonMembersPrice,
          releaseDateTime,
          title,
          seriesVideo,
          content_viewer_category,
          lead_cast,
          credits,
          notify_subscribers
        },
        index
      ) => ({
        credits_start_time: creditStartTime,
        early_release_date_time: earlyReleaseDateTime,
        media_id: mediaId,
        members_price: membersPrice === 0 ? null : Number(membersPrice).toFixed(2),
        non_members_price: nonMembersPrice === 0 ? null : Number(nonMembersPrice).toFixed(2),
        release_date_time: releaseDateTime,
        position: index,
        mime_type: seriesVideo.type,
        description,
        exclusive,
        title,
        lead_cast,
        credits,
        content_viewer_category,
        notify_subscribers
      })
    );

    logInfo('Creating FormData for series upload', {
      categoryCount: categoryIds?.length || 0,
      hashtagCount: hashtags?.length || 0,
      episodesCount: episodesList?.length || 0
    });

    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(`series[${key}]`, data[key]);
    });

    formData.append('series[logo_image]', series.logo_image);
    formData.append('series[desktop_image]', series.desktop_image);
    formData.append('series[mobile_image]', series.mobile_image);

    formData.append('series[category_ids]', JSON.stringify(categoryIds || []));
    formData.append('series[hashtags]', JSON.stringify(hashtags || []));
    formData.append('series[episodes]', JSON.stringify(episodesList || []));

    formData.append('series[lead_cast]', series.lead_cast || '');
    formData.append('series[content_viewer_category]', series.content_viewer_category || '');
    formData.append('series[notify_subscribers]', series.notify_subscribers || false);
    formData.append('series[credits]', series.credits || '');
    // formData.append('series[season]', series.season);

    logInfo('Dispatching addSeries action');
    await dispatch(addSeries(formData));
  };

  useEffect(() => {
    if (getTrailerUrlStatus === SUCCESS) {
      logInfo('Successfully obtained trailer URL', { trailerData });
      addNewSeries();
      return;
    }

    if (getTrailerUrlStatus === ERROR) {
      logError('Error getting trailer URL', { error: getTrailerUrlError });
      showErrorToast(getTrailerUrlError);
      dispatch(getTrailerUrlForJWPlayer.reset());
      setLoading(false);
    }
  }, [getTrailerUrlStatus]);

  // Validation function to be called before upload
  const validateBeforeUpload = async data => {
    logInfo('Validating series data before upload', {
      hasTrailer: !!data.trailer,
      hasLogo: !!data.logo_image,
      hasDesktopImage: !!data.desktop_image,
      hasMobileImage: !!data.mobile_image,
      categoryCount: data.categoryIds?.length
    });

    setUploadStage('validating');

    // Perform validation checks
    if (!data.trailer || !data.logo_image || !data.desktop_image || !data.mobile_image) {
      logError('Missing required media files', {
        hasTrailer: !!data.trailer,
        hasLogo: !!data.logo_image,
        hasDesktopImage: !!data.desktop_image,
        hasMobileImage: !!data.mobile_image
      });
      showErrorToast('Please upload all required media files');
      setUploadStage('');
      return false;
    }

    // Improved category validation with more detailed logging
    if (!data.categoryIds || !Array.isArray(data.categoryIds) || data.categoryIds.length === 0) {
      logError('No categories selected', {
        categoryIds: data.categoryIds,
        type: typeof data.categoryIds,
        isArray: Array.isArray(data.categoryIds),
        length: Array.isArray(data.categoryIds) ? data.categoryIds.length : null
      });
      showErrorToast('Please select at least one category');
      setUploadStage('');
      return false;
    }

    logInfo('Validation passed');
    setUploadStage('validated');
    return true;
  };

  const onSubmit = useCallback(
    async data => {
      logInfo('Starting series submission', {
        title: data.title,
        hasCategoryIds: !!data.categoryIds,
        categoryIdsType: typeof data.categoryIds
      });

      // Set loading state immediately
      setLoading(true);

      // Safely map the values and filter out any undefined items
      const categoryIds = (data.categoryIds || [])
        .filter(item => item && typeof item === 'object' && 'value' in item)
        .map(item => item.value);

      logInfo('Processed category IDs', {
        categoryCount: categoryIds.length,
        categoryIds
      });

      data.hashtags = parseHashtags(data.description);
      data.categoryIds = categoryIds;

      const isValid = await validateBeforeUpload(data);
      if (!isValid) {
        setLoading(false);
        return;
      }

      logInfo('Setting series data and getting trailer URL');
      setSeries(data);

      // Set upload stage to start showing progress indicators
      setUploadStage('validating');

      // Dispatch action to get trailer URL
      dispatch(getTrailerUrlForJWPlayer(data.trailer));
    },
    [dispatch, logInfo, showErrorToast]
  );

  const resetDispatchData = () => {
    logInfo('Resetting dispatch data');
    dispatch(clearEpisodes());
    dispatch(addSeries.reset());
    dispatch(getTrailerUrlForJWPlayer.reset());
  };

  const onCompleteVideoUpload = () => {
    logInfo('Video upload process completed');
    resetDispatchData();
    showToast('Publication successfully uploaded!');
    setLoading(false);

    logInfo('Navigating to celebrity profile');
    goTo(
      routeWithProps(routesPaths.celebrityId, {
        username: username.current.toLowerCase()
      })
    );
  };

  const getProfile = async () => {
    try {
      logInfo('Fetching user profile');
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
      logInfo('Profile fetched successfully', { username: data.user.username });
    } catch (error) {
      logApiError('Error fetching profile', error);
      throw parseError(error.response);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (completedVideos === totalVideos) {
      logInfo('All videos completed upload', {
        completedVideos,
        totalVideos
      });
      onCompleteVideoUpload();
      videoDoneRef.current = 2;
    }
  }, [completedVideos]);

  const uploadFile = async (url, file) => {
    try {
      logInfo('Starting file upload', {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        url
      });

      const totalSize = file.size;
      setUploadStage('uploading');

      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            const uploadedSize = progressEvent.loaded;
            const progress = Math.round((uploadedSize / totalSize) * 100);
            setUploadProgress(progress);

            // Log progress at meaningful intervals
            if (progress % 25 === 0) {
              logInfo(`Upload progress: ${progress}%`, {
                uploaded: uploadedSize,
                total: totalSize,
                fileName: file.name
              });
            }
          }
        }
      });

      logInfo('File upload completed successfully', { fileName: file.name });
      setUploadStage('processing');
      return true;
    } catch (error) {
      logApiError('Error uploading file', error);
      setUploadStage('');
      throw error;
    }
  };

  const uploadVideosInJWPlayer = async videos => {
    try {
      logInfo('Starting batch upload to JWPlayer', { videoCount: videos.length });
      videoDoneRef.current = 1;
      setUploadProgress(0);
      setUploadedVideos(0);
      totalVideoRef.current = videos.length;
      setUploadStage('validated');

      for (let index = 0; index < videos.length; index++) {
        const video = videos[index];
        logInfo(`Uploading video ${index + 1} of ${videos.length}`, {
          fileName: video.video.name,
          mediaId: video.mediaId
        });

        await uploadFile(video.uploadUrl, video.video);

        setUploadedVideos(prevCount => prevCount + 1);
        setCompletedVideos(prev => prev + 1);
        setUploadProgress(0);

        logInfo(`Completed video ${index + 1} of ${videos.length}`);
      }

      logInfo('All videos uploaded successfully');
      setUploadStage('completed');
    } catch (error) {
      logApiError('Error in batch upload process', error);
      showErrorToast('Something went wrong, try again please!');
      setUploadStage('');
    }
  };

  useEffect(() => {
    if (status === SUCCESS) {
      logInfo('Series created successfully, preparing to upload videos');
      setLoading(true);
      trackEvent(MIXPANEL_EVENTS.uploadSeries, {
        [MIXPANEL_PARAMS.seriesName]: series.title
      });

      const videosToUpload = [
        {
          video: series.trailer,
          mediaId: trailerData?.mediaId,
          uploadUrl: trailerData?.uploadUrl
        }
      ];

      episodes.forEach(({ mediaId, uploadUrl, seriesVideo }) => {
        videosToUpload.push({
          video: seriesVideo,
          mediaId,
          uploadUrl
        });
      });

      logInfo('Starting upload process', {
        videoCount: videosToUpload.length,
        trailerSize: series.trailer.size,
        hasSeries: videosToUpload.length > 1
      });

      uploadVideosInJWPlayer(videosToUpload);
    } else if (status === ERROR) {
      logError('Error creating series', { error });
      showErrorToast(error);
    }
  }, [status, dispatch]);

  return {
    onSubmit,
    status,
    loading,
    uploadProgress,
    uploadedVideos,
    error: capitalize(error),
    totalVideos: totalVideoRef.current,
    uploadingStatus: videoDoneRef.current,
    uploadStage,
    logs,
    showLogs,
    setShowLogs
  };
};
