import React from 'react';
import { useIntl } from 'react-intl';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import notFoundBackground from 'assets/not-found-bkg.png';

const NoInternetPage = () => {
  const intl = useIntl();


  return (
    <div   style={{
      height: '100vh',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 1000,
    }} className="not-found">
      <BackgroundOpacity background={notFoundBackground} opacity={0.5} />
      <div className="not-found-gradient" />
      <div className="not-found-content">
        <div className="section-title">{intl.formatMessage({ id: 'notfound.title' })}</div>
        <h1>{intl.formatMessage({ id: 'nointernet.subtitle' })}</h1>
        <h4 className="semibold">{intl.formatMessage({ id: 'nointernet.message1' })}</h4>
      </div>
    </div>
  );
};

export default NoInternetPage;
