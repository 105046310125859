import React, { useState } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';

const EventContext = React.createContext([{}, () => {}]);

const EventContextProvider = ({ children }) => {
  const [event, setEvent] = useState(null);
  const [flowConfig, setFlowConfig] = useState({});

  return (
    <EventContext.Provider value={{ event, setEvent, flowConfig, setFlowConfig }}>
      {children}
    </EventContext.Provider>
  );
};

EventContextProvider.propTypes = {
  children: oneOfType([arrayOf(node), node])
};

export { EventContext, EventContextProvider };
