/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { string, shape, func } from 'prop-types';

import { PlayIcon, OptionsIcon, AdIcon, EditIcon } from 'components/icons';
import PriceCrownIcon from 'components/common/PriceCrownIcon';

import { episodeShape } from 'constants/propTypesShapes';

import { useCelebrity, useSession, useClickOutside, useNavigation } from 'hooks';
import useProfile from 'hooks/users/useProfile';

import { checkSubscription, routeWithProps, toMinutesAndSeconds } from 'utils/helpers';

import { white } from 'styles/common/_constants.scss';
import ClockImg from 'assets/clock.svg';
import CheckSquareImg from 'assets/check-square-broken.svg';
import EyeImg from 'assets/eye.svg';
import { EPISODE_STATES } from 'constants/constants';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';
import { routesPaths } from 'constants/routesPaths';

const EpisodeCard = ({ episode, className, onClick, isLoggedUser, Ads, serieId }) => {
  const { celebrity } = useCelebrity();
  const {
    thumbnailsUrls = [],
    title,
    description,
    durationMinutes,
    exclusive,
    link,
    membersPrice,
    nonMembersPrice,
    playCount,
    totalSeconds,
    fullyWatchedPer,
    stage
  } = episode || {};
  const intl = useIntl();
  const CLOSED = 0;
  const OPENED = 1;
  const {
    profile: { memberships }
  } = useProfile();
  const { goTo } = useNavigation();
  const { user, authenticated } = useSession();
  const isMember = checkSubscription(memberships, celebrity.id);
  const [optionsStatus, setOptionsStatus] = useState(CLOSED);
  const isPublished = stage === EPISODE_STATES.published;
  const statsVisible = authenticated && isPublished && celebrity.id === user.id;

  const isMemberWithPrice = !!membersPrice && isMember && !link;
  const isNonMemberWithPrice = !link && !isMember && !!nonMembersPrice && !exclusive;
  const isExclusiveMember = !link && !isMember && exclusive && !!membersPrice;

  const shouldShowPriceTag = isMemberWithPrice || isNonMemberWithPrice || isExclusiveMember;

  const ref = useClickOutside(() => setOptionsStatus(CLOSED), optionsStatus);

  const handleEditEpisode = () => {
    goTo(
      routeWithProps(routesPaths.editEpisode, {
        episodeId: episode.episodeId,
        seriesId: serieId,
        celebrityId: celebrity.id
      })
    );
  };
  return (
    <div className={cn('episode-card', className)} onClick={link ? undefined : onClick}>
      <div className="episode-card-thumbnail">
        {thumbnailsUrls && (
          <>
            <img
              src={thumbnailsUrls[0]?.src}
              className={cn('episode-card-thumbnail', { grayscale: !link })}
              alt="episode"
            />
          </>
        )}
        <div className="sharing-options" ref={ref}>
          {optionsStatus === OPENED && (
            <>
              {' '}
              {isLoggedUser && (
                <IconWithBackground
                  icon={<EditIcon />}
                  onClick={() => handleEditEpisode()}
                  isSmall
                />
              )}
              {isLoggedUser && <IconWithBackground icon={<AdIcon />} onClick={Ads} isSmall />}
            </>
          )}
          {isLoggedUser && (
            <IconWithBackground
              icon={<OptionsIcon />}
              onClick={() => setOptionsStatus(optionsStatus === OPENED ? CLOSED : OPENED)}
              isSmall
            />
          )}
        </div>
        {statsVisible && (
          <div className="media-overlay">
            <div className="media-stat">
              <img className="media-icons" src={EyeImg} alt="" />
              <span className="media-fonts">{playCount}</span>
            </div>
            <div className="media-stat">
              <img className="media-icons" src={ClockImg} alt="" />
              <span className="media-fonts">{toMinutesAndSeconds(totalSeconds)}</span>
            </div>
            <div className="media-stat">
              <img className="media-icons" src={CheckSquareImg} alt="clock" />
              <span className="media-fonts">{fullyWatchedPer}%</span>
            </div>
          </div>
        )}
        <div className="episode-card-thumbnail-gradient" />
        <div className="opacity" />
        {link && (
          <div className="layer flex-center play-icon" onClick={onClick}>
            <PlayIcon fill color={white} />
          </div>
        )}
        {!link && (
          <div onClick={onClick}>
            <PriceCrownIcon
              price={
                shouldShowPriceTag
                  ? isMember
                    ? membersPrice
                    : nonMembersPrice || membersPrice
                  : null
              }
              membership={!isMember && episode.exclusive}
              className="episode-card-thumbnail-premium"
            />
          </div>
        )}
      </div>
      <div className="episode-card-title">
        <h5 className="bold">{title}</h5>
        <div className="p2 bold">{`${durationMinutes} ${intl.formatMessage({
          id: 'duration.minutes'
        })}`}</div>
      </div>
      <div className="p1">{description}</div>
    </div>
  );
};

EpisodeCard.propTypes = {
  episode: shape(episodeShape).isRequired,
  className: string,
  onClick: func.isRequired,
  Ads: func.isRequired,
  isLoggedUser: shape().isRequired
};

export default EpisodeCard;
