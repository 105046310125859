// App common constants
import React from 'react';

import { FacebookIcon, TwitterIcon, YoutubeIcon, InstagramIcon } from 'components/icons';

import VisaIcon from 'assets/credit-card-icons/visa.png';
import MasterCardIcon from 'assets/credit-card-icons/master.png';
import AmericanExpressIcon from 'assets/credit-card-icons/american-express.png';
import DinersIcon from 'assets/credit-card-icons/diners.png';
import JCBIcon from 'assets/credit-card-icons/jcb.png';
import DiscoverIcon from 'assets/credit-card-icons/discover.png';

export const DEFAULT_PAGE_TITLE = 'Identifi';
export const { CANONICAL_URL } = process.env;
export const SUPPORTED_LANGUAGES = ['en'];
export const DEFAULT_LANGUAGE = 'en';

export const COPYRIGHT_MESSAGE = `© ${new Date().getFullYear()} Identifi Holdings, Inc.`;
export const VIDEO_SHORT_DESCRIPTION_LENGTH = 72;
export const BIO_DESCRIPTION_LENGTH = 82;
export const AVATAR_NAME_LENGTH = 8;

export const RIGHT_ARROW_KEY = 39;
export const LEFT_ARROW_KEY = 37;
export const SPACE_KEY = 32;

export const NARRATIVE_AD_COUNT = 2;

export const MEDIA_INQUIRIES_EMAIL = 'media@weidentifi.com';
export const SUPPORT_EMAIL = 'support@weidentifi.com';

export const JWPLAYER_URL = 'https://cdn.jwplayer.com/libraries/<id>.js';

export const OPEN_SANS_SRC =
  'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap';

export const SHARING_PLATFORMS = {
  TWITTER: { id: 1, sharingUrl: 'https://twitter.com/intent/tweet', name: 'Twitter' },
  FACEBOOK: { id: 2, sharingUrl: 'https://www.facebook.com/sharer/sharer.php', name: 'Facebook' }
};

export const SOCIAL_PLATFORMS = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/IdentifiPresents/',
    icon: <FacebookIcon />
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/teamidentifi',
    icon: <TwitterIcon />
  },
  {
    name: 'Youtube',
    link: 'https://www.youtube.com/channel/UCaaSRuACAmAnlQ5bShA1RwQ',
    icon: <YoutubeIcon />
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/we_identifi/',
    icon: <InstagramIcon />
  }
];

export const CREDIT_CARDS = [
  {
    name: 'Visa',
    icon: VisaIcon
  },
  {
    name: 'MasterCard',
    icon: MasterCardIcon
  },
  {
    name: 'Diners Club',
    icon: DinersIcon
  },
  {
    name: 'Discover',
    icon: DiscoverIcon
  },
  {
    name: 'JCB',
    icon: JCBIcon
  },
  {
    name: 'American Express',
    icon: AmericanExpressIcon
  }
];

export const PERIODS = {
  MONTH: 'monthly'
};

export const MIXPANEL_EVENTS = {
  home: 'Home View',
  creatorID: 'ID View',
  showEpisodes: 'Show Episodes Clicked',
  playFirstEpisode: 'Play First Episode Clicked',
  narrativesListView: 'Narratives List View',
  nextEpisode: 'Next episode clicked',
  homeHighlight: 'Home Highlight Clicked',
  category: 'Category View',
  shareContent: 'Share Content Clicked',
  keepWatching: 'Keep Watching Clicked',
  featuredCreator: 'Featured Creator Clicked',
  seriesDetails: 'Series Details View',
  followClicked: 'Follow Clicked',
  signUp: 'Sign Up View',
  signUpCompleted: 'Sign Up Completed',
  login: 'Login View',
  notFound: '404 Page View',
  privacyPolicy: 'Privacy Policies View',
  terms: 'Terms of Service View',
  liveAvatar: 'Live Avatar Clicked',
  liveView: 'Live Event View',
  membershipView: 'Basic membership View',
  membershipCanceled: 'Membership Canceled',
  subscriptionStarted: 'Subscription Checkout Started',
  subscriptionConfirmed: 'Subscription Checkout Confirmed',
  creditCardAdded: 'Credit card added',
  creditCardRejected: 'Credit card rejected',
  creditCardSelected: 'Credit Card Selected,',
  episodePlayerView: 'Episode Player View',
  narrativePlayerView: 'Narrative Player View',
  videoTimeWatched: 'Video Time Watched',
  loggedIn: 'Logged in',
  addPaymentMethodClicked: 'Add Payment Method Clicked',
  completeSubscriptionClicked: 'Complete Subscription Clicked',
  shopifyClicked: 'Shopify Link Clicked',
  playTrailer: 'Play Live Trailer',
  playScheduledLive: 'Play Scheduled Live Event',
  buyInfoPPVClicked: 'Buy Info PPV Live Clicked',
  buyNowPPVClicked: 'Buy  Now PPV Live Clicked',
  confirmPPVLivePurchase: 'Confirm PPV Live Purchase',
  scheduledLiveEventView: 'Scheduled Live Event View',
  episodeBought: 'Episode Bought',
  podcastBought: 'Podcast Bought',
  eventBought: 'Event Bought',
  // updates
  uploadSeries: 'Upload Movies & Series',
  // become creator
  becomeCreator: 'Become Creator',
  // Narratives
  uploadNarrative: 'Upload Shorts',
  uploadNarrativePostProduce: 'Upload Shorts Post-Produce',
  // Report
  reportNarrative: 'Report Shorts',
  reportContent: 'Report Content',
  reportCelebrity: 'Report Celebrity',
  // Event
  reportEvent: 'Report Event',
  // Podcast
  reportPodcast: 'Report Podcast',
  // Account
  accountDeleted: 'Account Deleted',
  // Ads
  bannerAdClicked: 'Banner Ad Click',
  widgetAdClicked: 'Widget Ad Click',
  bannerAdViewed: 'Banner Ad View',
  widgetAdViewed: 'Widget Ad View'
};

export const MIXPANEL_PARAMS = {
  seriesName: 'Series Name',
  creatorName: 'Creator Name',
  narrativeTitle: 'Narrative Title',
  bannerAd: 'BannerAd',
  widgetAd: 'WidgetAd',
  Narrative: 'Narrative',
  Episode: 'Episode'
};

export const celebrityTabs = {
  ORIGINALS: { id: 1, localeId: 'tab.originals' },
  NARRATIVES: { id: 2, localeId: 'tab.narratives' },
  EVENTS: { id: 3, localeId: 'tab.events' },
  PODCASTS: { id: 4, localeId: 'tab.podcasts' }
};

export const subscriptionTabs = {
  SUBSCRIBERS: { id: 1, localeId: 'tab.subscribers' },
  SUBSCRIPTIONS: { id: 2, localeId: 'tab.subscriptions' },
  AD_MEMBERSHIP: { id: 3, localeId: 'tab.adMembership' },
  EVENTS: { id: 4, localeId: 'tab.events' },
  PODCASTS: { id: 5, localeId: 'tab.podcasts' }
};

export const settingTabs = {
  MANAGE_ACCOUNT: { id: 1, localeId: 'tab.manageAccount' },
  CHANGE_PROFILE_IMAGE: { id: 2, localeId: 'tab.changeProfileImage' },
  CHANGE_MEMBERSHIP_AMOUNT: { id: 3, localeId: 'tab.changemembershipamount' }
};

export const settingTabsForUser = {
  MANAGE_ACCOUNT: { id: 1, localeId: 'tab.manageAccount' }
};

export const serieTabs = {
  DETAILS: { id: 1, localeId: 'tab.details' },
  EPISODES: { id: 2, localeId: 'tab.episodes' }
};

export const eventInoTabs = {
  DETAILS: { id: 1, localeId: 'tab.details' }
};

export const NARRATIVE_STATES = {
  published: 'published',
  processing: 'processing',
  postProducing: 'post_produce_processing'
};

export const EPISODE_STATES = {
  published: 'published',
  processing: 'processing',
  postProducing: 'post_produce_processing'
};

export const contentTabs = {
  ORIGINALS: { id: 1, localeId: 'tab.originals', tab: 'originals' },
  NARRATIVES: { id: 2, localeId: 'tab.narratives', tab: 'narratives' }
};

export const accountTabs = {
  SUBSCRIPTIONS: { id: 1, localeId: 'tab.subscriptions', tab: 'subscriptions' },
  ADSUBSCRIPTION: { id: 2, localeId: 'tab.AdSubscriptions', tab: 'plans' },
  PAYMENTS: { id: 3, localeId: 'tab.payments', tab: 'payments' },
  SETTINGS: { id: 4, localeId: 'tab.settings', tab: 'settings' }
};

export const searchPills = {
  ALL: { id: 1, localeId: 'pill.all', tab: 'all' },
  CREATOR: { id: 2, localeId: 'pill.creator', tab: 'creator' },
  SHORTS: { id: 3, localeId: 'pill.shorts', tab: 'shorts' },
  MOVIES: { id: 4, localeId: 'pill.movies', tab: 'movies' },
  EPISODES: { id: 5, localeId: 'pill.episodes', tab: 'episodes' }
};

export const adManagementTabs = {
  VIDEO_ADS: { id: 1, localeId: 'tab.videoAds', tab: 'videoAds' },
  BANNER_ADS: { id: 2, localeId: 'tab.bannerAds', tab: 'bannerAds' },
  WIDGET_ADS: { id: 3, localeId: 'tab.widgetsAds', tab: 'widgetsAds' }
};

export const legalTabs = {
  // LINK_OF_INTEREST: { id: 1, localeId: 'tab.linkOfInterest', tab: 'links_of_interest' },
  PRIVACY: { id: 2, localeId: 'tab.privacy', tab: 'privacy' },
  TERMS: { id: 3, localeId: 'tab.terms', tab: 'terms' },
  CONTENT: { id: 4, localeId: 'tab.contentMarketPlace', tab: 'content' }
};

export const MEMBERSHIP_STATUSES = {
  ACTIVE: 'active',
  ACTIVE_CANCELED: 'canceled',
  INACTIVE: 'inactive'
};

export const AD_ACTIVE_CANCELLED = 'active_canceled';

export const LIVE_STATUSES = {
  STARTED: 'started',
  UNSTARTED: 'unstarted'
};

const JWPLAYER_URL_FOR_LIVES = 'https://cdn.jwplayer.com';
export const JWPLAYER_LIVE_CHANNELS_URL = `${JWPLAYER_URL_FOR_LIVES}/live/channels/`;
export const JWPLAYER_MEDIA_URL = `${JWPLAYER_URL_FOR_LIVES}/v2/media/`;

export const PURCHASE_TYPES = {
  MEMBERSHIP: 'MEMBERSHIP',
  EPISODE: 'EPISODE',
  PODCAST: 'PODCAST',
  EVENT: 'EVENT',
  MEMBERSHIP_EPISODE: 'MEMBERSHIP_EPISODE'
};

export const REGEX_HASHTAGS = /(#\w+)/g;

export const routeTypes = {
  NARRATIVE: '/narratives',
  PODCAST: '/podcast'
};

export const videoTypes = {
  ORIGINALS: 'original',
  NARRATIVES: 'narrative'
};

export const adTypes = {
  BANNER: 'Banner',
  VIDEO: 'Video',
  WIDGET: 'Widget'
};

export const getAdPlacement = val => {
  switch (val) {
    case 'before_video':
      return 'Before Video';
    case 'after_video':
      return 'After Video';
    case 'custom_intervals':
      return 'At Specific Interval';
    case 'specific_interval':
      return 'At Custom Time';
    default:
      return 'Before Video';
  }
};

export const contentOptions = [
  { value: 'general_audience', label: 'General' },
  { value: 'teens', label: 'Teen (13 years to 17 years)' },
  { value: 'adults', label: 'Adult (18 years and above)' }
];

export const seasonOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' }
];

export const videoUploadsType = {
  Shorts: 'Shorts',
  Movies: 'Movies',
  Common: 'Common'
};

export const moviesImageTypes = {
  Logo: 'logo',
  coverArtMobile: 'coverArtMobile',
  covertArtDesktop: 'coverArtDesktop'
};

export const imageRatios = {
  profile: 1 / 1,
  covertArtWeb: 16 / 9,
  covertArtMobile: 1 / 1,
  logo: 1 / 1,
  bannerAd: 16 / 9,
  widgetAd: 4 / 3
};

export const imageSizes = {
  profile: {
    width: 500,
    height: 500
  },
  logo: {
    width: 300,
    height: 300
  },
  coverMobile: {
    width: 720,
    height: 720
  },
  coverWeb: {
    width: 1280,
    height: 720
  },
  bannerAdDesktop: {
    width: 768,
    height: 48
  },
  bannerAdMobile: {
    width: 350,
    height: 175
  },
  widgetAd: {
    width: 350,
    height: 175
  },
  eventDesktopCover: {
    width: 800,
    height: 450
  },
  eventMobileCover: {
    width: 320,
    height: 180
  }
};

export const ALLOWED_TIME_ZONES = {
  "International Date Line West": "Etc/GMT+12",
  "Midway Island": "Pacific/Midway",
  "American Samoa": "Pacific/Pago_Pago",
  "Hawaii": "Pacific/Honolulu",
  "Alaska": "America/Juneau",
  "Pacific Time (US & Canada)": "America/Los_Angeles",
  "Tijuana": "America/Tijuana",
  "Mountain Time (US & Canada)": "America/Denver",
  "Arizona": "America/Phoenix",
  "Chihuahua": "America/Chihuahua",
  "Mazatlan": "America/Mazatlan",
  "Central Time (US & Canada)": "America/Chicago",
  "Saskatchewan": "America/Regina",
  "Guadalajara": "America/Mexico_City",
  "Mexico City": "America/Mexico_City",
  "Monterrey": "America/Monterrey",
  "Central America": "America/Guatemala",
  "Eastern Time (US & Canada)": "America/New_York",
  "Indiana (East)": "America/Indiana/Indianapolis",
  "Bogota": "America/Bogota",
  "Lima": "America/Lima",
  "Quito": "America/Lima",
  "Atlantic Time (Canada)": "America/Halifax",
  "Caracas": "America/Caracas",
  "La Paz": "America/La_Paz",
  "Santiago": "America/Santiago",
  "Newfoundland": "America/St_Johns",
  "Brasilia": "America/Sao_Paulo",
  "Buenos Aires": "America/Argentina/Buenos_Aires",
  "Montevideo": "America/Montevideo",
  "Georgetown": "America/Guyana",
  "Puerto Rico": "America/Puerto_Rico",
  "Greenland": "America/Godthab",
  "Mid-Atlantic": "Atlantic/South_Georgia",
  "Azores": "Atlantic/Azores",
  "Cape Verde Is.": "Atlantic/Cape_Verde",
  "Dublin": "Europe/Dublin",
  "Edinburgh": "Europe/London",
  "Lisbon": "Europe/Lisbon",
  "London": "Europe/London",
  "Casablanca": "Africa/Casablanca",
  "Monrovia": "Africa/Monrovia",
  "UTC": "Etc/UTC",
  "Belgrade": "Europe/Belgrade",
  "Bratislava": "Europe/Bratislava",
  "Budapest": "Europe/Budapest",
  "Ljubljana": "Europe/Ljubljana",
  "Prague": "Europe/Prague",
  "Sarajevo": "Europe/Sarajevo",
  "Skopje": "Europe/Skopje",
  "Warsaw": "Europe/Warsaw",
  "Zagreb": "Europe/Zagreb",
  "Brussels": "Europe/Brussels",
  "Copenhagen": "Europe/Copenhagen",
  "Madrid": "Europe/Madrid",
  "Paris": "Europe/Paris",
  "Amsterdam": "Europe/Amsterdam",
  "Berlin": "Europe/Berlin",
  "Bern": "Europe/Zurich",
  "Zurich": "Europe/Zurich",
  "Rome": "Europe/Rome",
  "Stockholm": "Europe/Stockholm",
  "Vienna": "Europe/Vienna",
  "West Central Africa": "Africa/Algiers",
  "Bucharest": "Europe/Bucharest",
  "Cairo": "Africa/Cairo",
  "Helsinki": "Europe/Helsinki",
  "Kyiv": "Europe/Kiev",
  "Riga": "Europe/Riga",
  "Sofia": "Europe/Sofia",
  "Tallinn": "Europe/Tallinn",
  "Vilnius": "Europe/Vilnius",
  "Athens": "Europe/Athens",
  "Istanbul": "Europe/Istanbul",
  "Minsk": "Europe/Minsk",
  "Jerusalem": "Asia/Jerusalem",
  "Harare": "Africa/Harare",
  "Pretoria": "Africa/Johannesburg",
  "Kaliningrad": "Europe/Kaliningrad",
  "Moscow": "Europe/Moscow",
  "St. Petersburg": "Europe/Moscow",
  "Volgograd": "Europe/Volgograd",
  "Samara": "Europe/Samara",
  "Kuwait": "Asia/Kuwait",
  "Riyadh": "Asia/Riyadh",
  "Nairobi": "Africa/Nairobi",
  "Baghdad": "Asia/Baghdad",
  "Tehran": "Asia/Tehran",
  "Abu Dhabi": "Asia/Muscat",
  "Muscat": "Asia/Muscat",
  "Baku": "Asia/Baku",
  "Tbilisi": "Asia/Tbilisi",
  "Yerevan": "Asia/Yerevan",
  "Kabul": "Asia/Kabul",
  "Ekaterinburg": "Asia/Yekaterinburg",
  "Islamabad": "Asia/Karachi",
  "Karachi": "Asia/Karachi",
  "Tashkent": "Asia/Tashkent",
  "Kolkata": "Asia/Kolkata",
  "Kathmandu": "Asia/Kathmandu",
  "Astana": "Asia/Dhaka",
  "Dhaka": "Asia/Dhaka",
  "Sri Jayawardenepura": "Asia/Colombo",
  "Almaty": "Asia/Almaty",
  "Novosibirsk": "Asia/Novosibirsk",
  "Rangoon": "Asia/Rangoon",
  "Bangkok": "Asia/Bangkok",
  "Hanoi": "Asia/Bangkok",
  "Jakarta": "Asia/Jakarta",
  "Krasnoyarsk": "Asia/Krasnoyarsk",
  "Beijing": "Asia/Shanghai",
  "Chongqing": "Asia/Chongqing",
  "Hong Kong": "Asia/Hong_Kong",
  "Urumqi": "Asia/Urumqi",
  "Kuala Lumpur": "Asia/Kuala_Lumpur",
  "Singapore": "Asia/Singapore",
  "Taipei": "Asia/Taipei",
  "Perth": "Australia/Perth",
  "Irkutsk": "Asia/Irkutsk",
  "Ulaanbaatar": "Asia/Ulaanbaatar",
  "Seoul": "Asia/Seoul",
  "Osaka": "Asia/Tokyo",
  "Sapporo": "Asia/Tokyo",
  "Tokyo": "Asia/Tokyo",
  "Yakutsk": "Asia/Yakutsk",
  "Darwin": "Australia/Darwin",
  "Adelaide": "Australia/Adelaide",
  "Canberra": "Australia/Canberra",
  "Melbourne": "Australia/Melbourne",
  "Sydney": "Australia/Sydney",
  "Brisbane": "Australia/Brisbane",
  "Hobart": "Australia/Hobart",
  "Vladivostok": "Asia/Vladivostok",
  "Guam": "Pacific/Guam",
  "Port Moresby": "Pacific/Port_Moresby",
  "Magadan": "Asia/Magadan",
  "Srednekolymsk": "Asia/Srednekolymsk",
  "Solomon Is.": "Pacific/Guadalcanal",
  "New Caledonia": "Pacific/Noumea",
  "Fiji": "Pacific/Fiji",
  "Kamchatka": "Asia/Kamchatka",
  "Marshall Is.": "Pacific/Majuro",
  "Auckland": "Pacific/Auckland",
  "Wellington": "Pacific/Auckland",
  "Nuku'alofa": "Pacific/Tongatapu",
  "Tokelau Is.": "Pacific/Fakaofo",
  "Chatham Is.": "Pacific/Chatham",
  "Samoa": "Pacific/Apia"
}
