import React from 'react';
import { string, bool, func, oneOfType, shape, arrayOf } from 'prop-types';
import cn from 'classnames';

import { useModal, useNavigation, useSession } from 'hooks';
import { celebrityInfoShape } from 'constants/propTypesShapes';

import { BackArrowIcon, CrossIcon, ReportIcon } from 'components/icons';
import CelebritiesButton from 'components/Players/Controls/CelebritiesButton';

import { white, zIndexModalThird } from 'styles/common/_constants.scss';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import { routeTypes } from 'constants/constants';
import Modal from 'components/common/Modal/Modal';
import ReportModal from 'components/ReportModal/ReportModal';

const PlayerNavigationBar = ({
  celebrities,
  disableSharing,
  onExitPlayer,
  customGoBack,
  title,
  closeIcon,
  isReport,
  reportTitle,
  type,
  id
}) => {
  const { goBack, goToWithSearch } = useNavigation();
  const {
    user: { id: loggedId }
  } = useSession();
  const isLoggedUser = loggedId === celebrities?.id;
  const { toggle: reportToggle, isShowing: reportShowing } = useModal();
  const checkIsNarrative = () => {
    const route = routeWithProps(routesPaths.celebrityId, {
      username: celebrities.username
    });
    if (window.location.pathname.includes(routeTypes.NARRATIVE)) {
      return goToWithSearch(route, `?tab=2`);
    }
    if (window.location.pathname.includes(routeTypes.PODCAST)) {
      return goToWithSearch(route, `?tab=4`);
    }
    return goBack();
  };

  const onBackClicked = () => {
    !!onExitPlayer && onExitPlayer();
    customGoBack ? customGoBack() : checkIsNarrative();
  };

  return (
    <div className="navigation-bar">
      <div className="first-row">
        <div className={cn('back-button', { wide: disableSharing })} onClick={onBackClicked}>
          {closeIcon ? <CrossIcon color={white} /> : <BackArrowIcon />}
        </div>
        {!!title && (
          <div className="flex-center media-title">
            <h4 className="semibold">{title}</h4>
          </div>
        )}

        <div className={cn('right-content', { 'celebrity-padded': disableSharing })}>
          {!disableSharing && !!celebrities && (
            // <div className="secondary-row">
            <CelebritiesButton celebrities={celebrities} onExitPlayer={onExitPlayer} />
            // </div>
          )}
        </div>
      </div>

      {!isLoggedUser && isReport && (
        <div
          className="share-button"
          style={{ cursor: 'pointer', display: 'flex', justifyContent: 'end', marginRight: '10px' }}
          onClick={reportToggle}
        >
          <ReportIcon strokeWidth={2} />
        </div>
      )}
      {reportShowing && isReport && (
        <Modal
          hide={reportToggle}
          isShowing={reportShowing}
          topModal
          zIndex={zIndexModalThird}
          className="original-modal"
        >
          <ReportModal title={reportTitle} type={type} id={id} />
        </Modal>
      )}
    </div>
  );
};

PlayerNavigationBar.propTypes = {
  disableSharing: bool,
  onExitPlayer: func,
  customGoBack: func,
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))]),
  title: string,
  closeIcon: bool,
  isReport: bool,
  reportTitle: string,
  type: string,
  id: string
};

export default PlayerNavigationBar;
