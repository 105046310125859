import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';
// import { useIntl } from 'react-intl';

import { useNavigation, useSession, useToast } from 'hooks';
import capitalize from 'lodash/capitalize';
import { routesPaths } from 'constants/routesPaths';
// import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import { addEvent } from 'state/actions/eventActions';
import { routeWithProps } from 'utils/helpers';
import EventService from 'services/eventService';
import parseError from 'utils/parseError';

export default () => {
  const responseData = useSelector(({ eventReducer }) => eventReducer.eventData);
  const [event, setEvent] = useState({});
  const dispatch = useDispatch();
  const {
    user: { id: celebrityId, username, firstName, lastName }
  } = useSession();
  // const { trackEvent } = useAnalytics();
  const history = useHistory();
  const { goToWithSearch, goTo } = useNavigation();
  // const intl = useIntl();
  const { status, error } = useStatus(addEvent);
  const { showToast, showErrorToast } = useToast();
  const isHandled = useRef(false);

  const startEvent = async eventId => {
    try {
      const { data } = await EventService.startEventById(eventId);
      return data;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const onSubmit = useCallback(
    event => {
      setEvent(event);
      const formData = new FormData();
      formData.append('event[title]', event.title);
      formData.append('event[description]', event.description);
      formData.append('event[event_date]', event.event_date);
      formData.append('event[duration]', event.duration);
      formData.append('event[timezone]', event.timezone);
      formData.append('event[event_time]', event.event_time);
      formData.append('event[members_price]', event.members_price || 0);
      formData.append('event[non_members_price]', event.non_members_price || 0);
      formData.append('event[web_cover_image]', event.web_cover_image);
      formData.append('event[mobile_cover_image]', event.mobile_cover_image);
      formData.append('event[record_event]', event.record_event);
      formData.append('event[notify_subscribers]', event.notify_subscribers);
      formData.append('event[go_live]', event.isGoLive ? true : false);

      dispatch(addEvent(celebrityId, formData));
    },
    [dispatch]
  );

  useEffect(() => {
    const handleSuccess = async () => {
      if (status === SUCCESS && !isHandled.current) {
        isHandled.current = true;
        try {
          let routeData = {};
          if (event?.isGoLive) {
            const roomDetail = await startEvent(responseData?.event?.id);
            routeData = {
              pathname: routesPaths.goLive,
              state: {
                role: 'Host',
                room: roomDetail?.liveStream?.roomKey,
                username: `${firstName} ${lastName}`,
                celebrityId,
                eventId: responseData?.event?.id,
              },
            };
            history.push(routeData);
          } else {
            showToast('Event successfully scheduled!');
            const route = routeWithProps(routesPaths.celebrityId, {
              username: username.toLowerCase()
            });
            goToWithSearch(route, `?tab=3`);
          }
        } catch (err) {
          showErrorToast(`Failed to start event: ${err.message}`);
        } finally {
          dispatch(addEvent.reset());
        }
      }else if(status === ERROR){
         if(error === 'event_date You already have an upcoming event on the same date/time'){
          showErrorToast('event_date You already have an upcoming event on the same date/time');
         }
         dispatch(addEvent.reset());
      }
    };
  
    handleSuccess();
  }, [status, event, responseData, dispatch, goToWithSearch, showToast, username, celebrityId]);
  
  

  return {
    onSubmit,
    status,
    error: capitalize(error)
  };
};
