import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * LogViewer Component - Displays detailed logs for debugging and tracking upload operations
 * Auto-displays only when errors occur without any manual toggle
 *
 * @param {Object} props - Component props
 * @param {Array} props.logs - Array of log objects to display
 * @param {boolean} props.showLogs - Whether logs are visible (only used internally)
 * @param {Function} props.setShowLogs - Function to toggle log visibility (only used internally)
 * @param {string} props.title - Optional title for the log viewer
 */
const LogViewer = ({ logs = [], showLogs, setShowLogs, title = 'Process Logs' }) => {
  // Check if there are any error logs
  const hasErrors = logs.some(log => log.type === 'error');

  // Auto-show logs when errors occur
  useEffect(() => {
    if (hasErrors && !showLogs) {
      setShowLogs(true);
    } else if (!hasErrors && showLogs) {
      setShowLogs(false);
    }
  }, [hasErrors, showLogs, setShowLogs]);

  // Don't render anything if no logs or no errors
  if (!logs || logs.length === 0 || !hasErrors) return null;

  return (
    <div className="logs-container">
      <div className="log-viewer">
        <h4>
          {title}
          <span className="error-indicator"> (Errors Found)</span>
        </h4>
        <div className="log-container">
          {logs
            .filter(log => log.type === 'error')
            .map((log, index) => (
              <div key={index} className={`log-entry ${log.type}`}>
                <span className="log-timestamp">
                  [{new Date(log.timestamp).toLocaleTimeString()}]
                </span>
                <span className="log-message">{log.message}</span>
                {log.data && (
                  <pre className="log-data">
                    {typeof log.data === 'object' ? JSON.stringify(log.data, null, 2) : log.data}
                  </pre>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

LogViewer.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      data: PropTypes.any,
      type: PropTypes.oneOf(['info', 'warning', 'error']),
      timestamp: PropTypes.number
    })
  ),
  showLogs: PropTypes.bool.isRequired,
  setShowLogs: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default LogViewer;

/**
 * Hook for managing logs in components
 *
 * @param {Object} options
 * @param {boolean} options.enableConsoleOutput - Whether to output logs to console
 * @returns {Object} Logging utilities
 */
export const useLogger = (options = { enableConsoleOutput: true }) => {
  const [logs, setLogs] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  // Clear logs when component unmounts or manually
  const clearLogs = useCallback(() => {
    setLogs([]);
    setHasErrors(false);
  }, []);

  // Add a new log entry
  const addLog = useCallback(
    (message, data = null, type = 'info') => {
      if (options.enableConsoleOutput) {
        console.log(`[${type.toUpperCase()}] ${message}`, data);
      }

      if (type === 'error') {
        setHasErrors(true);
      }

      setLogs(prevLogs => [
        ...prevLogs,
        {
          message,
          data,
          type,
          timestamp: Date.now()
        }
      ]);
    },
    [options.enableConsoleOutput]
  );

  // Helper functions for specific log types
  const logInfo = useCallback((message, data) => addLog(message, data, 'info'), [addLog]);
  const logWarning = useCallback((message, data) => addLog(message, data, 'warning'), [addLog]);
  const logError = useCallback((message, data) => addLog(message, data, 'error'), [addLog]);

  // Log API errors with proper formatting
  const logApiError = useCallback(
    (message, error) => {
      const errorResponse = error.response || {};
      const errorData = {
        status: errorResponse.status,
        statusText: errorResponse.statusText,
        data: errorResponse.data,
        message: error.message,
        stack: error.stack
      };

      addLog(message, errorData, 'error');
    },
    [addLog]
  );

  return {
    logs,
    showLogs,
    setShowLogs,
    hasErrors,
    addLog,
    logInfo,
    logWarning,
    logError,
    logApiError,
    clearLogs
  };
};
