import React from 'react';
import { node, string, bool } from 'prop-types';
import cn from 'classnames';
import { useIsMobile } from 'hooks';

import { useBlockScroll } from 'hooks';
import ArrowIcon from 'components/icons/others/ArrowIcon';

const ScrollingArrows = ({ className, children, disabled = false }) => {
  const { scrollRef, scrollRight, scrollLeft, showLeftArrow, showRightArrow } = useBlockScroll();
  const isMobile = useIsMobile();

  return (
    <div className="arrows-container">
      <div
        ref={scrollRef}
        className={cn('arrows-content-container scrollable-container', className)}
      >
        {children}
      </div>
      {!disabled && (
        <>
          <div
            className={cn('arrow left clickable', { hidden: !showLeftArrow || isMobile })}
            onClick={scrollLeft}
          >
            <div className="arrow-icon">
              <ArrowIcon width={20} height={30} type="left" />
            </div>
          </div>
          <div className={cn('arrow clickable', { hidden: !showRightArrow || isMobile })} onClick={scrollRight}>
            <div className="arrow-icon">
              <ArrowIcon width={20} height={30} type="right" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ScrollingArrows.propTypes = {
  className: string,
  children: node,
  disabled: bool
};

export default ScrollingArrows;
