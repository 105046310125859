/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import ThumbsUpIcon from 'assets/thumbs-up.png';
import HeartIcon from 'assets/heart.png';
import HeartFilledIcon from 'assets/heart-filled.png';
import ThumbsUpFilledIcon from 'assets/Thumbs-up-filled.png';
import faceFilledIcon from 'assets/faceFilled.png';
import faceWhiteIcon from 'assets/faceWhite.png';

import useReactions from 'hooks/celebrities/useReactions';
import SharingBlock from 'components/sharing/SharingBlock/SharingBlock';
import CelebritiesButton from 'components/Players/Controls/CelebritiesButton';
import { ShareIcon } from 'components/icons';
import { useIntl } from 'react-intl';
import { getFullName } from 'utils/helpers';
// import CelebritiesButton from 'components/Players/Controls/CelebritiesButton';
// import SharingBlock from 'components/sharing/SharingBlock/SharingBlock';
// import { ShareIcon } from 'components/icons';
// import { useIntl } from 'react-intl';
// import { getFullName } from 'utils/helpers';

const ActionableContents = ({
  id,
  contentType,
  disableSharing,
  videoTitle,
  celebrity,
  authenticated
}) => {
  const { requestReaction, requestDeleteReaction, responseData, getReactionData } = useReactions(
    id,
    contentType
  );
  const intl = useIntl();
  // const likes = data ? data?.likes ?? 0 : responseData?.likes ?? 0;
  // const dislikes = data ? data?.dislikes ?? 0 : responseData?.dislikes ?? 0;
  // const favourites = data ? data?.favourites ?? 0 : responseData?.favourites ?? 0;

  const { firstName, lastName, id: celebrityId } = celebrity;
  const [showIcons, setShowIcons] = useState(false);

  const toggleButtons = e => {
    e.stopPropagation();
    setShowIcons(!showIcons);
  };

  const isDisliked = responseData?.isDisliked ?? false;
  const isLiked = responseData?.isLiked ?? false;
  const isFavourited = responseData?.isFavourited ?? false;

  const requestUpdate = (val, alreadyReacted) => {
    if (alreadyReacted) {
      requestDeleteReaction(val);
    } else {
      requestReaction(val);
    }
  };

  useEffect(() => {
    if (authenticated) {
      getReactionData();
    }
  }, []);
  return (
    <>
     {contentType !== 'Event' && <div className={cn('actionable-contents')}>
        <div className="content-actions">
          <div className="like-container">
            <img
              className="content-img"
              src={isLiked ? ThumbsUpFilledIcon : ThumbsUpIcon}
              alt="like"
              onClick={() => requestUpdate('like', isLiked)}
            />
          </div>
          <div className="love-container">
            <img
              className="content-img"
              src={isFavourited ? HeartFilledIcon : HeartIcon}
              alt="love"
              onClick={() => requestUpdate('favourite', isFavourited)}
            />
          </div>
          <div className="dislike-container">
            <img
              className="content-img"
              src={isDisliked ? faceFilledIcon : faceWhiteIcon}
              alt="dislike"
              onClick={() => requestUpdate('dislike', isDisliked)}
            />
          </div>
        </div>
        <div className="share-container">
          {disableSharing ? (
            !!celebrity && (
              <CelebritiesButton celebrities={celebrity} onExitPlayer={onExitPlayer} topRow />
            )
          ) : (
            <div className="top-row">
              {showIcons ? (
                <SharingBlock
                  onClose={toggleButtons}
                  shareUrl={window.location.href}
                  videoTitle={videoTitle}
                  shareText={intl.formatMessage(
                    { id: 'sharing.narrative' },
                    { creator: getFullName(firstName, lastName) }
                  )}
                />
              ) : (
                <div className="share-button" onClick={toggleButtons}>
                  <ShareIcon strokeWidth={2} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>}
    </>
  );
};

export default React.memo(ActionableContents);
