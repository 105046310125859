import React, { useState, useMemo } from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { contentShape } from 'constants/propTypesShapes';
import { useIsMobile } from 'hooks';

import FeaturedCarousel from 'components/featured/FeaturedCarousel/FeaturedCarousel';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import SeriesHero from 'components/featured/SeriesHero/SeriesHero';
import ScheduledEventHero from 'components/featured/ScheduledEventHero/ScheduledEventHero';

const FeaturedHero = ({ content, titleId }) => {
  const [selectedContent, setSelectedContent] = useState(content[0]);

  const { id, bannerUrl, desktopImageUrl, mobileImageUrl, liveEvent } = selectedContent || {};
  const isMobile = useIsMobile();

  const backgroundImage = useMemo(() => {
    if (bannerUrl) return bannerUrl;
    return isMobile ? mobileImageUrl : desktopImageUrl;
  }, [isMobile, mobileImageUrl, desktopImageUrl, bannerUrl]);

  return (
    <div className="featured-content">
      <BackgroundOpacity background={backgroundImage} isVideo={!!bannerUrl} opacity={0.5} />
      <div className="featured-content-gradient" />
      <div className="relative">
        {liveEvent ? (
          <ScheduledEventHero event={selectedContent} />
        ) : (
          <SeriesHero serie={selectedContent} />
        )}
        <FeaturedCarousel
          content={content}
          selectedContent={id}
          onClick={setSelectedContent}
          className="show-title-carousel"
          titleId={titleId}
        />
      </div>
    </div>
  );
};

FeaturedHero.propTypes = {
  content: arrayOf(shape(contentShape)).isRequired,
  titleId: string
};

FeaturedHero.defaultProps = {
  titleId: 'featured.popularNow'
};

export default FeaturedHero;
