/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
import Close from 'components/common/Close/Close';
import Input from 'components/common/Input/Input';
import cn from 'classnames';
import {
  useClickOutside,
  useForm,
  useIsMobile,
  useNavigation,
  useTextInputProps,
  useToast,
  useValidation
} from 'hooks';
import Select from 'react-select';
import { func } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { white, black40 } from 'styles/common/_constants.scss';
import { CrownIcon } from 'components/icons';
import { defineMessages, useIntl } from 'react-intl';
import { episodeValidation } from 'utils/constraints';
import Button from 'components/common/Button/Button';
import { convertDateFormat, getTodayDate, timeExtract } from 'utils/date';
import { useDispatch, useSelector } from 'react-redux';
import { addEpisode, getVideoUrlForJWPlayer, updateEpisode } from 'state/actions/episodesActions';
import { ERROR, SUCCESS, useStatus } from '@rootstrap/redux-tools';
import { some } from 'lodash';
import { contentOptions, videoUploadsType } from 'constants/constants';
import { handleDateKeyDown, routeWithProps, validateVideo } from 'utils/helpers';
import { ValidationErrors } from 'utils/validationErrors';
import { customDropDownStyles } from 'styles/customDropDownStyles';
import { routesPaths } from 'constants/routesPaths';
import UserService from 'services/userService';
import parseError from 'utils/parseError';

const FIELDS = {
  title: 'title',
  description: 'description',
  creditsStartTime: 'creditsStartTime',
  membersPrice: 'membersPrice',
  nonMembersPrice: 'nonMembersPrice',
  seriesVideo: 'seriesVideo',
  lead_cast: 'lead_cast',
  credits: 'credits',
  content_viewer_category: 'content_viewer_category',
  notify_subscribers: 'notify_subscribers',
  earlyReleaseDate: 'earlyReleaseDate',
  earlyReleaseTime: 'earlyReleaseTime',
  releaseDate: 'releaseDate',
  releaseTime: 'releaseTime'
};

const messages = defineMessages({
  description: { id: 'form.description' },
  descriptionPlaceholder: { id: 'form.descriptionPlaceholder' },
  title: { id: 'form.title' },
  titlePlaceholder: { id: 'form.titlePlaceholder' },
  logo: { id: 'form.logo' },
  uploadLogo: { id: 'form.uploadLogo' },
  trailer: { id: 'form.trailer' },
  uploadTrailer: { id: 'form.uploadTrailer' },
  cover: { id: 'form.cover' },
  uploadCover: { id: 'form.uploadCover' },
  coverMobile: { id: 'form.coverMobile' },
  uploadCoverMobile: { id: 'form.uploadCoverMobile' },
  categories: { id: 'form.categories' },
  addBtn: { id: 'form.add' },
  shareTo: { id: 'form.shareTo' },
  episodes: { id: 'label.episodes' },
  addEpisode: { id: 'btn.addEpisode' },
  newEpisode: { id: 'form.newEpisode' },
  video: { id: 'form.video' },
  nameYourEpisodePlaceholder: { id: 'title.nameYourEpisodePlaceholder' },
  descriptionEpisodePlaceholder: { id: 'form.descriptionEpisodePlaceholder' },
  creditStartTime: { id: 'form.creditStartTime' },
  hhmmssPlaceholder: { id: 'form.hhmmssPlaceholder' },
  date: { id: 'form.date' },
  time: { id: 'form.time' },
  releaseDateTimeLabel: { id: 'form.releaseDateTimeLabel' },
  setPriceLabel: { id: 'form.setPriceLabel' },
  nonMembers: { id: 'form.nonMembers' },
  members: { id: 'form.members' },
  chooseVideo: { id: 'form.chooseVideo' },
  earlyReleaseMembers: { id: 'form.earlyReleaseMembers' },
  normalRelease: { id: 'form.normalRelease' }
});

const AddEpisodeForm = ({ hide, id, handleClose, editEpisodeData, seriesId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { goTo } = useNavigation();
  const ref = useClickOutside(hide, id);
  const username = useRef();
  const isMobile = useIsMobile();
  const [exclusive, setExclusive] = useState(false);
  const seriesVideoRef = useRef(null);
  const [selectedContent, setSelectedContent] = useState([]);
  const [seriesVideo, setSeriesVideo] = useState(null);
  const [creditStartTime, setCreditStartTime] = useState('');
  const [releaseTime, setReleaseTime] = useState('');
  const [earlyReleaseTime, setEarlyReleaseTime] = useState('');
  const [membersPrice, setMembersPrice] = useState('');
  const [nonMembersP, setNonMembersP] = useState('');
  const { error, status } = useStatus(getVideoUrlForJWPlayer);
  const { error: updateEpisodeError, status: updateEpisodeStatus } = useStatus(updateEpisode);
  const { episode } = useSelector(({ episodes: { episode } }) => ({ episode }));
  const { showErrorToast, showToast } = useToast();
  const [subscribedFans, setSubScribedFans] = useState(true);

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    if (editEpisodeData !== null) {
      getProfile();
    }
  }, []);

  useEffect(() => {
    if (status === SUCCESS) {
      dispatch(addEpisode(episode));
      dispatch(getVideoUrlForJWPlayer.reset());
      handleClose();
    }
    if (status === ERROR) {
      showErrorToast(error);
      dispatch(getVideoUrlForJWPlayer.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleChecked = event => {
    setExclusive(event.currentTarget.checked);
  };

  const validator = useValidation(episodeValidation);

  useEffect(() => {
    if (updateEpisodeStatus === SUCCESS) {
      showToast('Episode Updated Successfully!');
      dispatch(updateEpisode.reset());
      goTo(
        routeWithProps(routesPaths.celebrityId, {
          username: username.current.toLowerCase()
        })
      );
    }
    if (updateEpisodeStatus === ERROR) {
      showErrorToast(updateEpisodeError);
      dispatch(updateEpisode.reset());
    }
  }, [updateEpisodeStatus]);

  const onSubmit = async values => {
    if (editEpisodeData !== null) {
      const updatedEpisode = {
        ...editEpisodeData,
        ...values,
        leadCast: values.lead_cast,
        contentViewerCategory: values.content_viewer_category,
        viewer_category: values.content_viewer_category,
        viewerCategory: values.content_viewer_category,
        credits: values.credits,
        earlyReleaseDateTime: exclusive
          ? undefined
          : values[FIELDS.earlyReleaseDate]
          ? convertDateFormat(
              `${values[FIELDS.earlyReleaseDate]} ${values[FIELDS.earlyReleaseTime]}`
            )
          : undefined,
        releaseDateTime: values[FIELDS.releaseDate]
          ? convertDateFormat(`${values[FIELDS.releaseDate]} ${values[FIELDS.releaseTime]}`)
          : undefined,
        membersPrice: membersPrice.replace('$', ''),
        nonMembersPrice: nonMembersP.replace('$', ''),
        creditsStartTime: creditStartTime || '',
        exclusive,
        seriesVideo
      };
      dispatch(
        updateEpisode({
          seriesId,
          episodeId: updatedEpisode.episodeId,
          position: updatedEpisode.position,
          ...updatedEpisode
        })
      );
    } else {
      values.earlyReleaseDateTime = exclusive
        ? undefined
        : values[FIELDS.earlyReleaseDate]
        ? convertDateFormat(`${values[FIELDS.earlyReleaseDate]} ${values[FIELDS.earlyReleaseTime]}`)
        : undefined;
      values.releaseDateTime = values[FIELDS.releaseDate]
        ? convertDateFormat(`${values[FIELDS.releaseDate]} ${values[FIELDS.releaseTime]}`)
        : undefined;
      values.seriesVideo = seriesVideo;
      values.creditStartTime = creditStartTime || null;
      await dispatch(getVideoUrlForJWPlayer({ ...values, exclusive }));
    }
  };
  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
        membersPrice: ' ',
        nonMembersPrice: ' ',
        notify_subscribers: true,
        [FIELDS.earlyReleaseDate]: '',
        [FIELDS.earlyReleaseTime]: '',
        [FIELDS.releaseDate]: '',
        [FIELDS.releaseTime]: '',
        [FIELDS.creditsStartTime]: ''
      }
    },
    [onSubmit]
  );
  useEffect(() => {
    if (editEpisodeData !== null) {
      setValues({
        ...editEpisodeData,
        [FIELDS.lead_cast]: editEpisodeData.leadCast,
        [FIELDS.nonMembersPrice]: !editEpisodeData.exclusive
          ? editEpisodeData.nonMembersPrice
          : null,
        [FIELDS.earlyReleaseDate]: editEpisodeData.earlyReleaseDateTime
          ? new Date(editEpisodeData.earlyReleaseDateTime).toISOString().split('T')[0]
          : null,
        [FIELDS.earlyReleaseTime]: editEpisodeData.earlyReleaseDateTime
          ? timeExtract(editEpisodeData.earlyReleaseDateTime)
          : null,
        [FIELDS.releaseDate]: editEpisodeData.releaseDateTime
          ? new Date(editEpisodeData.releaseDateTime).toISOString().split('T')[0]
          : null,
        [FIELDS.releaseTime]: editEpisodeData.releaseDateTime
          ? timeExtract(editEpisodeData.releaseDateTime)
          : null,
        [FIELDS.creditsStartTime]:
          editEpisodeData.credits_start_time !== '' ? editEpisodeData.credits_start_time : null
      });
      const selectedContent = contentOptions.filter(
        item => item.value === editEpisodeData.contentViewerCategory
      );
      setCreditStartTime(
        editEpisodeData?.creditsStartTime ? editEpisodeData?.creditsStartTime : ''
      );
      setReleaseTime(
        editEpisodeData.releaseDateTime ? timeExtract(editEpisodeData.releaseDateTime) : ''
      );
      setEarlyReleaseTime(
        editEpisodeData.earlyReleaseDateTime
          ? timeExtract(editEpisodeData.earlyReleaseDateTime)
          : ''
      );
      setSelectedContent(selectedContent[0]);
      setMembersPrice(editEpisodeData.membersPrice ? `$${editEpisodeData.membersPrice}` : '');
      setExclusive(editEpisodeData.exclusive);
      setSeriesVideo(editEpisodeData.link);
      if (!editEpisodeData.exclusive) {
        setNonMembersP(
          editEpisodeData.nonMembersPrice ? `$${editEpisodeData.nonMembersPrice}` : ''
        );
      }
    }
  }, [editEpisodeData]);

  const nonMembersPrice = !editEpisodeData && values[FIELDS.nonMembersPrice];
  // useEffect(() => {
  //   if (!nonMembersPrice && !editEpisodeData) {
  //     setValues(prev => ({ ...prev, [FIELDS.membersPrice]: '0' }));
  //     setMembersPrice('0');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [nonMembersPrice]);

  const handleSeriesVideoUpload = () => {
    seriesVideoRef.current.click();
  };

  const resetSeriesVideoInput = () => {
    if (seriesVideoRef.current) {
      seriesVideoRef.current.value = '';
    }
  };

  const handleSeriesVideoChange = async e => {
    const file = e.target.files[0];
    // if (file && file.type.startsWith('video/')) {
    //   setSeriesVideo(file);
    //   handleValueChange(FIELDS.seriesVideo, file);
    // } else {
    //   showErrorToast('Please upload a valid video file.');
    //   e.target.value = null;
    // }
    if (file && file.type.startsWith('video/')) {
      const { isValid, isResolutionValid, isAspectRatioValid, error } = await validateVideo(
        file,
        videoUploadsType.Movies
      );
      if (error) {
        showErrorToast(ValidationErrors.loadingVideo);
        resetSeriesVideoInput();
      } else if (!isResolutionValid) {
        showErrorToast(ValidationErrors.resolutionValidation);
        resetSeriesVideoInput();
      } else if (!isAspectRatioValid) {
        showErrorToast(ValidationErrors.aspectRatioValidationForMovies);
        resetSeriesVideoInput();
      } else if (isValid) {
        setSeriesVideo(file);
        handleValueChange(FIELDS.seriesVideo, file);
      }
    } else {
      showErrorToast(ValidationErrors.validFile);
      resetSeriesVideoInput();
    }
  };
  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);
  const nullNonMembersPrice =
    !editEpisodeData && !exclusive && (!nonMembersPrice || nonMembersPrice === 0);

  const handleEarlyReleaseDate = e => {
    handleValueChange(FIELDS.earlyReleaseDate, e.target.value);
  };

  // const handleEarlyReleaseTime = e => {
  //   handleValueChange(FIELDS.earlyReleaseTime, e.target.value);
  // };

  const handleReleaseDate = e => {
    handleValueChange(FIELDS.releaseDate, e.target.value);
  };

  // const handleReleaseTime = e => {
  //   handleValueChange(FIELDS.releaseTime, e.target.value);
  // };

  const handleClearReleaseDateTime = () => {
    setValues(prev => ({
      ...prev,
      [FIELDS.releaseDate]: '',
      [FIELDS.releaseTime]: ''
    }));
    setReleaseTime('');
  };

  const handleClearEarlyReleaseDateTime = () => {
    setValues(prev => ({
      ...prev,
      [FIELDS.earlyReleaseDate]: '',
      [FIELDS.earlyReleaseTime]: ''
    }));
    setEarlyReleaseTime('');
  };

  const onChangeReleaseTime = event => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    if (value.length > 2) {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
    handleValueChange(FIELDS.releaseTime, value);
    setReleaseTime(value);
  };

  const onChangeEarlyReleaseTime = event => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    if (value.length > 2) {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
    handleValueChange(FIELDS.earlyReleaseTime, value);
    setEarlyReleaseTime(value);
  };

  const onChangeCreditsStartTime = event => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    if (value.length > 2) {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
    if (value.length > 5) {
      value = `${value.slice(0, 5)}:${value.slice(5, 7)}`;
    }
    setCreditStartTime(value);
  };

  const onChangeMembersPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');
    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(FIELDS.membersPrice, valueWithoutDollar);
      setMembersPrice(value);
    } else {
      setMembersPrice('');
      handleValueChange(FIELDS.membersPrice, '');
    }
  };

  const onChangeNonMembersPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');
    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(FIELDS.nonMembersPrice, valueWithoutDollar);
      setNonMembersP(value);
    } else {
      setNonMembersP('');
      handleValueChange(FIELDS.nonMembersPrice, '');
    }
  };

  const handleContentChange = e => {
    setSelectedContent(e);
    handleValueChange(FIELDS.content_viewer_category, e.value);
  };

  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        {!editEpisodeData ? intl.formatMessage(messages.newEpisode) : 'Update Episode'}{' '}
        <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="serie-landing-header-content">
        <div className="series-form">
          <div className="series-form-fields">
            <Input
              name="seriesTitle"
              placeholder={intl.formatMessage(messages.nameYourEpisodePlaceholder)}
              label={intl.formatMessage(messages.title)}
              errors={errors[FIELDS.title]}
              {...inputProps(FIELDS.title)}
            />
            <Input
              name="seriesDescription"
              placeholder={intl.formatMessage(messages.descriptionEpisodePlaceholder)}
              label={intl.formatMessage(messages.description)}
              errors={errors[FIELDS.description]}
              {...inputProps(FIELDS.description)}
            />
            <Input
              name="seriesLeadCast"
              placeholder="Lead cast of the episode"
              label="Lead Cast (optional)"
              errors={errors[FIELDS.lead_cast]}
              {...inputProps(FIELDS.lead_cast)}
            />
            <Input
              name="seriesCredits"
              placeholder="Credits of the episode"
              label="Credits (optional)"
              errors={errors[FIELDS.credits]}
              {...inputProps(FIELDS.credits)}
            />
            <div
              className={cn('custom-content')}
              style={{ margin: `${isMobile ? '0 1.4rem 2.8rem' : ''}` }}
            >
              <span className="custom-content-label">Content Viewers</span>
              <div className="single-select">
                <Select
                  value={selectedContent}
                  options={contentOptions}
                  styles={customDropDownStyles}
                  onChange={handleContentChange}
                  isSearchable={false}
                />
              </div>
            </div>
            <input
              type="file"
              ref={seriesVideoRef}
              style={{ display: 'none' }}
              accept=".mp4,.mov"
              onChange={handleSeriesVideoChange}
              name="series_video"
            />
            <div className="input-container upload-div">
              <span className="tag-bold input-label">{intl.formatMessage(messages.video)}</span>
              {seriesVideo ? (
                <div>
                  <video className="video-preview" autoPlay>
                    <source
                      src={editEpisodeData ? seriesVideo : URL.createObjectURL(seriesVideo)}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <div className="button-margin">
                  <Button
                    labelId={intl.formatMessage(messages.chooseVideo)}
                    type="secondary"
                    onClick={handleSeriesVideoUpload}
                    className="cover-button"
                  />
                  <span className="label-margin">Recommended dimension: 1280px X 720px (16:9)</span>
                </div>
              )}
            </div>
            <Input
              name="creditStartTime"
              placeholder={intl.formatMessage(messages.hhmmssPlaceholder)}
              label={intl.formatMessage(messages.creditStartTime)}
              errors={errors[FIELDS.creditsStartTime]}
              {...inputProps(FIELDS.creditsStartTime)}
              maxLength={8}
              value={creditStartTime}
              onChange={onChangeCreditsStartTime}
            />
            <div className="input-container">
              <div className="form-group">
                <input type="checkbox" id="members" checked={exclusive} onChange={handleChecked} />
                <label htmlFor="members" className="tag-bold input-label">
                  Exclusive for Members
                  <span>
                    <CrownIcon width={20} height={20} />
                  </span>
                </label>
              </div>
            </div>
            {exclusive ? (
              <>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.releaseDateTimeLabel)}
                  </div>
                  <div className="input-card">
                    <div className="d-flex">
                      <Input
                        name="date"
                        type="date"
                        placeholder={intl.formatMessage(messages.date)}
                        label={intl.formatMessage(messages.date)}
                        value={values[FIELDS.releaseDate]}
                        onChange={handleReleaseDate}
                        min={getTodayDate()}
                      />
                      {/* <Input
                        name="time"
                        type="time"
                        placeholder={intl.formatMessage(messages.time)}
                        label={intl.formatMessage(messages.time)}
                        value={values[FIELDS.releaseTime]}
                        onChange={handleReleaseTime}
                      /> */}
                      <Input
                        name="time"
                        placeholder="HH:MM"
                        label={intl.formatMessage(messages.time)}
                        errors={errors[FIELDS.releaseTime]}
                        {...inputProps(FIELDS.releaseTime)}
                        maxLength={5}
                        value={releaseTime}
                        onChange={onChangeReleaseTime}
                      />
                    </div>
                    <p style={{ cursor: 'pointer' }} onClick={handleClearEarlyReleaseDateTime}>
                      Clear
                    </p>
                    <small>*Leave it empty in order to be published right away.</small>
                  </div>
                </div>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.setPriceLabel)}
                  </div>
                  <div className={`input-card ${exclusive ? 'active' : ''}`}>
                    <div className="d-flex">
                      <Input
                        name="members"
                        placeholder="Price"
                        label={intl.formatMessage(messages.members)}
                        errors={errors[FIELDS.membersPrice]}
                        {...inputProps(FIELDS.membersPrice)}
                        onChange={onChangeMembersPrice}
                        value={membersPrice}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.earlyReleaseMembers)}
                  </div>
                  <div className="input-card">
                    <div className="d-flex">
                      <Input
                        name="date"
                        type="date"
                        placeholder={intl.formatMessage(messages.date)}
                        label={intl.formatMessage(messages.date)}
                        value={values[FIELDS.earlyReleaseDate]}
                        onKeyDown={handleDateKeyDown}
                        onChange={handleEarlyReleaseDate}
                        min={getTodayDate()}
                      />
                      {/* <Input
                        name="time"
                        type="time"
                        placeholder={intl.formatMessage(messages.time)}
                        label={intl.formatMessage(messages.time)}
                        value={values[FIELDS.earlyReleaseTime]}
                        onKeyDown={handleDateKeyDown}
                        onChange={handleEarlyReleaseTime}
                      /> */}
                      <Input
                        name="time"
                        placeholder="HH:MM"
                        label={intl.formatMessage(messages.time)}
                        errors={errors[FIELDS.earlyReleaseTime]}
                        {...inputProps(FIELDS.earlyReleaseTime)}
                        maxLength={5}
                        value={earlyReleaseTime}
                        onChange={onChangeEarlyReleaseTime}
                      />
                    </div>
                    <p style={{ cursor: 'pointer' }} onClick={handleClearEarlyReleaseDateTime}>
                      Clear
                    </p>
                  </div>
                </div>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.normalRelease)}
                  </div>
                  <div className="input-card">
                    <div className="d-flex">
                      <Input
                        name="date"
                        type="date"
                        placeholder={intl.formatMessage(messages.date)}
                        label={intl.formatMessage(messages.date)}
                        value={values[FIELDS.releaseDate]}
                        onKeyDown={handleDateKeyDown}
                        onChange={handleReleaseDate}
                        min={getTodayDate()}
                      />
                      {/* <Input
                        name="time"
                        type="time"
                        placeholder={intl.formatMessage(messages.time)}
                        label={intl.formatMessage(messages.time)}
                        value={values[FIELDS.releaseTime]}
                        onKeyDown={handleDateKeyDown}
                        onChange={handleReleaseTime}
                      /> */}
                      <Input
                        name="time"
                        placeholder="HH:MM"
                        label={intl.formatMessage(messages.time)}
                        errors={errors[FIELDS.releaseTime]}
                        {...inputProps(FIELDS.releaseTime)}
                        maxLength={5}
                        value={releaseTime}
                        onChange={onChangeReleaseTime}
                      />
                    </div>
                    <p style={{ cursor: 'pointer' }} onClick={handleClearReleaseDateTime}>
                      Clear
                    </p>
                    <small>*Leave it empty in order to be published right away.</small>
                  </div>
                </div>
                <div className="input-container">
                  <div className="tag-bold input-label">
                    {intl.formatMessage(messages.setPriceLabel)}
                  </div>
                  <div className="input-card">
                    <div className="d-flex">
                      <Input
                        name="nonMembers"
                        placeholder="Price"
                        label={intl.formatMessage(messages.nonMembers)}
                        errors={errors[FIELDS.nonMembersPrice]}
                        {...inputProps(FIELDS.nonMembersPrice)}
                        onChange={onChangeNonMembersPrice}
                        value={nonMembersP}
                      />
                      <Input
                        name="members"
                        placeholder="Price"
                        label={intl.formatMessage(messages.members)}
                        errors={errors[FIELDS.membersPrice]}
                        {...inputProps(FIELDS.membersPrice)}
                        onChange={onChangeMembersPrice}
                        value={membersPrice}
                      />
                    </div>
                    <small>*Leave this field empty for free content.</small>
                  </div>
                </div>
              </>
            )}
            {!editEpisodeData && (
              <div className={cn('notify-box')}>
                <input
                  type="checkbox"
                  checked={subscribedFans}
                  onChange={() => {
                    setSubScribedFans(!subscribedFans);
                    handleValueChange(FIELDS.notify_subscribers, !subscribedFans);
                  }}
                />
                Notify all paid fans
              </div>
            )}
            <Button
              labelId={
                !editEpisodeData ? intl.formatMessage(messages.addEpisode) : 'Update Episode'
              }
              type="secondary"
              onClick={handleSubmit}
              className="in-add-button"
              disabled={
                some(errors) || !values[FIELDS.title] || !values[FIELDS.description] || !seriesVideo
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AddEpisodeForm.propTypes = {
  hide: func.isRequired
};

export default AddEpisodeForm;
