/* eslint-disable no-useless-escape */
import validate from 'validate.js';

validate.validators.presence.options = { allowEmpty: false };

validate.validators.digitsOnly = (value, options, key) => {
  if (value && !/^\d+$/.test(value)) {
    return options.message || `${key} must contain only digits`;
  }
};

validate.validators.priceValidator = (value, options, key, globalOptions) => {
  if (options.allowBlank && (value === null || value === undefined || value.trim() === '')) {
    return undefined;
  }

  if (globalOptions.is_go_live && (value == 0 || value == 0.0)) {
    return undefined;
  }

  if (isNaN(value) || !validate.isNumber(parseFloat(value))) {
    return options.message || `is not a valid number`;
  }

  const numValue = parseFloat(value);
  if (options.greaterThanOrEqualTo !== undefined && numValue < options.greaterThanOrEqualTo) {
    return options.message || `must be greater than or equal to ${options.greaterThanOrEqualTo}`;
  }

  if (options.lessThan !== undefined && numValue >= options.lessThan) {
    return options.message || `must be less than ${options.lessThan}`;
  }

  return undefined;
};

validate.validators.timeNotBeforeNow = (value, options, key, globalOptions) => {
  if (!value || !globalOptions.event_date || globalOptions.status === 'completed') return undefined;

  const currentDate = new Date();
  const [inputHours, inputMinutes] = value.split(':').map(Number);

  const selectedDate = new Date(globalOptions.event_date);

  if (selectedDate.toDateString() === currentDate.toDateString()) {
    const currentHours = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();

    if (
      inputHours < currentHours ||
      (inputHours === currentHours && inputMinutes < currentMinutes)
    ) {
      return options.message || `${key} must not be before the current time`;
    }
  }

  return undefined;
};

validate.validators.optionalUrl = function(value, options) {
  if (!value || value.trim() === '') {
    return undefined;
  }

  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/i;
  if (!urlPattern.test(value)) {
    return options.message || 'is invalid';
  }

  return undefined;
};

export const validations = (constraints, props = {}) => data =>
  validate(data, constraints, props) || {};

export const login = {
  phone: {
    presence: true
  }
};

export const signup = {
  firstName: {
    presence: true
  },
  lastName: {
    presence: true
  },
  email: {
    presence: true,
    email: true
  },
  phone: {
    presence: true
  }
};

export const billing = {
  firstName: {
    presence: true
  },
  lastName: {
    presence: true
  },
  zipcode: {
    presence: true
  },
  country: {
    presence: true
  }
};

export const creator = {
  username: {
    presence: true
  },
  membershipPrice: {
    priceValidator: {
      greaterThanOrEqualTo: 0.5,
      lessThan: 1000
    }
  }
};

export const creatorValidations = {
  membershipPrice: {
    priceValidator: {
      greaterThanOrEqualTo: 0.5,
      lessThan: 1000
    }
  }
};

export const bannerAdValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  action_button_url: {
    optionalUrl: {
      message: 'is invalid'
    }
  }
};

export const widgetAdValidation = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  }
};

export const createNarrativeValidation = {
  title: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 50
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 160
    }
  },
  leadCast: {
    length: {
      maximum: 160
    }
  },
  credits: {
    length: {
      maximum: 160
    }
  }
};

export const createSeriesValidation = {
  title: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 50
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 160
    }
  },
  lead_cast: {
    length: {
      maximum: 160
    }
  },
  credits: {
    length: {
      maximum: 160
    }
  }
};

export const episodeValidation = {
  title: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 50
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 160
    }
  },
  lead_cast: {
    length: {
      maximum: 160
    }
  },
  credits: {
    length: {
      maximum: 160
    }
  },
  nonMembersPrice: {
    priceValidator: {
      allowBlank: true,
      greaterThanOrEqualTo: 0.5,
      lessThan: 1000
    }
  },
  membersPrice: {
    priceValidator: {
      allowBlank: true,
      greaterThanOrEqualTo: 0.5,
      lessThan: 1000
    }
  },
  // creditsStartTime: {
  //   format: {
  //     pattern: /^(\d{1,2}):([0-5]?\d|59):([0-5]?\d|59)$/,
  //     message: 'must have the format HH:MM:SS'
  //   }
  // },
  earlyReleaseTime: {
    format: {
      pattern: /^(|([0-1]?[0-9]|2[0-3]):([0-5][0-9]))$/,
      message: '^Please enter a valid time in HH:MM format.'
    }
  },
  releaseTime: {
    format: {
      pattern: /^(|([0-1]?[0-9]|2[0-3]):([0-5][0-9]))$/,
      message: '^Please enter a valid time in HH:MM format.'
    }
  }
};

export const videoadsValidations = {
  title: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 50
    }
  },
  videoLength: {
    presence: true
  },
  adPlacement: {
    presence: true
  },
  externalLink: {
    optionalUrl: {
      message: 'is invalid'
    }
  },
  adEndDate: {
    presence: false
  }
};

export const eventValidation = {
  title: {
    presence: true,
    length: {
      maximum: 100
    }
  },
  description: {
    presence: true,
    length: {
      maximum: 160
    }
  },
  event_date: {
    presence: true
  },
  event_time: {
    presence: true,
    format: {
      pattern: /^([0-1]?\d|2[0-3]):([0-5]?\d|59)$/,
      message: '^Please enter a valid duration in HH:MM format.'
    },
    timeNotBeforeNow: {
      message: '^Time can not be before the current time.'
    }
  },
  duration: {
    format: {
      pattern: /^([0-1]?\d|2[0-3]):([0-5]?\d|59):([0-5]?\d|59)$/,
      message: '^Please enter a valid duration in HH:MM:SS format.'
    }
  },
  non_members_price: {
    priceValidator: {
      allowBlank: true,
      greaterThanOrEqualTo: 0.5,
      lessThan: 1000
    }
  },
  members_price: {
    priceValidator: {
      allowBlank: true,
      greaterThanOrEqualTo: 0.5,
      lessThan: 1000
    }
  }
};

export const podcastValidation = {
  title: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 100
    }
  },
  description: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 160
    }
  },
  lead_cast: {
    length: {
      maximum: 160
    }
  },
  credits: {
    length: {
      maximum: 160
    }
  },
  start_date: {
    presence: true
  },
  duration: {
    presence: true,
    format: {
      pattern: /^(\d{1,2}):([0-5]?\d|59):([0-5]?\d|59)$/,
      message: '^Please enter a valid duration in HH:MM:SS format.'
    }
  },
  start_time: {
    presence: true,
    format: {
      pattern: /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/,
      message: '^Please enter a valid time in HH:MM format.'
    }
  },
  non_members_price: {
    priceValidator: {
      allowBlank: true,
      greaterThanOrEqualTo: 0.5,
      lessThan: 1000
    }
  },
  members_price: {
    priceValidator: {
      allowBlank: true,
      greaterThanOrEqualTo: 0.5,
      lessThan: 1000
    }
  }
};
