/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo, useState } from 'react';
import { number, object, bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { routesPaths } from 'constants/routesPaths';
import { checkSubscription, routeWithProps, toMinutesAndSeconds } from 'utils/helpers';
import { useCelebrity, useClickOutside, useModal, useNavigation, useSession } from 'hooks';

import VideoThumbnail from 'components/common/VideoThumbnail/VideoThumbnail';
import { EditIcon, OptionsIcon, ShareIcon, TrashIcon } from 'components/icons';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';
import DeletePublication from 'components/DeletePublication/DeletePublication';
import Modal from 'components/common/Modal/Modal';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import SharingBlock from 'components/sharing/SharingBlock/SharingBlock';
import ClockImg from 'assets/clock.svg';
import CheckSquareImg from 'assets/check-square-broken.svg';
import EyeImg from 'assets/eye.svg';
import BlackBg from 'assets/Black.png';
import { EPISODE_STATES } from 'constants/constants';
import ReportModal from 'components/ReportModal/ReportModal';
import useProfile from 'hooks/users/useProfile';
import PriceCrownIcon from 'components/common/PriceCrownIcon';
import PodcastCardInfo from './PodcastCardInfo';

const CLOSED = 0;
const OPENED = 1;
const ICONS_DISPLAYED = 2;

const PodcastCard = ({
  celebrityId,
  podcast,
  isLoggedUser,
  refresh,
  username,
  celebrityName,
  onClick
}) => {
  const { toggle, isShowing } = useModal();
  const intl = useIntl();
  const { authenticated, user } = useSession();
  const { celebrity } = useCelebrity();
  const { toggle: reportToggle, isShowing: reportShowing } = useModal();
  const [optionsStatus, setOptionsStatus] = useState(CLOSED);
  const {
    membersPrice,
    title,
    id,
    duration,
    startTime,
    startDate,
    nonMembersPrice,
    bannerImageUrl,
    status,
    audioOnly,
    video: { playCount, totalSeconds, fullyWatchedPer, stage, link, thumbnailsUrls, description }
  } = podcast;
  const { goTo } = useNavigation();
  const {
    profile: { memberships }
  } = useProfile();
  const isMember = checkSubscription(memberships, celebrity.id);
  const ref = useClickOutside(() => setOptionsStatus(CLOSED), optionsStatus);
  const isPublished = stage === EPISODE_STATES.published;
  const statsVisible = isPublished && celebrity.id === user.id;

  const handleEditEvent = () => {
    goTo(
      routeWithProps(routesPaths.editPodcast, {
        podcastId: id,
        celebrityId
      })
    );
  };

  const podcastUrl = useMemo(
    () =>
      `${window.location.origin}${routeWithProps(routesPaths.podcast, {
        celebrityId,
        podcastId: podcast.id
      })}`,
    [celebrityId, podcast.id]
  );

  const handleCardClick = e => {
    if (!ref.current || (!ref.current.contains(e.target) && stage !== 'processing')) {
      goTo(
        routeWithProps(routesPaths.podcast, {
          podcastId: id,
          celebrityId
        })
      );
    }
  };

  const handleOptionsClick = e => {
    e.stopPropagation();
    setOptionsStatus(optionsStatus === OPENED ? CLOSED : OPENED);
  };

  return (
    <div className="podcast-card podcast-card-horizontal" onClick={handleCardClick}>
      <div className="podcast-card-thumbnail">
        <VideoThumbnail
          type="podcast"
          thumbnail={
            stage === 'processing' ||
            (stage === 'post_produce_processing' && thumbnailsUrls === null) ||
            (stage === 'published' && thumbnailsUrls === null)
              ? BlackBg
              : bannerImageUrl || thumbnailsUrls[0].src
          }
          className={`podcast-card-horizontal-thumbnail ${
            stage === 'processing' ||
            (stage === 'post_produce_processing' && thumbnailsUrls === null) ||
            (stage === 'published' && thumbnailsUrls === null)
              ? 'processing-card'
              : ''
          }`}
        >
          {(stage === 'processing' || (stage === 'published' && thumbnailsUrls === null)) && (
            <div className="processing-overlay">PROCESSING</div>
          )}
          {stage === 'post_produce_processing' && thumbnailsUrls === null && (
            <div className="processing-overlay">POST-PRODUCING</div>
          )}
          {audioOnly && <div className="podcast-status-purple">Audio Only</div>}
        </VideoThumbnail>
        <div className="podcast-card-horizontal-gradient" />
        <div className="podcast-card-thumbnail-gradient" />
        <div className="opacity" />
        {!link && (
          <div onClick={onClick}>
            <PriceCrownIcon
              price={isMember ? membersPrice : nonMembersPrice || membersPrice}
              // membership={!isMember}
              className="podcast-card-thumbnail-premium"
            />
          </div>
        )}
        <div className="podcast-card-horizontal-info">
          <PodcastCardInfo
            title={title}
            description={description}
            podcastTime={startTime}
            podcastDate={startDate}
            membersPrice={membersPrice}
            duration={duration}
            nonMembersPrice={nonMembersPrice}
            isAuthenticated={authenticated}
          />
        </div>
        <div className="sharing-options" ref={ref} onClick={e => e.stopPropagation()}>
          {optionsStatus === OPENED && (
            <>
              {!isLoggedUser && (
                <button
                  className="report-button"
                  onClick={e => {
                    e.stopPropagation();
                    reportToggle();
                  }}
                >
                  Report
                </button>
              )}
              {isLoggedUser && (
                <IconWithBackground
                  icon={<TrashIcon />}
                  onClick={e => {
                    e.stopPropagation();
                    toggle();
                  }}
                  isSmall
                />
              )}
              <IconWithBackground
                icon={<ShareIcon />}
                onClick={e => {
                  e.stopPropagation();
                  setOptionsStatus(ICONS_DISPLAYED);
                }}
                isSmall
              />
              {isLoggedUser && status !== 'completed' && (
                <IconWithBackground
                  icon={<EditIcon />}
                  onClick={e => {
                    e.stopPropagation();
                    handleEditEvent();
                  }}
                  isSmall
                />
              )}
            </>
          )}
          {optionsStatus === ICONS_DISPLAYED && (
            <SharingBlock
              onClose={e => {
                e.stopPropagation();
                setOptionsStatus(CLOSED);
              }}
              shareUrl={podcastUrl}
              videoTitle={title}
              shareText={intl.formatMessage({ id: 'sharing.event' }, { creator: celebrityName })}
            />
          )}
          {(optionsStatus === CLOSED || optionsStatus === OPENED) && (
            <IconWithBackground icon={<OptionsIcon />} onClick={handleOptionsClick} isSmall />
          )}
          {isShowing && (
            <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
              <DeletePublication
                type="podcast"
                refresh={refresh}
                celebrityId={celebrityId}
                id={id}
                username={username}
              />
            </Modal>
          )}
          {reportShowing && (
            <Modal
              hide={reportToggle}
              isShowing={reportShowing}
              topModal
              zIndex={zIndexModalThird}
              className="original-modal"
            >
              <ReportModal title="Report Podcast" type="isPodcast" id={id} />
            </Modal>
          )}
        </div>
        {statsVisible && (
          <div className="podcast-media-overlay">
            <div className="media-stat">
              <img className="media-icons" src={EyeImg} alt="" />
              <span className="media-fonts">{playCount}</span>
            </div>
            <div className="media-stat">
              <img className="media-icons" src={ClockImg} alt="" />
              <span className="media-fonts">{toMinutesAndSeconds(totalSeconds)}</span>
            </div>
            <div className="media-stat">
              <img className="media-icons" src={CheckSquareImg} alt="clock" />
              <span className="media-fonts">{fullyWatchedPer}%</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PodcastCard.propTypes = {
  celebrityId: number.isRequired,
  celebrityName: string.isRequired,
  isLoggedUser: bool,
  podcast: object,
  refresh: func,
  username: string,
  onClick: func
};

export default PodcastCard;
