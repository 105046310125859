/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { func, shape } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ERROR, SUCCESS } from '@rootstrap/redux-tools';
import { eventInoTabs } from 'constants/constants';
import { serieShape } from 'constants/propTypesShapes';
import { useIsMobile, useClickOutside, useSession, useModal, useCelebrity } from 'hooks';
import Tabs from 'components/common/Tabs/Tabs';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import Button from 'components/common/Button/Button';
import { useToast } from 'hooks';
import Close from 'components/common/Close/Close';
import AuthModal from 'components/user/AuthModal/AuthModal';
import { debounce } from 'lodash';
import { routesPaths } from 'constants/routesPaths';
import { white, black40, zIndexModalThird, zIndexModalSecond } from 'styles/common/_constants.scss';
import Modal from 'components/common/Modal/Modal';
import EventPurchaseModal from 'components/payments/EventPurchaseModal/EventPurchaseModal';
import Info from './Info/Info';
import EventService from 'services/eventService';
import parseError from 'utils/parseError';
import EventAbout from './EventAbout/EventAbout';
import useMembership from 'hooks/celebrities/useMembership';
import useProfile from 'hooks/users/useProfile';
import useEventRegister from 'components/payments/EventPurchaseModal/useEventRegister';
import {
  cancelEventSubscription, registerEvent
} from 'state/actions/membershipActions';

const EventInfo = ({ event, hide, celebrityId, isAuthenticated, onSuccess }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { eventAd, status: registerEventstatus, error: registerEventerror } = useEventRegister();
  const { showErrorToast, showToast, showCustomErrorToast } = useToast();
  const { toggle, isShowing } = useModal();
  const { toggle: toggleAuth, isShowing: isShowingAuth } = useModal();
  const { toggle: toggleCancel, isShowing: isShowingCancel } = useModal();
  const { id, webCoverImageUrl, status, duration, eventStartTimeInUtc } = event;
  const { celebrity, refresh, success } = useCelebrity(celebrityId);
  const { DETAILS } = eventInoTabs;
  const {
    user: { id: loggedId, type, firstName, lastName }
  } = useSession();
  const isLoggedUser = loggedId === celebrityId;
  const [inRange, setInRange] = useState(false);
  const [isShowedAuthModal, setIsShowedAuthModal] = useState(false);
  const [afterDuration, setAfterDuration] = useState(false);
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide, id);
  const { cancelEventSubscriptions, cancelEventStatus, cancelEventError } = useMembership();
  const { requestProfile } = useProfile();

  const startEvent = async eventId => {
    try {
      const { data } = await EventService.startEventById(eventId);
      return data;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const getEventById = async eventId => {
    try {
      const { data } = await EventService.getEventByEventId(eventId, event?.celebrityId);
      return data;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const [selectedTab, setSelectedTab] = useState(DETAILS.id);

  const renderContent = tab => {
    switch (tab) {
      case DETAILS.id:
        return <EventAbout event={event} />;
      default:
    }
  };

  const hideMainModel = () => {
      hide();
  };

  const joinEvent = () => {
    if(status === 'ongoing'){
      event.subscribedToEvent = true;
    }
};

 useEffect(() => {
    if (registerEventstatus === SUCCESS) {
      showToast('Register Event Successfully!');
      if (event?.status !== 'ongoing') {
        !!hideMainModel && hideMainModel();
      }
      dispatch(registerEvent.reset());
      refresh();
      !!onSuccess && onSuccess();
       joinEvent();
    }
    if (registerEventstatus === ERROR) {
      const options = {
        hideProgressBar: true,
        autoClose: 5000
      };
      showCustomErrorToast(registerEventerror, options);
      dispatch(registerEvent.reset());
      !!hideMainModel && hideMainModel();
      hide();
    }
  }, [registerEventstatus]);

  useEffect(() => {
    if(success && isShowedAuthModal) onClickEvent();
  }, [success]);

  useEffect(() => {
    if (cancelEventStatus === SUCCESS) {
      showToast('Event canceled successfully!');
      dispatch(cancelEventSubscription.reset());
      hide();
      refresh();
      !!onSuccess && onSuccess();
    }
    if (cancelEventStatus === ERROR) {
      showErrorToast(cancelEventError);
      dispatch(cancelEventSubscription.reset());
      hide();
    }
  }, [cancelEventStatus]);

  const onClickEvent = async () => {
    setIsShowedAuthModal(false);
    if (!isAuthenticated && status === 'ongoing') {
      setIsShowedAuthModal(true);
      toggleAuth();
    } else
    if (isLoggedUser) {
      if(status === 'ongoing'){
        history.push({
          pathname: routesPaths.goLive,
          state: {
            role: 'Host',
            room: event.roomKey,
            username: `${firstName} ${lastName}`,
            celebrityId: loggedId,
            eventId: event.id,
          },
        });
      }else if(inRange){
      try {
        const roomDetail = await startEvent(event?.id);
        history.push({
          pathname: routesPaths.goLive,
          state: {
            role: 'Host',
            room: roomDetail?.liveStream?.roomKey,
            username: `${firstName} ${lastName}`,
            celebrityId: loggedId,
            eventId: event.id,
          },
        });
      } catch (error) {
        console.error('Error fetching room key:', error);
        return;
      }
    }
    } else if (!event.subscribedToEvent) {
      try {
        if(status === 'ongoing'){
        const eventDetail = await getEventById(event?.id);
        if(eventDetail?.event?.status === 'ongoing'){
          const price = eventDetail?.event?.isMember ? 
          eventDetail?.event?.membersPrice : 
          eventDetail?.event?.nonMembersPrice;
          if(price === 0){
            const payload = {
              eventId: eventDetail?.event?.id,
              register: true
            };
      
             eventAd(payload);
          }else{
            toggle();
          }
        }else{
          const options = {
            hideProgressBar: true,
            autoClose: 5000
          };
          showCustomErrorToast('Event has been ended.', options);
          hide();
        }
      }else{
        const price = event?.isMember ? 
          event?.membersPrice : 
          event?.nonMembersPrice;
          if(price === 0){
            const payload = {
              eventId: event?.id,
              register: true
            };
      
            eventAd(payload);
          }else{
            toggle();
          }
      }
      } catch (error) {
        console.error('Error fetching event detail:', error);
        return;
      }
    }else if(event.roomKey){
      history.push({
        pathname: routesPaths.goLive,
        state: { role: "Audience", room: event.roomKey, username: `${firstName} ${lastName}`, celebrityId: loggedId, eventId: event.id }
      })
    }else{
      toggleCancel();
    }
  }

  const onCancel = debounce(() => {
    cancelEventSubscriptions(event);
    toggleCancel();
}, 300); 

  const parseDurationToSeconds = (durationString) => {
    const [hours, minutes, seconds] = durationString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const activeDurationInSeconds = parseDurationToSeconds(duration);

  const targetDate = new Date(eventStartTimeInUtc);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const startEnableTime = new Date(targetDate.getTime() - 15 * 60 * 1000); 
      const endEnableTime = new Date(targetDate.getTime() + activeDurationInSeconds * 1000);
      setInRange(now >= startEnableTime && now <= endEnableTime);
      setAfterDuration(now > endEnableTime);
    };

    const intervalId = setInterval(checkTime, 1000);
    checkTime(); 
    return () => clearInterval(intervalId);
  }, [targetDate, activeDurationInSeconds]);

  return (
    <div className="event-landing">
      <div className="event-landing-header">
        <BackgroundOpacity opacity={0.5} background={webCoverImageUrl} />
        <div className="event-landing-header-gradient" />
        <Close onClick={hide} color={isMobile ? white : black40} />
        <div className="layer event-landing-header-content">
          <Info event={event} className="header-info" />
          {status !== 'completed' && (isAuthenticated || (!isAuthenticated && status === 'ongoing')) && ((isLoggedUser && inRange) || !isLoggedUser) && (
            <div className="flex-column header-right-column">
              <Button
                 labelId={
                  !isAuthenticated && status === 'ongoing'
                    ? 'Join Event'
                    : isLoggedUser
                    ? status === 'ongoing'
                      ? 'Join Event'
                      : 'Start Event'
                    : event.subscribedToEvent
                    ? event.roomKey
                      ? 'Join Event'
                      : 'Cancel Event'
                    : 'Register Now'
                }
                type="primary"
                size="xsmall"
                onClick={onClickEvent}
                className="watch-event"
                lightFont
              />
            </div>
          )}
        </div>
      </div>
      <div className="event-landing-content">
        <>
          <Tabs
            tabs={Object.values(eventInoTabs)}
            selectedTab={selectedTab}
            onTabSelect={setSelectedTab}
            isVertical={!isMobile}
            className="event-landing-tabs"
          />
          <div className="separator" />
          {renderContent(selectedTab)}
        </>
      </div>
      <div className="top-gradient" />
      <div className="bottom-gradient" />
      {isShowing && (
        <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
          <EventPurchaseModal celebrity={celebrity} event={event} hideMainModel={hideMainModel} onSuccess={onSuccess} joinEvent={joinEvent} ref={ref}/>
          {/* <PurchaseModalHeader
            title="ab"
            image={UploadIcon}
            price="20"
            showProfile={false}
            hide={hide}
            // purchaseItems={PURCHASE_ITEMS}
          /> */} 
        </Modal>
      )}
      {isShowingCancel && (
        <Modal hide={toggleCancel} isShowing={isShowingCancel} topModal zIndex={zIndexModalThird}>
          <div className="delete-modal" ref={ref}>
      <div className="delete-modal-header">
        {`Cancel Event`}
        <Close onClick={toggleCancel} color={isMobile ? white : black40} />
      </div>
      <div className="layer delete-modal-header-content">
        <div className="delete-content-div">
          <span>Are you sure you want to cancel this event subscription?</span>
          <div className="buttons">
            <Button className="cancel-btn" onClick={toggleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              labelId="Yes, Cancel"
              className="delete-btn"
              onClick={onCancel}
            />
          </div>
        </div>
      </div>
    </div>
        </Modal>
      )}
      <Modal hide={toggleAuth} isShowing={isShowingAuth} zIndex={zIndexModalSecond}>
        <AuthModal isModal onSuccess={refresh} />
      </Modal>
    </div>
  );
};

EventInfo.propTypes = {
  event: shape(serieShape),
  hide: func.isRequired,
  onSuccess: func
};

export default EventInfo;
